import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const getToken = () => localStorage.getItem('token');
const getRole = () => localStorage.getItem('role');

const getAuthorizationHeader = () => {
  const token = getToken();
  return token ? `Bearer ${token}` : '';
};

interface WorkerAppTypes {
  app_status: number;
  avatar: string | null;
}

// interface Employed {
//   created_at: string;
//   fns_status: number;
//   id: number;
//   inn: string;
//   last_info_update: string;
//   name: string;
//   nationality: string;
//   patronymic: string;
//   phone: string;
//   surname: string;
//   worker_app: WorkerAppTypes;
// }

interface WorkerFnsTypes {
  fns_status: number;
  inn: string;
  last_info_update: null | string;
}

export interface Employed {
  app_status: number;
  avatar: null | string;
  id: number;
  name: string;
  nationality: string;
  phone: string;
  surname: string;
  worker_fns: WorkerFnsTypes;
}

interface ItemTypes {
  count: number;
  filters: string[];
  idx: number;
  key: string;
  name: string;
}

interface TabsTypes {
  count: number;
  items?: ItemTypes[];
}

// https://{domain}/api/tochka/beneficiaries
export const employedsWorkSupportApi = createApi({
  reducerPath: 'employedsWorkSupportApi',
  tagTypes: ['Employeds', 'Employed', 'Tags', 'EmployedsTabs'],
  refetchOnReconnect: true,
  keepUnusedDataFor: 15,
  // refetchOnFocus: true,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_SERVER_TEST,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: getAuthorizationHeader(),
    },
  }),
  endpoints: (build) => ({
    getEmployedsWorkSupport: build.query<Employed[] | any, void>({
      query: () => ({
        url: `workers-app`,
        method: 'GET',
      }),
      providesTags: () => ['Employeds'],
    }),
    // /workers-app/{worker_app_id}/show
    getEmployed: build.query<any, any>({
      query: ({ id }) => ({
        url: `/workers-app/${id}`,
        method: 'GET',
      }),
      providesTags: () => ['Employed'],
    }),
    // /workers-app/{worker_app_id}/tabs/payments
    getTabEmployeds: build.query<any, any>({
      query: ({ id, title }) => ({
        url: `/workers-app/${id}/tabs/${title}`,
        method: 'GET',
      }),
      providesTags: () => ['Tags'],
      transformResponse: (response: any) => response?.items,
      // invalidatesTags: []
    }),
  }),
});

export const {
  useGetTabEmployedsQuery,
  useGetEmployedQuery,
  useGetEmployedsWorkSupportQuery,
} = employedsWorkSupportApi;
