// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';
//
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
//
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import {
  useActionForOrdersMutation,
  useGetOrderQuery,
  useLazyGetTypesOfServicesQuery,
  useUpdateOrderMutation,
} from 'redux/ordersApi';
import {
  resetOrderInfo,
  setDateStart,
  setInfoDateHourMinutes,
  setInfoOrderDetail,
} from 'redux/ordersDetails/slice';
import { Layout } from 'features/Layouts/Layouts';
import { ButtonSave } from 'components/ButtonSave';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { WhiteBlock } from 'components/WhiteBlock';
import { InformationOrderBlock } from 'components/InformationOrderBlock';
import { DocumentInfo } from 'components/DocumentInfo';
import { Title } from 'components/Title';
import { EditFieldsOrder } from 'components/EditFieldsOrder';
import {
  getDateFormattedDateForParams,
  dateForOrderDetails,
  getDateFormattedDateForParamsString,
  getDateFormattedDateInfo,
} from 'utils/date';
import { downloadFileForOrders } from 'api/getApi';
import { NewOrderDetailsHistory } from 'components/NewOrderDetailsHistory';
import { Nothing } from 'components/Nothing';
import { OrderActionBtn } from 'components/OrderActionBtn';
// import { ReactComponent as EditSvg } from './img/iconEdit.svg';
import styles from './OrdersDetails.module.scss';
import { Tooltip } from 'ui/Tooltip';

export const OrdersDetails = () => {
  const role = localStorage.getItem('role');
  const phone = localStorage.getItem('phone');
  // const token = localStorage.getItem('token');
  // const [showEditFields, setShowEditFields] = useState(false);
  // const [history, setHistory] = useState<any>([]);
  // const [statusOrder, setStatusOrder] = useState('');
  const navigate = useNavigate();
  const minMaxPrice =
    phone === '9052264047' || phone === '79052264047'
      ? { min: 50, max: 50000 }
      : { min: 1000, max: 50000 };
  const { balance, infoPercent } = useSelector((state: RootState) => state.authSlice);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<null | string>(null);
  const { id } = useParams();
  const [getTypes, { data: dataTypes, isLoading: isLoadingTypes }] =
    useLazyGetTypesOfServicesQuery();
  const { data, isLoading, error } = useGetOrderQuery({ id });
  const [
    updateOrder,
    { data: dataUpdateOrder, error: errorUpdateOrder, isLoading: loadingUpdateOrder },
  ] = useUpdateOrderMutation();

  const [actionForOrdersMutation, { data: dataAction, error: errorAction }] =
    useActionForOrdersMutation();
  const { infoOrderDetail, dateStartHourMinutes, dateEndHourMinutes } = useSelector(
    (state: RootState) => state.ordersDetailsSlice,
  );
  const {
    worker_id,
    description,
    amount,
    is_remote,
    city: cityInfoOrderDetail,
    street: streetInfoOrderDetail,
  } = infoOrderDetail || {};
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      // setHistory(data?.history);
      dispatch(setInfoOrderDetail({ key: 'worker_id', value: data?.worker?.id }));
      dispatch(
        setInfoOrderDetail({
          key: 'is_remote',
          value: data?.is_remote ? 'Удаленно' : 'По адресу',
        }),
      );
      for (const key in data?.order_data) {
        if (key === 'date_start' || key === 'date_end') {
          if (data.order_data[key] > 1) {
            dispatch(
              setInfoOrderDetail({
                key,
                value: getDateFormattedDateInfo(data.order_data[key].split(' ')[0]),
              }),
            );
            dispatch(
              setInfoDateHourMinutes({ key, value: data.order_data[key].split(' ')[1] }),
            );
          }
        } else {
          dispatch(setInfoOrderDetail({ key, value: data.order_data[key] }));
        }
      }
    }
    if (role === 'director') {
      getTypes();
    }
  }, [data, id]);

  const updateOrderHandler = async (isDraft) => {
    const { date_start, date_end, amount, is_draft, is_remote, ...rest } =
      infoOrderDetail;
    await updateOrder({
      id,
      body: {
        ...rest,
        // date_start:
        //   typeof date_start === 'object'
        //     ? `${getDateFormattedDateForParams(date_start)} ${
        //         dateStartHourMinutes.hour
        //       }:${dateStartHourMinutes.minutes}`
        //     : `${getDateFormattedDateForParamsString(date_start)} ${
        //         dateStartHourMinutes.hour
        //       }:${dateStartHourMinutes.minutes}`,
        date_end:
          typeof date_end === 'object'
            ? `${getDateFormattedDateForParams(date_end)} 00:00`
            : `${getDateFormattedDateForParamsString(date_end)} 00:00`,
        amount: +amount,
        is_remote: is_remote === 'Удаленно' ? true : false,
        is_draft: isDraft,
      },
    });
  };

  const {
    home = '',
    street = '',
    city = '',
    apartment = '',
    ...obj
  } = data?.order_data || {};
  const objectInfo = {
    ...obj,
    address: data?.is_remote
      ? 'Удалённая работа'
      : `${city || ''} ${street || ''} ${home || ''} ${apartment || ''}`,
  };

  const downloadFile = async (elem) => {
    setSelectedDocument(elem.type);
    try {
      setDownloadLoading(true);
      const response = await downloadFileForOrders(role === 'worksupport' && role, id, {
        type: elem.type,
      });

      if (!response.data) {
        throw new Error('Ошибка при загрузке файла');
      }

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);

      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = elem.number;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();

      // Открываем файл в новой вкладке
      window.open(url, '_blank');
      // Освобождаем ресурсы URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      setDownloadLoading(false);
      setSelectedDocument(null);
    }
  };

  const arrStatus = [
    'order_success',
    'moderation_in_progress',
    'moderation_success',
    'worker_failed',
    'worker_success',
    'worker_approved_by_customer',
    'worker_invited_by_customer',
    'worker_rejected_by_customer',
    'worker_approved_order',
    'order_published',
  ];

  const isCanceled = useMemo(() => {
    return arrStatus.some((e: string) => e === data?.status?.value);
  }, [data?.status]);

  const { success, ...objPayment } = data?.payment || {};

  useEffect(() => {
    if (errorUpdateOrder || errorAction) {
      alert(errorUpdateOrder?.data?.message || errorAction?.data?.message);
    }
  }, [errorUpdateOrder, errorAction]);

  const actionWithOrder = async (url) => {
    try {
      await actionForOrdersMutation({ url });
    } catch (error) {
      console.log('error', error);
    }
  };

  const sumWithPercent = +infoOrderDetail.amount * (infoPercent.commission_percent / 100);

  const disabledBtnPercent =
    +balance <
    (sumWithPercent < infoPercent.commission_min_amount
      ? +infoOrderDetail.amount + infoPercent.commission_min_amount
      : +infoOrderDetail.amount + +sumWithPercent.toFixed(2));

  const [index, setIndex] = useState<number | null>(null);

  const mouseMove = (i: number) => {
    setIndex(i);
  };

  const mouseLeave = () => {
    setIndex(null);
  };

  const lengthAmount = 60;

  const formatedHistory = useMemo(() => {
    const groupHistory = {};

    data?.history.forEach((elem: any) => {
      const { datetime, ...rest } = elem;
      const dateSlice = datetime.split(' ').slice(0, 2).join(' ');

      if (!groupHistory[dateSlice]) {
        groupHistory[dateSlice] = {
          datetime: dateSlice,
          items: [],
        };
      }

      groupHistory[dateSlice].items.push({
        date: datetime.split(' ').slice(2).join(''),
        ...rest,
      });
    });

    const consolidatedHistory = Object.values(groupHistory);
    return consolidatedHistory;
  }, [data]);

  // console.log('first', balance);

  useEffect(() => {
    if (error?.status === 404) navigate(`/orders`);
  }, [error]);

  return (
    <Layout>
      {(!+balance && role === 'director') ||
      isLoading ||
      isLoadingTypes ||
      error?.status === 500 ? (
        error ? (
          <Nothing style={{ marginTop: '30px' }} title='Ошибка, попробуйте позже' />
        ) : (
          <LoadingSkeleton mt />
        )
      ) : (
        <div className={styles.root}>
          <div className={styles.back}>
            <p className={styles.back_title}>
              {data?.is_draft
                ? 'Карточка заказа'
                : `Заказ №${data?.number}
                  `}
              {/* ${
                    data?.order_data?.date_start
                      ? `от ${dateForOrderDetails(data?.order_data?.date_start)}`
                      : ''
                  } */}
            </p>
            {role !== 'worksupport' && (
              <OrderActionBtn
                disabledBtnPercent={disabledBtnPercent}
                worker_id={worker_id}
                data={data}
                description={description}
                amount={amount}
                isCanceled={isCanceled}
                id={id}
                actionWithOrder={actionWithOrder}
                updateOrderHandler={(isDraft) => updateOrderHandler(isDraft)}
                // actionOrders={actionOrders}
                minMaxPrice={minMaxPrice}
                balance={balance}
                is_remote={is_remote}
                city={cityInfoOrderDetail}
                street={streetInfoOrderDetail}
              />
            )}
          </div>
          <div className={styles.content}>
            <div className={styles.rootleft}>
              {data?.is_draft && role === 'director' ? (
                <div>
                  <EditFieldsOrder
                    draft={data?.is_draft}
                    types={dataTypes}
                    minMaxPrice={minMaxPrice}
                    balance={+balance >= +amount}
                    infoPercent={infoPercent}
                    sumBalance={+balance}
                  />
                </div>
              ) : (
                <div className={styles.left}>
                  <div className={styles.left_title}>
                    <p className={styles.title}>Данные заказа</p>
                    {/* <EditSvg onClick={() => setShowEditFields(true)} /> */}
                  </div>
                  <InformationOrderBlock objectInfo={objectInfo} />
                </div>
              )}
              {!!data?.docs.length && (
                <div className={styles.left}>
                  <Title className={styles.left_title} title='Документы' />
                  <div className={styles.blockDocs}>
                    {data?.docs?.map((elem, i) => (
                      <DocumentInfo
                        loading={selectedDocument === elem?.type && downloadLoading}
                        onClick={() => downloadFile(elem)}
                        key={i}
                        href={''}
                        number={elem?.number}
                        date={elem?.created_at}
                      />
                    ))}
                  </div>
                </div>
              )}

              {!!data?.history?.length && (
                <WhiteBlock style={{ paddingRight: '4px' }}>
                  <Title title='История' />
                  <div className={styles.root_history}>
                    {formatedHistory?.map((elem: any, ind: number) => (
                      <div className={styles.root_block} key={ind}>
                        <Title
                          className={styles.root_history_title}
                          title={elem.datetime}
                        />
                        {elem.items.map((item, i) => (
                          <div className={styles.root_block_text} key={i}>
                            <div
                              className={styles.root_text}
                              onMouseMove={() => mouseMove(i)}
                              onMouseLeave={mouseLeave}>
                              <Title
                                style={{
                                  fontWeight: 400,
                                  fontSize: '16px',
                                  lineHeight: '24px',
                                }}
                                title={
                                  item.text.length > lengthAmount
                                    ? `${item.text.slice(0, lengthAmount)}...`
                                    : item.text
                                }
                              />
                              {index === i && item.text?.length > lengthAmount && (
                                <Tooltip
                                  field={true}
                                  position='bottom'
                                  text={item.text}
                                />
                              )}
                            </div>
                            <p className={styles.root_datetext}>{item.date}</p>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </WhiteBlock>
              )}
            </div>

            <div className={styles.right}>
              <NewOrderDetailsHistory
                status={data?.status}
                payment={data?.payment}
                objPayment={objPayment}
                data={data}
                role={role}
                actionWithOrder={actionWithOrder}
                id={id}
                isCanceled={isCanceled}
              />
              {/* <OrderDetailsHistory historyArr={history} /> */}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

// без ECHO
// useEffect(() => {
//   const pusher = new Pusher('YOUR_PUSHER_KEY', {
//     cluster: 'YOUR_PUSHER_CLUSTER',
//     encrypted: true,
//   });

//   const channel = pusher.subscribe('chat');
//   channel.bind('MessageEvent', (data) => {
//     setMessages(prevMessages => [...prevMessages, data.message]);
//   });

//   return () => {
//     pusher.unsubscribe('chat');
//   };
// }, []);

/////////////////////////////////////////////////////////

// useEffect(() => {
//   window.Pusher = Pusher;
//   // Инициализация Echo
//   const echo = new Echo({
//     broadcaster: `${process.env.REACT_APP_API_ECHO_BROADCASTER}`,
//     key: `${process.env.REACT_APP_API_ECHO_KEY}`,
//     wsHost: `${process.env.REACT_APP_API_ECHO_WS_HOST}`,
//     wsPort: 8080,
//     wssPort: 8080,
//     forceTLS: false,
//     enabledTransports: ['ws', 'wss'],
//     authHost: `${process.env.REACT_APP_API_ECHO_AUTH_HOST}`,
//     authEndpoint: `${process.env.REACT_APP_API_ECHO_END_POINT}`,
//     auth: {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     },
//   });

//   // Подписка на приватный канал и прослушивание событий
//   const privateChannel = echo.private(`orders.${id}`);

//   privateChannel.listen('.order.changed', (data: any) => {
//     // console.log('data?.history', data?.history);
//     setHistory(data?.history);
//   });

//   // Очистка эффекта
//   return () => {
//     privateChannel.stopListening('.order.changed');
//     delete window.Pusher;
//   };
// }, [id, token]);

// const statusOrder2 = useMemo(() => {
//   const historyValue = history?.filter((e) => e.color !== 'grey').at(-1)?.value;
//   // eslint-disable-next-line no-prototype-builtins
//   return titleForBtn.hasOwnProperty(historyValue) ? historyValue : null;
// }, [history]);

// console.log('statusOrder', statusOrder2);

//
// useEffect(() => {
//   if (dataSaveOrder) {
//     navigate(`/orders`);
//     dispatch(resetOrderInfo());
//   }
// }, [dataSaveOrder]);
//
