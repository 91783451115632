import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from 'features/Layouts/Layouts';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { DealDetailsItem } from 'components/DealDetailsItem';
import { useGetOperationsDetailsQuery } from 'redux/operationsApi';
import { DealDetailsItemRight } from 'components/DealDetailsItemRight';
import { currentDay } from 'utils/date';
import styles from './OperationsDealDetails.module.scss';

export const OperationsDealDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetOperationsDetailsQuery({ name: `deals/${id}` });

  return (
    <Layout>
      {isLoading ? (
        <LoadingSkeleton mt />
      ) : (
        data && (
          <div>
            <div className={styles.back}>
              <p className={styles.back_title}>
                Сделка ID {data?.id}{' '}
                {data?.created_at
                  ? `от ${currentDay(data?.created_at?.split(' ')[0])}`
                  : null}
              </p>
            </div>
            <div className={styles.content}>
              <div className={styles.left}>
                <DealDetailsItem data={data} />
              </div>

              <div className={styles.right}>
                {data?.payments?.map((item: any, index: number) => (
                  <DealDetailsItemRight key={index} data={item} />
                ))}
              </div>
            </div>
          </div>
        )
      )}
    </Layout>
  );
};
