import React, { FC } from 'react';
import styles from './SummaryHistoryItem.module.scss';
import { addSpaceNumber } from 'utils/addSpaceNumber';
import classNames from 'classnames';

interface SummaryHistoryItemTypes {
  item: any;
}

export const SummaryHistoryItem: FC<SummaryHistoryItemTypes> = ({ item }) => {
  // console.log('item', item);
  return (
    <div className={styles.root}>
      <div
        className={styles.root_item}
        style={{ display: 'flex', flexDirection: 'column', paddingLeft: 0 }}>
        <p className={styles.root_item__standart}>
          {item.paid_date.split(' ').slice(0, 2).join(' ')}
        </p>
        <p className={styles.root_item__for_ndfl}>{item.paid_time}</p>
      </div>
      <div
        className={styles.root_item}
        style={{ display: 'flex', flexDirection: 'column' }}>
        <p className={styles.root_item__standart}>{item.name}</p>
        <div style={{ display: 'flex', columnGap: '10px' }}>
          <p className={styles.root_item__for_ndfl}>{item.description}</p>
        </div>
      </div>
      <p
        style={{ textAlign: 'end', paddingRight: 0, fontWeight: 500 }}
        className={classNames(styles.root_item__standart, item.incoming && styles.green)}>
        {item.incoming ? '+ ' : ''}
        {addSpaceNumber(item.amount)} ₽
      </p>
    </div>
  );
};
