import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { title } from 'process';
// import { ordersQueryType, orderApiTypes, OrderTypes } from 'models/orderTypes';

const token = localStorage.getItem('token');

const api = 'api2';
// const api = 'api-test';
// /workers/${idWorker}/show

export const workerApi = createApi({
  reducerPath: 'workerApi',
  tagTypes: ['Worker', 'Reports', 'Groups', 'WorkerSupport'],
  refetchOnReconnect: true,
  keepUnusedDataFor: 15,
  // refetchOnFocus: true,
  baseQuery: fetchBaseQuery({
    baseUrl: `https://${api}.skilla.ru/api/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
      Accept: 'application/json',
    },
  }),
  endpoints: (build) => ({
    getGroups: build.query<any, any>({
      query: ({ idWorker }) => ({
        url: `/workers/${idWorker}/groups`,
        method: 'GET',
      }),
      providesTags: () => ['Groups'],
      transformResponse: (response: any) => response?.data,
      // providesTags: (result, error, arg) =>
      //   result
      //     ? [...result?.data?.items.map(({ id }: any) => ({ type: 'Worker', id })), 'Worker']
      //     : ['Worker']
      // providesTags: (result, error, arg): any => console.log(result, error, arg)
    }),
    getWorker: build.query<any, any>({
      query: ({ idWorker }) => ({
        url: `/workers/${idWorker}/show`,
        method: 'GET',
        // params
      }),
      providesTags: () => ['Worker'],
      transformResponse: (response: any) => response?.data,
    }),
    //  http://{{host}}/api/workers-app/moderation
    getWorkerSupport: build.query<any, any>({
      query: () => ({
        url: `/workers-app/moderation`,
        method: 'GET',
      }),
      providesTags: () => ['WorkerSupport'],
      transformResponse: (response: any) => response?.data,
    }),
    getReports: build.query<any, any>({
      query: ({ idWorker, params }) => ({
        url: `/workers/${idWorker}/show/reports`,
        method: 'GET',
        params,
      }),
      providesTags: () => ['Reports'],
    }),
    updateWorker: build.mutation<any, any>({
      query: ({ idWorker, params, body, docs_accept }) => ({
        url: `/workers/${idWorker}/update?${docs_accept === 1 ? '&app_verified=1' : ''}`,
        method: 'POST',
        body,
        headers: {
          Accept: 'application/json',
          Authorization: token ? `Bearer ${token}` : '',
        },
        params: params,
      }),
      invalidatesTags: ['Worker'],
      // invalidatesTags: (result, error, arg): any => console.log(result, error, arg.id)
      // invalidatesTags: (result, error, arg) => [{ type: 'Worker', id: arg.id }]
    }),
    moderateDecline: build.mutation<any, any>({
      query: ({ idWorker, params }) => ({
        url: `/workers/${idWorker}/docs-decline`,
        method: 'PATCH',
        params,
      }),
      invalidatesTags: ['Worker'],
      // invalidatesTags: (result, error, arg): any => console.log(result, error, arg)
    }),
    postPush: build.mutation<any, any>({
      query: ({ idWorker }) => ({
        url: `/workers/${idWorker}/send-invite`,
        method: 'POST',
      }),
      invalidatesTags: ['Worker'],
    }),
    // https://{domain}/api/workers/groups/store
    createGroup: build.mutation<any, any>({
      query: (params) => ({
        url: `/workers/groups/store`,
        method: 'POST',
        params,
      }),
      invalidatesTags: ['Groups'],
    }),
    ///workers/{worker_id}/groups/{group_id}/add
    addToGroup: build.mutation<any, any>({
      query: ({ worker_id, group_id }) => ({
        url: `/workers/${worker_id}/groups/${group_id}/add`,
        method: 'POST',
      }),
      invalidatesTags: ['Groups'],
    }),
    //workers/{worker_id}/groups/{group_id}/delete
    removeFromGroup: build.mutation<any, any>({
      query: ({ worker_id, group_id }) => ({
        url: `/workers/${worker_id}/groups/${group_id}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Groups'],
    }),
    //https://{domain}/api/workers-app/{worker-app_id}/tabs/partners
    getTab: build.query<any, any>({
      query: ({ id, title, name }) => ({
        url: `/${name}/${id}/tabs/${title}`,
        method: 'GET',
      }),
      transformResponse: (response: any) => response?.data,
      // invalidatesTags: []
    }),
    //workers-app/{worker-app_id}/update
    updateWorkerSupport: build.mutation<any, any>({
      query: ({ idWorker, params, body }) => ({
        url: `/workers-app/${idWorker}/update`,
        method: 'PUT',
        body,
        headers: {
          Accept: 'application/json',
          Authorization: token ? `Bearer ${token}` : '',
        },
        params: params,
      }),
      invalidatesTags: ['WorkerSupport'],
    }),
  }),
});

export const {
  useGetTabQuery,
  useGetWorkerSupportQuery,
  useGetWorkerQuery,
  useGetReportsQuery,
  useUpdateWorkerMutation,
  useModerateDeclineMutation,
  usePostPushMutation,
  useGetGroupsQuery,
  useCreateGroupMutation,
  useAddToGroupMutation,
  useRemoveFromGroupMutation,
  useUpdateWorkerSupportMutation,
} = workerApi;
