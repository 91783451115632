import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './Tooltip.module.scss';

type TooltipProp = {
  fullAddress: string;
  last?: boolean;
  lastChanges?: boolean;
};

export const Tooltip: FC<TooltipProp> = ({ fullAddress, last, lastChanges }) => {
  return (
    <div
      className={classNames(
        last ? styles.root_last : styles.root,
        lastChanges ? styles.addWidth : '',
      )}>
      {fullAddress}
    </div>
  );
};
