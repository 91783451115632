import { FC, useEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowSvg } from 'assets/img/filter/arrow.svg';
import { searchList } from 'utils/search';
import classNames from 'classnames/bind';
import styles from './ListSelect.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setWasChanges } from 'redux/mainInfo/slice';
import { RootState } from 'redux/store';
import { setResetField } from 'redux/createProfile/slice';

const cx = classNames.bind(styles);

type ListSelectProps = {
  placeholder: string;
  list: any;
  defaultValue?: any;
  search?: boolean;
  colorDefaultValue?: boolean;
  activeItem?: string;
  setActiveItem: any;
  isModerated?: boolean;
  handlerResetField?: any;
  style?: any;
};

export const ListSelect: FC<ListSelectProps> = ({
  placeholder,
  list,
  defaultValue,
  search,
  colorDefaultValue,
  activeItem,
  setActiveItem,
  isModerated,
  handlerResetField,
  style,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  // const [activeItem, setActiveItem] = useState<string>(defaultValue);
  const [listValues, setListValues] = useState<any[]>(list);
  const listRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const handlerOpen = () => {
    setIsOpen(!isOpen);
    setSearchValue('');
    setListValues(list);
  };

  const handlerItem = (item: string) => {
    setActiveItem(item);
    dispatch(setWasChanges(true));
    if (placeholder === 'Тип') {
      handlerResetField();
    }
  };

  const changeSearch = (e: any) => {
    const value = e.target.value;
    setSearchValue(value);

    const filterList = searchList(list, value, true);
    setListValues(filterList);
  };

  const classNameColor = cx({
    title: true,
    colorDefaultValue: colorDefaultValue && activeItem === defaultValue,
  });

  const classNameRoot = cx({
    root: true,
    active: isOpen,
    root_isModerated: isModerated,
  });

  const classNameArrow = cx({
    arrow: true,
    arrow_open: isOpen,
  });

  useEffect(() => {
    const clickOutside = (event: MouseEvent) => {
      if (listRef.current && !event.composedPath().includes(listRef.current)) {
        setIsOpen(false);
      }
    };
    document.body.addEventListener('mousedown', clickOutside);
    return () => document.body.removeEventListener('mousedown', clickOutside);
  }, []);

  const Menu = () => {
    return (
      <ul style={!listValues?.length ? { padding: 0 } : {}} className={styles.list}>
        {listValues?.map((item, i) => (
          <li
            className={`${styles.item} ${item === activeItem ? styles.item_active : ''}`}
            onClick={() => handlerItem(item)}
            key={i}>
            {item.name}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div
      style={style}
      className={classNameRoot}
      onClick={isModerated ? undefined : handlerOpen}
      ref={listRef}>
      {activeItem &&
        (isOpen ? (
          <input
            className={styles.search}
            autoFocus
            type='text'
            value={searchValue}
            placeholder='Поиск'
            onChange={changeSearch}
            onClick={(e) => e.stopPropagation()}
          />
        ) : (
          <span className={classNameColor}>{activeItem}</span>
        ))}

      <div className={classNameArrow}>
        <ArrowSvg />
      </div>

      {isOpen && <Menu />}

      <span
        style={isModerated ? { background: 'transparent' } : {}}
        className={styles.placeholder}>
        {placeholder}
      </span>
    </div>
  );
};
