import React, { FC } from 'react';
import { useGetTabQuery } from 'redux/beneficiariesApi';
import { TitleRowTabBeneficiaries } from 'components/TitleRowTabBeneficiaries';
import { PaymentsItem } from 'ui/PaymentsItem';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { Nothing } from 'components/Nothing';
import styles from './Payments.module.scss';

interface PaymentsTypes {
  id: number | null;
  title: string;
}

export const Payments: FC<PaymentsTypes> = ({ id, title }) => {
  const { data, isLoading, error } = useGetTabQuery({ id, title });

  const dataPayments = ['id', 'Тип платежа', 'Сумма', 'Статус', 'Идентификация '];

  // console.log('data.items', data?.items);

  return (
    <div className={styles.root}>
      <TitleRowTabBeneficiaries data={dataPayments} title={title} />
      {error ? (
        <Nothing style={{ height: '640px' }} title='Ничего не найдено' />
      ) : isLoading ? (
        <LoadingSkeleton tabDetails />
      ) : (
        data &&
        data.items.map((elem: any) => <PaymentsItem payment={elem} key={elem.id} />)
      )}
    </div>
  );
};
