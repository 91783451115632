import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const getToken = () => localStorage.getItem('token');

const getAuthorizationHeader = () => {
  const token = getToken();
  return token ? `Bearer ${token}` : '';
};

interface statusTypes {
  color_bg: string;
  color_text: string;
  key: string;
  text: string;
}

interface payerDocTypes {
  id: string;
  success_added: boolean;
  success_added_desc: string;
  type: string;
}

interface payerTypes {
  amount: number;
  virtual_account: string;
  documents: payerDocTypes[];
}
interface orderTypes {
  number: number;
  created_at: string;
}

interface payerAndRecipientTypes {
  name: string;
  value: string;
}

export interface paymentsTypes {
  amount: number;
  created_at: string;
  document_date: string | null;
  document_number: string | null;
  id: number;
  payer: payerAndRecipientTypes[];
  purpose: string;
  recipient: payerAndRecipientTypes[];
  status: statusTypes;
  type: string;
  updated_at: string;
}

export interface getOperationsDetailsTypes {
  id: number;
  created_at: string;
  ext_key: string;
  total_amount: number;
  updated_at: string;
  order: orderTypes;
  payer: payerTypes;
  payments: paymentsTypes[];
  status: statusTypes;
}

export const operationsApi = createApi({
  reducerPath: 'operationsApi',
  tagTypes: ['Operations', 'Tags', 'OperationsDetails', 'OperationsDetailsPayments'],
  refetchOnReconnect: true,
  keepUnusedDataFor: 30,
  // refetchOnFocus: true,
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_SERVER_TEST}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: getAuthorizationHeader(),
    },
  }),
  endpoints: (build) => ({
    getOperations: build.query<any, any>({
      query: () => ({
        url: `/operations`,
        method: 'GET',
      }),
      providesTags: () => ['Operations'],
      // transformResponse: (response: any) => response?.data,
      // providesTags: (result, error, arg) =>
      //   result
      //     ? [...result?.data?.items.map(({ id }: any) => ({ type: 'Worker', id })), 'Worker']
      //     : ['Worker']
      // providesTags: (result, error, arg): any => console.log(result, error, arg)
    }),
    getTabOperations: build.query<any, any>({
      query: ({ title }) => ({
        url: `/operations/${title}`,
        method: 'GET',
      }),
      providesTags: () => ['Tags'],
      // transformResponse: (response: any) => response?.data,
      // invalidatesTags: []
    }),
    getOperationsDetails: build.query<getOperationsDetailsTypes, any>({
      query: ({ name }) => ({
        url: `/operations/${name}`,
        method: 'GET',
      }),
      providesTags: () => ['OperationsDetails'],
    }),

    getOperationsDetailsPayments: build.query<paymentsTypes, any>({
      query: ({ name }) => ({
        url: `/operations/${name}`,
        method: 'GET',
      }),
      providesTags: () => ['OperationsDetails'],
    }),
  }),
});

export const {
  useGetTabOperationsQuery,
  useGetOperationsQuery,
  useGetOperationsDetailsQuery,
  useGetOperationsDetailsPaymentsQuery,
} = operationsApi;
