import { FC, useEffect, useState, useRef } from 'react';
import { InputDate } from 'components/InputDate';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import {
  setDocumentWorker,
  // setErrorStatusField,
  setNationalityWorker,
  setNotErrorStatusField,
  setWasChanges,
} from 'redux/mainInfo/slice';
import { ListSelect } from 'components/ListSelect';
// import { DOCUMENTS, NATIONALITY } from 'constants/stringConstants';
import styles from './MainDocument.module.scss';
import { ListSelectDisp } from 'components/ListSelectDisp';
import { AddressDadataReg } from 'components/AddressDadataReg';
import { PatternFormat } from 'react-number-format';
import { InputMask } from 'components/InputMask';
import { searchSuggestingApi } from 'api/getApi';
import { InputText } from 'ui/InputText';
import {
  setInfoProfileCreate,
  setDatePassport,
  setErrorStatusField,
} from 'redux/createProfile/slice';
import classNames from 'classnames';

export const DocumentInfo: FC = () => {
  const { series, number, dep_code, doc_place, doc_date, errorStatusField } = useSelector(
    (state: RootState) => state.createProfile,
  );

  const {
    documentTypes,
    nationalitiesList,
    nationalityWorker,
    documentWorker,
    // errorStatusField,
  } = useSelector((state: RootState) => state.mainInfo);
  const [address, setAddress] = useState<string>('');
  const [nameDocument, setDocument] = useState<string>('Паспорт РФ');
  const [documentsList, setDocumentsList] = useState<any>([]);
  /* const [nationality, setNationality] = useState<string>('Россия'); */
  const [focusSeria, setFocusSeria] = useState<boolean>(false);
  const [focusNum, setFocusNum] = useState<boolean>(false);
  const [focusIssue, setFocusIssue] = useState<boolean>(false);

  const [temporaryState, setTemporaryState] = useState('');
  const [suggestingValue, setSuggestingValue] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState<number>(0);
  const dispatch = useDispatch();
  //Паспортные данные номер серия кем выдан код подразделения дата выдачи
  // useEffect(() => {
  //   setAddress(address_passport);
  //   passport_s !== '' && setFocusSeria(true);
  //   number !== '' && setFocusNum(true);
  //   passport_kod !== '' && setFocusIssue(true);
  // }, [
  //   passport_s,
  //   passport_n,
  //   passport_kod,
  //   addressRegCity,
  //   addressRegStreet,
  //   addresRegHome,
  //   addresRegK,
  //   addressRegA,
  // ]);

  const searchSuggesting = async (value: string) => {
    setSuggestingValue(await searchSuggestingApi(value));
  };

  useEffect(() => {
    if (documentWorker === 21) {
      setDocument('Паспорт');
      dispatch(setDocumentWorker(21));
    } else if (documentWorker === 10) {
      setDocument('Иностранный паспорт');
      dispatch(setDocumentWorker(10));
    } else if (documentWorker === 12) {
      setDocument('ВНЖ');
      dispatch(setDocumentWorker(12));
    } else if (documentWorker === 14) {
      setDocument('Временное удостоверение');
      dispatch(setDocumentWorker(14));
    } else if (documentWorker === 23) {
      setDocument('Иностранное свидетельство о рождении');
      dispatch(setDocumentWorker(23));
    }
  }, []);

  useEffect(() => {
    if (nameDocument === 'Паспорт') {
      dispatch(setDocumentWorker(21));
    } else if (nameDocument === 'Иностранный паспорт') {
      dispatch(setDocumentWorker(10));
    } else if (nameDocument === 'ВНЖ') {
      dispatch(setDocumentWorker(12));
    } else if (nameDocument === 'Временное удостоверение') {
      dispatch(setDocumentWorker(14));
    } else if (nameDocument === 'Иностранное свидетельство о рождении') {
      dispatch(setDocumentWorker(23));
    }
  }, [nameDocument]);

  useEffect(() => {
    const doc = documentTypes.filter((el: any) => el.fns_code);
    const docTypes = doc.map((el: any) => {
      return el.alias;
    });
    setDocumentsList(docTypes);
  }, [documentTypes]);

  const handleSelectItem = (elem: any) => {
    dispatch(setWasChanges(true));
    // dispatch(setPassportIssued(elem?.value));
    dispatch(
      setInfoProfileCreate({
        key: 'doc_place',
        value: elem?.value,
      }),
    ),
      dispatch(
        setInfoProfileCreate({
          key: 'dep_code',
          value: elem?.data?.code.replace('-', ''),
        }),
      );
    setSuggestingValue([]);
    setTemporaryState('');
    if (errorStatusField.dep_code || errorStatusField.doc_place) {
      dispatch(setNotErrorStatusField('dep_code'));
      dispatch(setNotErrorStatusField('doc_place'));
    }
    inputMaskRef.current.blur();
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'ArrowUp') {
      setSelectedItem((prev: number) =>
        prev === 0 ? suggestingValue.length - 1 : prev - 1,
      );
    } else if (e.key === 'ArrowDown') {
      setSelectedItem((prev: number) =>
        prev === suggestingValue.length - 1 ? 0 : prev + 1,
      );
    } else if (e.key === 'Enter') {
      // Добавить логику для обработки нажатия Enter
      handleSelectItem(suggestingValue[selectedItem]);
      setSelectedItem(0);
    }
  };

  useEffect(() => {
    if (suggestingValue?.length > 0) document?.addEventListener('keydown', handleKeyDown);
    return () => {
      document?.removeEventListener('keydown', handleKeyDown);
    };
  }, [suggestingValue, selectedItem]);
  ///
  const inputMaskRef = useRef<any>(null);

  return (
    <div className={styles.root} id='mainDocAnchor'>
      <div className={styles.row}>
        {/* <NationalityList /> */}
        <ListSelectDisp
          placeholder='Гражданство'
          list={nationalitiesList}
          defaultValue='Россия'
          search
          activeItem={nationalityWorker}
          setActiveItem={setNationalityWorker}
        />
        <ListSelect
          placeholder='Документ'
          list={documentsList}
          defaultValue='Паспорт РФ'
          search
          activeItem={nameDocument}
          setActiveItem={setDocument}
        />
      </div>

      <div className={styles.row} style={{ position: 'relative' }}>
        <div className={styles.row_item}>
          <div className={styles.series}>
            <InputText
              maxLength={4}
              error={Boolean(errorStatusField.series)}
              nameError='series'
              placeholder='Серия'
              type='text'
              // props={{ maxLength: 4 }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(setErrorStatusField({ key: 'series', value: 0 }));
                dispatch(setInfoProfileCreate({ key: 'series', value: e.target.value }));
              }}
              value={series}
              classNameFocus={focusSeria}
            />
          </div>

          <div className={styles.number}>
            <InputText
              maxLength={6}
              error={Boolean(errorStatusField.number)}
              nameError='number'
              placeholder='Номер'
              type='text'
              // props={{ maxLength: 6 }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(setErrorStatusField({ key: 'number', value: 0 }));
                dispatch(setInfoProfileCreate({ key: 'number', value: e.target.value }));
              }}
              value={number}
              classNameFocus={focusNum}
            />
            {/* {errorStatusField.number ? (
              <p className={styles.errors}>Поле обязательно</p>
            ) : null} */}
          </div>
        </div>

        <div className={styles.code}>
          <InputMask
            onBlur={(e: any) => {
              if (!e.target.value)
                dispatch(setErrorStatusField({ key: 'dep_code', value: 1 }));
            }}
            disabled={false}
            inputMaskRef={inputMaskRef}
            error={Boolean(errorStatusField.dep_code)}
            value={dep_code}
            setValue={(value: any) => {
              // console.log(value.length);
              if (value.length === 0) {
                setSuggestingValue([]);
                dispatch(setInfoProfileCreate({ key: 'dep_code', value }));
                dispatch(setErrorStatusField({ key: 'dep_code', value: 1 }));
              }
              if (value.length > 0 && temporaryState) {
                dispatch(setInfoProfileCreate({ key: 'dep_code', value }));
                searchSuggesting(value);
                dispatch(setErrorStatusField({ key: 'dep_code', value: 0 }));
              }
            }}
            onKeyDown={() => {
              setTemporaryState('1');
              dispatch(setWasChanges(true));
            }}
            // onBlur
            placeholder='Код подразделения'
            type='number'
          />
          {!!suggestingValue.length && (
            <ul className={styles.list}>
              {suggestingValue?.map((elem: any, index: number) => (
                <li
                  // onKeyDown={(e) => console.log(e)}
                  className={`${styles.item} ${
                    selectedItem === index ? styles.item_active : ''
                  }`}
                  onClick={() => {
                    handleSelectItem(elem);
                  }}
                  key={index}>
                  {elem?.data?.code} {elem.value}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.issued}>
          <InputText
            error={Boolean(errorStatusField.doc_place)}
            nameError='doc_place'
            placeholder='Выдан'
            type='text'
            value={doc_place}
            onChange={(e: any) => {
              dispatch(setErrorStatusField({ key: 'doc_place', value: 0 }));
              dispatch(setInfoProfileCreate({ key: 'doc_place', value: e.target.value }));
            }}
            classNameFocus={focusIssue}
          />
        </div>
        <div className={styles.datePassport}>
          <InputDate
            withoutHour
            error={Boolean(errorStatusField.doc_date)}
            nameError='doc_date'
            placeholder='Дата выдачи'
            date={doc_date}
            setDate={setDatePassport}
          />
        </div>
      </div>

      <div
        className={classNames(
          styles.address,
          errorStatusField.reg_address && styles.address_error,
        )}>
        <AddressDadataReg placeholder='Адрес регистрации' objKey='reg_address' />
      </div>

      <div
        className={classNames(
          styles.address,
          errorStatusField.birth_place && styles.address_error,
        )}>
        <AddressDadataReg placeholder='Место рождения' objKey='birth_place' />
      </div>
    </div>
  );
};
