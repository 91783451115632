import { FC } from 'react';
import { BlockResize } from 'components/BlockResize';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Field } from 'ui/Field';
import { ReactComponent as Fns1 } from 'assets/img/taxStatus/ФНС.svg';
import { ReactComponent as Fns2 } from 'assets/img/taxStatus/fns2.svg';
import { ReactComponent as Fns3 } from 'assets/img/taxStatus/error.svg';
import styles from './FNSStatus.module.scss';

export const FNSStatus: FC = () => {
  const { infoEmployeds, openBlock } = useSelector(
    (state: RootState) => state.employedsDetailSlice,
  );

  // console.log('infoEmployeds', infoEmployeds);

  const { worker_fns } = infoEmployeds || {};

  return (
    <BlockResize open={openBlock} title='Налоговый статус'>
      <div className={styles.root}>
        <div className={styles.row}>
          <Field style={{ width: '192px' }} placeholder='ИНН' title={worker_fns?.inn} />
        </div>

        <div className={styles.block}>
          <div className={styles.block_svg}>
            {worker_fns?.fns_status === 2 ? (
              <Fns1 />
            ) : worker_fns?.fns_status === 1 ? (
              <Fns2 />
            ) : (
              <Fns3 />
            )}
          </div>
          <div className={styles.block_text}>
            <p className={styles.block_text_title}>Оплата налогов от имени исполнителя</p>
            <p className={styles.block_text_subTitle}>
              {worker_fns?.fns_status > 0
                ? 'Исполнитель предоставил доступ к приложению и разрешил оплату налогов'
                : 'Исполнитель утратил статус самозанятого'}
            </p>
          </div>
        </div>
      </div>
    </BlockResize>
  );
};
