import { useMemo } from 'react';
import {
  activeArrfilters,
  actionsArrFilters,
  actionsByWorkerArrFilters,
} from 'constants/filtersConstants';

export const useFilterOrders = (orders: any[], active: any) => {
  const nameFilter = localStorage.getItem('ordersFilter') || active.name;
  const filtredOrders = useMemo(() => {
    if (nameFilter === 'Все активные') {
      // return orders?.filter((order: any) =>
      //   activeArrfilters.some((item: string) => order.status.value.includes(item)),
      // );
      return orders?.filter((order: any) => order.status.text !== 'Черновик');
    }

    if (nameFilter === 'Требуют действия') {
      return orders?.filter((order: any) =>
        actionsArrFilters.some((item: string) => order.status.value.includes(item)),
      );
    }

    if (nameFilter === 'Требуют действия исполнителя') {
      return orders?.filter((order: any) =>
        actionsByWorkerArrFilters.some((item: string) =>
          order.status.value.includes(item),
        ),
      );
    }

    if (nameFilter === 'Завершенные') {
      return orders?.filter((order: any) => order.status.text === 'Заказ завершен');
    }

    if (nameFilter === 'Черновики') {
      return orders?.filter((order: any) => order.status.text === 'Черновик');
    }

    if (nameFilter === 'Все') {
      return orders;
    }

    return [];
  }, [active, orders]);
  return filtredOrders;
};
