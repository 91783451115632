import React from 'react';
import styles from './NotFound.module.scss';
import { Layout } from 'features/Layouts/Layouts';

export const NotFound = () => {
  return (
    <Layout>
      <div className={styles.root_nothing}>
        <p>Ничего не найдено</p>
      </div>
    </Layout>
  );
};
