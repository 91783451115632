import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './ModalContract.module.scss';
import { Title } from 'components/Title';
import { InputText } from 'ui/InputText';
import { Button } from 'ui/Button';
import { Modal } from 'components/Modal';
import { getFormatPhone } from 'utils/getFotmatPhone';
import { useLazyGetSmsCodeQuery, useSignContactMutation } from 'redux/ProfileApi';
import classNames from 'classnames';
import { PatternFormat } from 'react-number-format';

interface ModalProfileTypes {
  setShowModalContract: (e: boolean) => void;
  setStartTimer: (e: boolean) => void;
  timer: number;
  startTimer: boolean;
}

export const ModalContract: FC<ModalProfileTypes> = ({
  setShowModalContract,
  setStartTimer,
  timer,
  startTimer,
}) => {
  const patternFormatRef = useRef<any>(null);
  const [value, setValue] = useState('');
  const phone = localStorage.getItem('phone');
  const [signContract, { data, isLoading, error }] = useSignContactMutation<any>();
  const [getCode, { data: dataGetCode, error: errorGetCode }] =
    useLazyGetSmsCodeQuery<any>();

  const signHandler = async () => {
    if (value.length === 6) {
      await signContract({
        body: {
          code: Number(value),
        },
      });
    }
  };

  useEffect(() => {
    if (data) setShowModalContract(false);
  }, [data]);

  useEffect(() => {
    if (timer === 60) {
      getCode();
    }
    if (patternFormatRef.current) {
      patternFormatRef.current.focus();
    }
  }, []);

  const getCodeAgain = () => {
    if (timer === 60) {
      getCode();
      setStartTimer(true);
    }
  };

  const keyHandler = (e: any) => {
    if (e.key === 'Enter' && value.length === 6) {
      signHandler();
    }
  };

  // console.log('first', !startTimer, value.length < 6);

  return (
    <Modal setOpen={setShowModalContract}>
      <div className={styles.root}>
        <Title className={styles.root_title} title='Подписание договора' />
        <p className={styles.root_text}>
          Введите код отправленный на номер <b>{getFormatPhone(phone)}</b>
        </p>
        <div className={styles.root_inputBlock}>
          <p>Код подтверждения</p>
          <PatternFormat
            onKeyDown={(e) => keyHandler(e)}
            getInputRef={patternFormatRef}
            className={classNames(styles.input, error && styles.input_error)}
            format='###-###'
            placeholder=''
            onValueChange={(values: any) => {
              setValue(values.value);
            }}
            value={value}
          />
          {error?.data?.message && (
            <p style={{ color: '#E10D0D' }}>{error?.data?.message}</p>
          )}
        </div>
        <Button
          spanStyle={value.length < 6 ? { color: '#71869D' } : {}}
          disabled={value.length < 6}
          onClick={signHandler}
          className={styles.root_btn}>
          Подтвердить
        </Button>
        {/* // )} */}
        <p
          style={
            !startTimer
              ? { cursor: 'pointer', color: '#002cfb' }
              : { cursor: 'not-allowed' }
          }
          onClick={getCodeAgain}
          className={styles.root_link}>
          {errorGetCode?.data?.message && timer !== 60 ? (
            <>
              {errorGetCode.data.message} <span>{timer} сек</span>
            </>
          ) : (
            <>
              Не пришло смс? Запросите еще одно
              {timer !== 60 && (
                <>
                  {' '}
                  через <span>{timer} сек</span>
                </>
              )}
            </>
          )}
        </p>
      </div>
    </Modal>
  );
};
