import React, { useState } from 'react';
import styles from './Notifications.module.scss';
import { MainTitle } from 'components/MainTitle';
import { TabMenuFilter } from 'components/TabMenuFilter';
import { DateFilter } from 'components/DateFilter';

export const Notifications = () => {
  const [active, setActive] = useState<any>({ key: 'all', filter: null });

  const lists = [
    { name: 'Все', key: 'all', count: 3 },
    { name: 'Непрочитанные', key: 'Непрочитанные', count: 23 },
  ];

  return (
    <div className={styles.root}>
      <MainTitle title='Уведомления' />
      <div className={styles.root_header}>
        <TabMenuFilter count lists={lists} active={active.key} setActive={setActive} />
        <DateFilter />
      </div>
      <div></div>
    </div>
  );
};
