import { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { setWasChanges } from 'redux/mainInfo/slice';
import { ReactComponent as SearchSvg } from 'assets/img/filter/search.svg';
import styles from './InputSearch.module.scss';

const cx = classNames.bind(styles);

type InputSearchProps = {
  placeholder?: string;
  value: string;
  onChange?: any;
  onblurInput?: any;
  props?: any;
  style?: any;
  border?: boolean;
  setFocusSearchWorkers?: ((b: boolean) => void) | any;
  orders?: boolean;
  focus?: boolean;
  setFocus?: ((b: boolean) => void) | any;
  notFocus?: boolean;
};

export const InputSearch: FC<InputSearchProps> = ({
  placeholder = 'Искать...',
  value,
  onChange,
  onblurInput,
  props,
  style,
  border,
  orders,
  setFocusSearchWorkers,
  setFocus,
  focus,
  notFocus,
}) => {
  const inputRef = useRef<any>(null);
  // const [focus, setFocus] = useState<boolean>(false);
  const dispatch = useDispatch();

  const classNameInput = cx({
    input: true,
    focus: focus,
  });

  const classNameRoot = cx({
    root: true,
    root_border: border,
  });

  const changeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // dispatch(setValue(e.target.value.trimStart()));
    dispatch(setWasChanges(true));
  };

  const focusInput = () => {
    setFocus(true);
    if (orders) {
      // setFocusSearchWorkers(true);
    }
  };

  const blurInput = () => {
    if (!inputRef.current.value) {
      // setFocus(false);
      if (orders) {
        // setFocusSearchWorkers(false);
      }
    }
  };

  return (
    <div className={classNameRoot}>
      <SearchSvg className={styles.root_icon} />
      <input
        placeholder={placeholder}
        style={style}
        className={classNameInput}
        ref={inputRef}
        onChange={onChange}
        onFocus={notFocus ? undefined : focusInput}
        autoComplete='disabled'
        // onBlur={onblurInput}
        value={value}
        {...props}
      />
    </div>
  );
};
