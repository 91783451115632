import { FC, useEffect, useState, useRef } from 'react';
import { BlockResize } from 'components/BlockResize';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { setDocumentWorker } from 'redux/mainInfo/slice';
import { Field } from 'ui/Field';
import styles from './MainDocument.module.scss';

export const MainDocument: FC = () => {
  const { infoEmployeds, openBlock } = useSelector(
    (state: RootState) => state.employedsDetailSlice,
  );
  const { worker_app, nationality } = infoEmployeds || {};
  const [nameDocument, setDocument] = useState('Паспорт');
  const dispatch = useDispatch();

  // const searchSuggesting = async (value: string) => {
  //   setSuggestingValue(await searchSuggestingApi(value));
  // };

  // useEffect(() => {
  //   const { doc_fns_code } = worker_app;
  //   if (doc_fns_code === 21) {
  //     setDocument('Паспорт');
  //   } else if (doc_fns_code === 10) {
  //     setDocument('Иностранный паспорт');
  //     dispatch(setDocumentWorker(10));
  //   } else if (doc_fns_code === 12) {
  //     setDocument('ВНЖ');
  //   } else if (doc_fns_code === 14) {
  //     setDocument('Временное удостоверение');
  //   } else if (doc_fns_code === 23) {
  //     setDocument('Иностранное свидетельство о рождении');
  //   }
  // }, [worker_app]);

  return (
    <BlockResize open={openBlock} title='Основной документ'>
      <div className={styles.root} id='mainDocAnchor'>
        <div className={styles.row}>
          <Field
            style={{ width: '100%' }}
            placeholder='Гражданство'
            title={nationality}
          />
          <Field style={{ width: '100%' }} placeholder='Документ' title={nameDocument} />
        </div>

        <div className={styles.row} style={{ position: 'relative' }}>
          <div className={styles.series}>
            <Field
              style={{ width: '128px' }}
              placeholder='Серия'
              title={infoEmployeds.passport_s}
            />
          </div>

          <div className={styles.number}>
            <Field
              style={{ width: '190px' }}
              placeholder='Номер'
              title={infoEmployeds.passport_n}
            />
          </div>

          <div className={styles.code}>
            <Field
              style={{ width: '190px' }}
              placeholder='Код подразделения'
              title={infoEmployeds.passport_kod}
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.issued}>
            <Field
              style={{ width: '330px' }}
              placeholder='Выдан'
              title={infoEmployeds.passport_kem}
            />
          </div>
          <div className={styles.datePassport}>
            <Field
              style={{ width: '190px' }}
              placeholder='Дата выдачи'
              title={infoEmployeds.passport_date}
            />
          </div>
        </div>

        <div className={styles.address}>
          <Field
            style={{ width: '100%' }}
            placeholder='Адрес прописки'
            title={infoEmployeds.address_passport}
          />
        </div>
      </div>
    </BlockResize>
  );
};
