import moment from 'moment';
import 'moment/locale/ru';

export const getFotmattedDateCreated = (date: string) => {
  const format = date?.length > 10 ? 'YYYYMMDDhhmmss' : 'DDMMYYYY';
  if (!date) return;

  const dateMoment = moment(date, format).fromNow(true);

  const dateText = dateMoment.replaceAll(' ', '').replace(/[0-9]/g, '');
  const dateNumber = dateMoment.replace(/[^0-9]/g, '');

  //момент выдает "месяц", нам нужно добавить "1 месяц"
  if (!Number.isInteger(parseInt(dateMoment.charAt(0)))) {
    return `1 ${dateNumber} ${getShortDate(dateText)}`;
  } else return `${dateNumber} ${getShortDate(dateText)}`;
};

export const getShortDate = (date: any) => {
  if (date === 'секунда' || date === 'несколькосекунд') return 'сек';
  if (date === 'секунды') return 'сек';
  if (date === 'секунд') return 'сек';
  if (date === 'минута') return 'мин';
  if (date === 'минуты') return 'мин';
  if (date === 'минут') return 'мин';
  if (date === 'час') return 'час';
  if (date === 'часа') return 'час';
  if (date === 'часов') return 'час';
  if (date === 'день') return 'дн';
  if (date === 'дня') return 'дн';
  if (date === 'дней') return 'дн';
  if (date === 'неделя') return 'нед';
  if (date === 'недели') return 'нед';
  if (date === 'недель') return 'нед';
  if (date === 'месяц') return 'мес';
  if (date === 'месяца') return 'мес';
  if (date === 'месяцев') return 'мес';
  if (date === 'год') return 'год';
  if (date === 'года') return 'год';
  if (date === 'лет') return 'лет';
  return date;
};

const insertZeroNum = (count: number) => {
  return count < 10 ? `0${count}` : count;
};

export const formattedDateMozilla = (date: any) => {
  const formattedDate = new Date(date[2], date[1] - 1, date[0]);
  // Форматирование даты в формат ГГГГ-ММ-ДД
  const year = formattedDate.getFullYear();
  const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
  const day = String(formattedDate.getDate()).padStart(2, '0');
  return year + '-' + month + '-' + day;
};

export const getDateFormattedDateInfo = (date: any) => {
  const format = 'DD.MM.YYYY';
  return moment(new Date(`${date}`)).format(format);
};

export const currentNewDay = () => {
  return moment().format('L');
};

export const isAfterDate = (date: string) => {
  const now = moment();
  const targetDate = moment(date, 'DD-MM-YYYY');
  // if (now.isSame(targetDate, 'day')) return date;
  if (now.isSame(targetDate, 'day')) return moment(date).format('L');
  if (now.isAfter(targetDate)) return moment().format('L');
};

export const formattedDateWithHour = (date: any) => {
  const formattedDate = new Date(date[2], date[1] - 1, date[0]);
  // Форматирование даты в формат ГГГГ-ММ-ДД
  const year = formattedDate.getFullYear();
  const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
  const day = String(formattedDate.getDate()).padStart(2, '0');
  return year + '-' + month + '-' + day;
};

export const currentYearNewFormat = moment().format('YYYY');

export const getDateFormattedDateForParams = (date: any) => {
  // const format = 'YYYY-MM-DD HH:mm';
  const format = 'YYYY-MM-DD';
  return moment(new Date(`${date}`)).format(format);
};

export const getDateFormattedDateForParamsString = (date: any) => {
  // const dateHour = date?.split(' ')[1];
  // const dateArr = date?.split(' ')[0];
  // return `${dateArr?.split('.').reverse().join('-')} ${dateHour}`;
  return `${date?.split('.').reverse().join('-')}`;
};

export const getDateFormatForBack = (date: any) => {
  return moment(date).format('YYYY-MM-DD');
};

export const getDateFormatted = (date: any) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  return `${year}-${insertZeroNum(month + 1)}-${insertZeroNum(day)}`;
};

export const getDateFormattedCreateProfile = (date: any) => {
  if (!date) return '';

  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  return `${insertZeroNum(day)}.${insertZeroNum(month + 1)}.${year}`;
};

export const getDatePicker = (value: any) => {
  const date = new Date(value);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  return `${year}-${insertZeroNum(month + 1)}-${insertZeroNum(day)}`;
};

export const getCurrentDay = () => {
  const date = new Date();
  return getDateFormatted(date);
};

export const getThreeDay = () => {
  const date = new Date();
  date.setDate(date.getDate() - 2);
  return getDateFormatted(date);
};

export const getWeek = () => {
  const date = new Date();
  date.setDate(date.getDate() - 6);
  return getDateFormatted(date);
};

export const getLastWeek = (value: 'start' | 'end') => {
  if (value === 'start') {
    const dateStart = moment().subtract(1, 'week').startOf('week').format();
    const date = new Date(dateStart);
    return getDateFormatted(date);
  }

  if (value === 'end') {
    const dateEnd = moment().subtract(1, 'week').endOf('week').format();
    const date = new Date(dateEnd);
    return getDateFormatted(date);
  }
};

export const getTwoLastWeek = () => {
  const date = new Date();
  date.setDate(date.getDate() - 13);
  return getDateFormatted(date);
};

export const getMonth = () => {
  const dateStart = moment().subtract(1, 'month').format();
  const date = new Date(dateStart);
  return getDateFormatted(date);
};

export const getYear = () => {
  const dateStart = moment().subtract(1, 'year').format();
  const date = new Date(dateStart);
  return getDateFormatted(date);
};

export const getLastMonth = (value: 'start' | 'end' | 'title') => {
  if (value === 'start') {
    const dateStart = moment().subtract(1, 'month').startOf('month').format();
    const date = new Date(dateStart);
    return getDateFormatted(date);
  }

  if (value === 'end') {
    const dateEnd = moment().subtract(1, 'month').endOf('month').format();
    const date = new Date(dateEnd);
    return getDateFormatted(date);
  }

  if (value === 'title') {
    const title = moment().subtract(1, 'month').format('MMMM');
    return title;
  }
};

export const getBeforeLastMonth = (value: 'start' | 'end' | 'title') => {
  if (value === 'start') {
    const dateStart = moment().subtract(2, 'month').startOf('month').format();
    const date = new Date(dateStart);
    return getDateFormatted(date);
  }

  if (value === 'end') {
    const dateEnd = moment().subtract(2, 'month').endOf('month').format();
    const date = new Date(dateEnd);
    return getDateFormatted(date);
  }

  if (value === 'title') {
    const title = moment().subtract(2, 'month').format('MMMM');
    return title;
  }
};

export const getTitleDateNewFormat = (dateStart: any, dateEnd: any) => {
  const start = moment(new Date(`${dateStart}`)).format('DD.MM.YY');
  const end = moment(new Date(`${dateEnd}`)).format('DD.MM.YY');

  return `${start}-${end}`;
};

export const getTitleDate = (dateStart: any, dateEnd: any) => {
  const start = moment(new Date(`${dateStart}`)).format('DD MMMM');
  const end = moment(new Date(`${dateEnd}`)).format('DD MMMM');

  return `${start} - ${end}`;
};

export const getTitleDateForEnd = (dateEnd: any) => {
  const end = moment(new Date(`${dateEnd}`)).format('DD MMMM');

  return end;
};

export const getTitleDateDuration = (
  dateStart: any,
  dateEnd: any,
  viewFullMonth = false,
) => {
  const currentYear = new Date().getFullYear();
  const startYear = new Date(`${dateStart}`).getFullYear();
  const endYear = new Date(`${dateEnd}`).getFullYear();

  const startMonth = new Date(`${dateStart}`).getMonth() + 1;
  const endMonth = new Date(`${dateEnd}`).getMonth() + 1;

  const startDay = new Date(`${dateStart}`).getDate();
  const endDay = new Date(`${dateEnd}`).getDate();

  //чтобы не было предупреждения о формате даты, то написать во всех moment(new Date(`${dateStart}`)) *DONE*
  const lastDayMonth = moment(new Date(`${dateStart}`))
    .endOf('month')
    .format('D');

  if (
    startYear === endYear &&
    startMonth === 1 &&
    endMonth === 12 &&
    startDay === 1 &&
    endDay === 31
  ) {
    //выбран полный год
    return `${moment(new Date(`${dateStart}`)).format('YYYY')}`;
  }

  //выбран день текущего года
  if (dateStart === dateEnd && currentYear === startYear) {
    return moment(new Date(`${dateStart}`)).format('D MMMM');
  }

  //выбран день другого года
  if (dateStart === dateEnd && currentYear !== startYear) {
    return `${moment(new Date(`${dateStart}`)).format('D MMMM YYYY')}`;
  }

  //Выбран период в одном месяце текущего года
  if (currentYear === startYear && startMonth === endMonth) {
    //выбран полный месяц текущего года
    if (startDay === 1 && endDay === +lastDayMonth && !viewFullMonth) {
      const date = `${moment(new Date(`${dateStart}`)).format('MMMM')}`;
      return date[0].toUpperCase() + date.slice(1);
    }

    return `${startDay}—${moment(new Date(`${dateEnd}`)).format('D MMMM')}`;
  }

  //Выбран период в одном месяце другого года
  if (currentYear !== endYear && startMonth === endMonth) {
    //выбран полный месяц другого года
    if (startDay === 1 && endDay === +lastDayMonth && !viewFullMonth) {
      const date = `${moment(new Date(`${dateStart}`)).format('MMMM YYYY')}`;
      return date[0].toUpperCase() + date.slice(1);
    }

    return `${startDay} — ${moment(new Date(`${dateEnd}`)).format('D MMMM YYYY')}`;
  }

  //Выбран период в разные месяцы текущего года
  if (currentYear === startYear && currentYear === endYear && startMonth !== endMonth) {
    return `${moment(new Date(`${dateStart}`)).format('D MMMM')} — ${moment(
      new Date(`${dateEnd}`),
    ).format('D MMMM')}`;
  }

  //Выбран период в разные месяцы другого года
  if (currentYear !== endYear && currentYear !== startYear && startMonth !== endMonth) {
    return `${moment(new Date(`${dateStart}`)).format('D MMMM')} — ${moment(
      new Date(`${dateEnd}`),
    ).format('D MMMM YYYY')}`;
  }

  //Выбран период в разные года
  if (startYear !== endYear) {
    return `${moment(new Date(`${dateStart}`)).format('D MMMM YYYY')} — ${moment(
      new Date(`${dateEnd}`),
    ).format('D MMMM YYYY')}`;
  }
};

export const currentDay = (date: string) => {
  const dateObject = moment(date, 'YYYY-MM-DD');
  return dateObject.format('DD.MM.YYYY');
};

export const dateForOrderDetails = (date: string) => {
  const dateObject = moment(date, 'YYYY-MM-DD hh:mm:ss');
  return dateObject.format('DD.MM.YYYY HH:mm');
};

export const getTitleColumnDate = (value: string) => {
  const dayNumber = moment(value).format('D');
  const day = moment(value).format('dd');
  return `${dayNumber}, ${day}`;
};

export const getDayIsMonday = (columnIndex: number, value: string) => {
  const day = moment(value).format('dd');
  if (day === 'пн' && columnIndex > 1) {
    return true;
  } else return false;
};

export const dateDayTime = (dateValue: string) => {
  if (isNaN(Date.parse(`${dateValue}`))) {
    return null;
  }

  const date = new Date(`${dateValue}`);
  return `${getTitleDateDuration(date, date)} ${date.toLocaleTimeString().slice(0, -3)}`;
};

export const getDateFormattedDot = (date: any) => {
  const dateFormated = new Date(date);
  const millisecondsDate = dateFormated.getTime();

  const currentDay = new Date();

  if (!date) return '';

  if (millisecondsDate < currentDay.setHours(currentDay.getHours() - 1)) {
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const day = new Date().getDate();
    return `${insertZeroNum(day)}.${insertZeroNum(month + 1)}.${year}`;
  } else {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return `${insertZeroNum(day)}.${insertZeroNum(month + 1)}.${year}`;
  }

  // const hour = date.getHours();
  // const minuts = date.getMinutes();

  // console.log(date.getHours(), date.getMinutes());
  //  ${insertZeroNum(
  //   hour,
  // )}:${insertZeroNum(minuts)}`;
};

export const setDateToday = (n: any) => {
  const date = new Date(n);
  date.setDate(date.getDate());
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const year = date.getFullYear();

  const month = date.getMonth();
  let fMonth;
  switch (month) {
    case 0:
      fMonth = 'января';
      break;
    case 1:
      fMonth = 'февраля';
      break;
    case 2:
      fMonth = 'марта';
      break;
    case 3:
      fMonth = 'апреля';
      break;
    case 4:
      fMonth = 'мая';
      break;
    case 5:
      fMonth = 'июня';
      break;
    case 6:
      fMonth = 'июля';
      break;
    case 7:
      fMonth = 'августа';
      break;
    case 8:
      fMonth = 'сентября';
      break;
    case 9:
      fMonth = 'октября';
      break;
    case 10:
      fMonth = 'ноября';
      break;
    case 11:
      fMonth = 'декабря';
      break;
    default:
  }
  const dayWeek = date.getDay();
  let fDay;
  switch (dayWeek) {
    case 1:
      fDay = 'Пн';
      break;
    case 2:
      fDay = 'Вт';
      break;
    case 3:
      fDay = 'Ср';
      break;
    case 4:
      fDay = 'Чт';
      break;
    case 5:
      fDay = 'Пт';
      break;
    case 6:
      fDay = 'Сб';
      break;
    default:
      fDay = 'Вс';
  }

  const day = date.getDate();
  const dateText = `${day} ${fMonth}`;
  return dateText;
};

export const getIsBefore = (date: any) => {
  return moment(date).isBefore(getDateFormatted(new Date()));
};

export const getMounth = (date: string) => {
  const mounth = moment(new Date(`${date}`)).format('MMMM YYYY');
  return mounth[0].toUpperCase() + mounth.slice(1);
};

export const getOnlyMounth = () => {
  return moment(new Date()).format('MMMM');
};

export const listMounth = [
  { name: 'январь', key: 1 },
  { name: 'февраль', key: 2 },
  { name: 'март', key: 3 },
  { name: 'апрель', key: 4 },
  { name: 'май', key: 5 },
  { name: 'июнь', key: 6 },
  { name: 'июль', key: 7 },
  { name: 'август', key: 8 },
  { name: 'сентябрь', key: 9 },
  { name: 'октябрь', key: 10 },
  { name: 'ноябрь', key: 11 },
  { name: 'декабрь', key: 12 },
];
