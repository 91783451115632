import { Nothing } from 'components/Nothing';
import React, { FC } from 'react';
import styles from './History.module.scss';

interface HistoryTabTypes {
  id: number | null;
  title: string;
}

export const HistoryTab: FC<HistoryTabTypes> = ({ id, title }) => {
  // const { data, isLoading } = useGetTabQuery({ id, title });
  // console.log('HistoryTab', data);
  return <Nothing style={{ height: '640px' }} title='Ничего не найдено' />;
};
