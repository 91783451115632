import React, { FC } from 'react';
import { currentDay, getFotmattedDateCreated } from 'utils/date';
import { ReactComponent as IconSvg } from './img/icon.svg';
import styles from './PaymentsEmployedsItem.module.scss';
import { addSpaceNumber } from 'utils/addSpaceNumber';
import { BeneficiariesTabBtn } from 'ui/BeneficiariesTabBtn';

interface PaymentsEmployedsItemTypes {
  payment: any;
}

export const PaymentsEmployedsItem: FC<PaymentsEmployedsItemTypes> = ({ payment }) => {
  // console.log('PaymentsEmployeds', payment);
  return (
    <div className={styles.root}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p className={styles.root_text}>
          {payment.tochka_created_at
            ? currentDay(payment.tochka_created_at?.split(' ')[0])
            : null}
        </p>
        <p className={styles.root_subText}>{payment.id}</p>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p className={styles.root_text}>{payment.beneficiary?.customer?.jur_name}</p>
        <div style={{ display: 'flex', columnGap: '10px', padding: '0 16px' }}>
          <p style={{ padding: 0 }} className={styles.root_subText}>
            {`ИНН ${payment.beneficiary?.customer?.inn}`}
          </p>
          <p style={{ padding: 0 }} className={styles.root_subText}>
            {`КПП ${payment.beneficiary?.customer?.kpp}`}
          </p>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p className={styles.root_text}>{payment.type}</p>
        <p className={styles.root_subText}>{payment.purpose}</p>
      </div>
      <p style={{ textAlign: 'end' }}>{addSpaceNumber(payment.amount)}</p>

      <div className={styles.iconTitle}>
        <div style={{ padding: '0 16px' }}>
          <BeneficiariesTabBtn
            classNameStyles={styles.statusBlock}
            oldStyle
            bg={payment.status?.color_bg}
            color={payment.status?.color_text}
            title={payment.status.text}
          />
        </div>
        <p className={styles.root_subText}>
          <IconSvg /> {getFotmattedDateCreated(payment.tochka_updated_at)}. назад
        </p>
      </div>
    </div>
  );
};
