import React from 'react';
import { ReactComponent as SuccessIcon } from './img/success.svg';
import styles from './SuccessInfo.module.scss';
import { Title } from 'components/Title';

export const SuccessInfo = () => {
  return (
    <div className={styles.root}>
      <SuccessIcon />
      <div className={styles.root_blockText}>
        <Title title='Исполнитель добавлен' />
        {/* <p className={styles.root_blockText_text}>
          Мы отправили СМС-приглашение исполнителю
        </p> */}
      </div>
    </div>
  );
};
