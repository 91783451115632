import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowSvg } from 'assets/img/arrowOpenBlock.svg';
import { ReactComponent as CalendarSvg } from 'assets/img/iconCalendar.svg';
import styles from './SelectedMounth.module.scss';
import { addSpaceNumber } from 'utils/addSpaceNumber';

interface ListMounthTypes {
  name: string;
  key: number;
}

interface SelectedMounthTypes {
  listMounth: any;
  selectedMounth: any;
  total_amount: number;
  setSelectedMounth: (e: string) => void;
}

export const NewSelectedMounth: FC<SelectedMounthTypes> = ({
  setSelectedMounth,
  selectedMounth,
  listMounth,
  total_amount,
}) => {
  const [show, setShow] = useState(false);
  // const [selectedMounth, setSelectedMounth] = useState(getOnlyMounth());
  // console.log('selectedMounth', selectedMounth);

  const func = (mounth: any) => {
    // listMounth
    setSelectedMounth(mounth.name);
  };

  const prevHandler = () => {
    if (listMounth.length > 0 && selectedMounth) {
      const currentMounth = listMounth.find((elem: any) => {
        if (selectedMounth?.key - 1 > 0) {
          return elem.key === selectedMounth?.key - 1;
        } else if (selectedMounth?.key - 1 === 0) {
          return elem.key === 12;
        }
      });
      setSelectedMounth(currentMounth.name);
    }
  };

  const nextHandler = () => {
    if (listMounth.length > 0 && selectedMounth) {
      const currentMounth = listMounth.find((elem: any) => {
        if (selectedMounth?.key + 1 < 13) {
          return elem.key === selectedMounth?.key + 1;
        } else if (selectedMounth?.key + 1 === 13) {
          return elem.key === 1;
        }
      });
      setSelectedMounth(currentMounth.name);
    }
  };

  return (
    <div className={classNames(styles.root)}>
      <div
        onClick={() => setShow(!show)}
        className={classNames(
          styles.root_titleBlock,
          show && styles.root_titleBlock_rotateSvg,
        )}>
        <div onClick={prevHandler} className={styles.leftArrow}>
          <ArrowSvg width={12} height={16} />
        </div>
        <div className={styles.root_title}>
          <CalendarSvg />
          <p>{selectedMounth.name}</p>
        </div>

        <div onClick={nextHandler} className={styles.rightArrow}>
          <ArrowSvg width={12} height={16} />
        </div>
      </div>
      <p className={styles.root_price}>
        {total_amount ? addSpaceNumber(total_amount) : 0} ₽
      </p>
    </div>
  );
};
