import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const getToken = () => localStorage.getItem('token');
const getRole = () => localStorage.getItem('role');

const getBaseUrl = () => {
  const role = getRole();
  return role !== 'director'
    ? process.env.REACT_APP_API_SERVER_TEST
    : process.env.REACT_APP_API_SERVER_TEST_EMPLOYEDS;
};

const getAuthorizationHeader = () => {
  const token = getToken();
  return token ? `Bearer ${token}` : '';
};

interface WorkerAppTypes {
  app_status: number;
  avatar: string | null;
}

interface Employed {
  created_at: string;
  fns_status: number;
  id: number;
  inn: string;
  last_info_update: string;
  name: string;
  nationality: string;
  patronymic: string;
  phone: string;
  surname: string;
  worker_app: WorkerAppTypes;
}

interface ItemTypes {
  count: number;
  filters: string[];
  idx: number;
  key: string;
  name: string;
}

interface TabsTypes {
  count: number;
  items?: ItemTypes[];
}

// https://{domain}/api/tochka/beneficiaries
export const employedsApi = createApi({
  reducerPath: 'employedsApi',
  tagTypes: ['Employeds', 'Employed', 'Tags', 'EmployedsTabs'],
  refetchOnReconnect: true,
  keepUnusedDataFor: 15,
  // refetchOnFocus: true,
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl(),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: getAuthorizationHeader(),
    },
  }),
  endpoints: (build) => ({
    getEmployeds: build.query<Employed[] | any, { params: any }>({
      query: ({ params }) => ({
        url: `${getRole() === 'director' ? 'workers' : 'workers-app'}`,
        method: 'GET',
        params,
      }),
      providesTags: () => ['Employeds'],
    }),

    getTabEmployedsCustomer: build.query<TabsTypes, any>({
      query: () => ({
        url: `workers/tabs`,
        method: 'GET',
      }),
      providesTags: () => ['EmployedsTabs'],
    }),
    // /workers-app/{worker_app_id}/show
    getEmployed: build.query<any, any>({
      query: ({ id }) => ({
        url: `/workers/${id}`,
        method: 'GET',
      }),
      providesTags: () => ['Employed'],
    }),
    // /workers-app/{worker_app_id}/tabs/payments
    getTabEmployeds: build.query<any, any>({
      query: ({ id, title }) => ({
        url: `/workers/${id}/tabs/${title}`,
        method: 'GET',
      }),
      providesTags: () => ['Tags'],
      // transformResponse: (response: any) => response?.data,
      // invalidatesTags: []
    }),
    inviteEmployeds: build.mutation<any, any>({
      query: ({ body }) => ({
        url: `/workers/invite`,
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['Employeds'],
    }),
  }),
});

export const {
  useGetTabEmployedsQuery,
  useGetEmployedQuery,
  useGetEmployedsQuery,
  useGetTabEmployedsCustomerQuery,
  useInviteEmployedsMutation,
} = employedsApi;

// export const employedsApi = createApi({
//   reducerPath: 'employedsApi',
//   tagTypes: ['Employeds', 'Employed', 'Tags', 'EmployedsTabs'],
//   refetchOnReconnect: true,
//   keepUnusedDataFor: 15,
//   // refetchOnFocus: true,
//   baseQuery: fetchBaseQuery({
//     baseUrl: '/',
//     // baseUrl: `${
//     //   role !== 'director'
//     //     ? process.env.REACT_APP_API_SERVER_TEST
//     //     : process.env.REACT_APP_API_SERVER_TEST_EMPLOYEDS
//     // }`,
//     headers: {
//       'Content-Type': 'application/json',
//       // Authorization: token ? `Bearer ${token}` : '',
//       // Accept: 'application/json',
//     },
//   }),
//   endpoints: (build) => ({
//     getEmployeds: build.query<Employed[] | any, { params: any }>({
//       query: ({ params }) => {
//         const token = localStorage.getItem('token');
//         const role = localStorage.getItem('role');
//         const url =
//           role !== 'director'
//             ? process.env.REACT_APP_API_SERVER_TEST
//             : process.env.REACT_APP_API_SERVER_TEST_EMPLOYEDS;
//         const Authorization = token ? `Bearer ${token}` : undefined;
//         return {
//           url: `${url}/workers`,
//           method: 'GET',
//           headers: {
//             Authorization,
//             Accept: 'application/json',
//           },
//           params,
//         };
//       },
//       providesTags: () => ['Employeds'],
//     }),

//     getTabEmployedsCustomer: build.query<TabsTypes, any>({
//       query: () => {
//         const token = localStorage.getItem('token');
//         const role = localStorage.getItem('role');
//         const url =
//           role !== 'director'
//             ? process.env.REACT_APP_API_SERVER_TEST
//             : process.env.REACT_APP_API_SERVER_TEST_EMPLOYEDS;
//         const Authorization = token ? `Bearer ${token}` : undefined;
//         return {
//           url: `${url}/workers/tabs`,
//           method: 'GET',
//           headers: {
//             Authorization,
//             Accept: 'application/json',
//           },
//         };
//       },
//       providesTags: () => ['EmployedsTabs'],
//     }),
//     // /workers-app/{worker_app_id}/show
//     getEmployed: build.query<any, any>({
//       query: ({ id }) => {
//         const token = localStorage.getItem('token');
//         const role = localStorage.getItem('role');
//         const url =
//           role !== 'director'
//             ? process.env.REACT_APP_API_SERVER_TEST
//             : process.env.REACT_APP_API_SERVER_TEST_EMPLOYEDS;
//         const Authorization = token ? `Bearer ${token}` : undefined;
//         return {
//           url: `${url}/workers/${id}`,
//           method: 'GET',
//           headers: {
//             Authorization,
//             Accept: 'application/json',
//           },
//         };
//       },
//       providesTags: () => ['Employed'],
//     }),
//     // /workers-app/{worker_app_id}/tabs/payments
//     getTabEmployeds: build.query<any, any>({
//       query: ({ id, title }) => {
//         const token = localStorage.getItem('token');
//         const role = localStorage.getItem('role');
//         const url =
//           role !== 'director'
//             ? process.env.REACT_APP_API_SERVER_TEST
//             : process.env.REACT_APP_API_SERVER_TEST_EMPLOYEDS;
//         const Authorization = token ? `Bearer ${token}` : undefined;
//         return {
//           url: `${url}/workers/${id}/tabs/${title}`,
//           method: 'GET',
//           headers: {
//             Authorization,
//             Accept: 'application/json',
//           },
//         };
//       },
//       providesTags: () => ['Tags'],
//       // transformResponse: (response: any) => response?.data,
//       // invalidatesTags: []
//     }),
//     inviteEmployeds: build.mutation<any, any>({
//       query: ({ body }) => {
//         const token = localStorage.getItem('token');
//         const role = localStorage.getItem('role');
//         const url =
//           role !== 'director'
//             ? process.env.REACT_APP_API_SERVER_TEST
//             : process.env.REACT_APP_API_SERVER_TEST_EMPLOYEDS;
//         const Authorization = token ? `Bearer ${token}` : undefined;
//         return {
//           url: `${url}/workers/invite`,
//           method: 'POST',
//           body,
//           headers: {
//             Authorization,
//             Accept: 'application/json',
//           },
//         };
//       },
//       invalidatesTags: ['Employeds'],
//     }),
//   }),
// });
