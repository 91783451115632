import { FC, useState } from 'react';
import operation from 'assets/img/navSvg/operation.svg';
import benef from 'assets/img/navSvg/benef.svg';
import iconSettings from 'assets/img/navSvg/iconSettings.svg';
import employed from 'assets/img/navSvg/employed.svg';
import help from 'assets/img/navSvg/help.svg';
import styles from './NavItem.module.scss';
import { Link, useLocation } from 'react-router-dom';

// interface NavItem {}
interface NavListTypes {
  navItems?: any;
}

export const NavList: FC<NavListTypes> = ({ navItems }) => {
  const [activeItem, setActiveItem] = useState<number>(0);
  const { pathname } = useLocation();

  const clickItem = (item: number) => {
    setActiveItem(item);
  };

  return (
    <nav>
      <ul className={styles.list}>
        {navItems.map((item: any, i: number) => (
          <Link to={item[2]} key={i}>
            <li
              className={
                pathname.includes(item[2]) && item[2].length > 1
                  ? `${styles.active} ${styles.item}`
                  : styles.item
              }
              onClick={() => clickItem(i)}>
              <img className={styles.img} src={item[1]} alt={item[0]} />
              {item[0]}
            </li>
          </Link>
        ))}
      </ul>
    </nav>
  );
};
