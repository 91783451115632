import { FC, useEffect, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import styles from './InputMask.module.scss';
import classNames from 'classnames';
import {
  setErrorStatusField,
  setNotErrorStatusField,
  setResetErrorStatusField,
} from 'redux/mainInfo/slice';
import { useDispatch } from 'react-redux';

type InputTextProps = {
  placeholder: string;
  value: string;
  setValue: (s: any) => void;
  // props?: any;
  type?: 'tel' | 'number';
  onKeyDown?: any;
  error?: boolean;
  // classNameFocus?: boolean;
  inputMaskRef?: any;
  appStatus?: any;
  nameError?: string;
  isModerated?: boolean;
  width?: boolean;
  disabled?: boolean;
  onBlur?: any;
};

export const InputMask: FC<InputTextProps> = ({
  value,
  setValue,
  placeholder,
  type,
  onKeyDown,
  error,
  inputMaskRef,
  appStatus,
  nameError,
  isModerated = false,
  width,
  disabled = true,
  onBlur,
}) => {
  const verifStatus = appStatus === 0 || appStatus === null ? false : appStatus;
  // const [mask, setMask] = useState('');
  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (type === 'tel') {
  //     document.addEventListener('keydown', function (event) {
  //       if (event.ctrlKey && event.key === 'v') {
  //         // console.log('object');
  //         // Ваш код для обработки Ctrl+V
  //         // console.log('Вы нажали Ctrl+V');
  //         setMask('+# (###) ###-##-##');
  //       }
  //     });
  //   }
  // }, [type, value, onKeyDown]);
  return (
    <div className={styles.rootPhone}>
      <PatternFormat
        // disabled={(type === 'tel' && verifStatus) || isModerated}
        disabled={disabled}
        getInputRef={inputMaskRef}
        onKeyDown={onKeyDown}
        placeholder={type === 'number' ? 'xxx-xxx' : ''}
        className={classNames(
          styles.tel,
          error ? styles.telError : '',
          type === 'tel' && appStatus ? styles.telStatus : '',
          width ? styles.tel_width : '',
        )}
        // format={type === 'tel' ? mask : '###-###'}
        format={
          type === 'tel'
            ? value?.length === 11
              ? '+# (###) ###-##-##'
              : '+7 (###) ###-##-##'
            : '###-###'
        }
        value={value}
        valueIsNumericString={true}
        onBlur={onBlur}
        onValueChange={(values) => {
          setValue(values.value);
          if (values.value.length < 10) {
            dispatch(setErrorStatusField(nameError));
          } else {
            dispatch(setNotErrorStatusField(nameError));
          }
        }}
      />
      <span
        style={isModerated ? { background: 'transparent' } : {}}
        className={classNames(
          styles.placeholder,
          (type === 'number' || value) && styles.placeholder_active,
          error ? styles.placeholderError : '',
          type === 'tel' && appStatus ? styles.placeholderStatus : '',
        )}>
        {placeholder}
      </span>
    </div>
  );
};
