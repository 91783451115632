import { Header } from 'components/Header';
import { Nav } from 'components/Nav';
import { ReactNode } from 'react';
import styles from './Layouts.module.scss';

type Props = {
  children: ReactNode;
};

export const Layout = ({ children }: Props) => {
  return (
    <div className={styles.worker}>
      <Nav />
      <div className={styles.content}>
        <Header />
        <div style={{ margin: '0 75px' }}>{children}</div>
      </div>
    </div>
  );
};
