import { FC } from 'react';
import { Avatar } from 'components/Avatar';
// import { getStatusActive } from 'utils/getStatus';
import { AppStatus } from 'components/AppStatus';
import { Phone } from 'components/Phone';
import classNames from 'classnames';
import styles from './InfoEmployeds.module.scss';

type InfoEmployedsProps = {
  name: string;
  surname: string;
  phone?: string;
  id: number;
  avatar: string | null;
  status: number;
};

export const InfoEmployeds: FC<InfoEmployedsProps> = ({
  id,
  avatar,
  name = 'исполнитель',
  surname = 'Неизвестный',
  phone,
  status,
}) => {
  return (
    <div className={styles.root}>
      <Avatar
        name={name}
        surname={surname}
        avatar={avatar}
        id={id}
        statusActive={'active'}
      />

      <div className={styles.text}>
        <div className={styles.name}>
          <div className={classNames(styles.name_text)}>
            {surname ? `${surname} ${name}` : ''}
          </div>
          {/* <AppStatus workerAppStatus={status} /> */}
        </div>
        {phone && (
          <div className={styles.phone}>
            <Phone miniPhone workerPhone phone={phone} />
          </div>
        )}
      </div>
    </div>
  );
};
