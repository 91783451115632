import { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './Field.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const cx = classNames.bind(styles);

type FieldProps = {
  placeholder?: string;
  title?: string | number | null;
  width?: boolean;
  style?: any;
  styleTitle?: any;
  price?: string | number;
  error?: boolean;
};

export const Field: FC<FieldProps> = ({
  placeholder,
  title,
  width,
  style = { width: '314px', height: '40px' },
  price,
  styleTitle = { height: '40px' },
  error = false,
}) => {
  // const inputRef = useRef<any>(null);
  const placeholderRef = useRef<any>(null);

  const classNamefield = cx({
    field: true,
    focus: true,
    field_width: width,
  });

  const classNamePlaceholder = cx({
    placeholder: true,
    // transition: !value,
  });
  return (
    <div
      style={
        error
          ? { ...style, border: '1px solid #e10d0d', borderRadius: '4px' }
          : { ...style, border: '1px solid #EAF0FD' }
      }
      className={styles.root}>
      <div
        style={error ? { ...styleTitle, color: '#e10d0d' } : styleTitle}
        className={classNamefield}>
        {title}
      </div>
      {placeholder ? (
        <span className={classNamePlaceholder} ref={placeholderRef}>
          {placeholder}
        </span>
      ) : null}
      {price && (
        <span style={error ? { color: '#e10d0d' } : {}} className={styles.price}>
          {price}
        </span>
      )}
    </div>
  );
};
