import { FC, useRef } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { TitleRow } from 'components/TitleRow';
import { Link } from 'react-router-dom';
import styles from './EmployedsList.module.scss';
import { Nothing } from 'components/Nothing';
import { Employed } from 'redux/employedsWorkSupportApi';
import { EmployedWorkSupportItem } from 'ui/EmployedWorkSupportItem';

import { FixedSizeList } from 'react-window';

interface EmployedsListTypes {
  data: Employed[];
}

export const EmployedsWorkSupportList: FC<EmployedsListTypes> = ({ data }) => {
  const [parent] = useAutoAnimate({ duration: 300 });
  const refList = useRef<any>(null);
  const pageHeight = document.documentElement.scrollHeight;

  const role = localStorage.getItem('role') || 'WorkSupport';

  const dataTitle = [
    'id',
    'ФИО',
    'ИНН',
    'Налоговый статус',
    'Гражданство',
    'Дата обновления',
    'Статус',
  ];

  const Row = ({ index, style = {} }: any) => {
    return (
      <div style={style}>
        {data && (
          <Link to={`/employedsWorkSupport/${data[index]?.id}`}>
            <EmployedWorkSupportItem role={role} employed={data[index]} />
          </Link>
        )}
      </div>
    );
  };

  return (
    <div ref={parent} className={styles.root}>
      <TitleRow data={dataTitle} title={'employeds'} />
      {data?.length ? (
        <FixedSizeList
          className={styles.window}
          ref={refList}
          height={data?.length < 10 ? 83 * data?.length : pageHeight - 300}
          width='100%'
          itemCount={data?.length ? data?.length : 0}
          itemSize={81}>
          {Row}
        </FixedSizeList>
      ) : (
        <Nothing style={{ height: '640px' }} title='Ничего не найдено' />
      )}
    </div>
  );
};
