import { FC } from 'react';
import styles from './MainTitle.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

interface MainTitleProps {
  count?: boolean | number;
  title?: string;
}

export const MainTitle: FC<MainTitleProps> = ({ count = 0, title }) => {
  return (
    <div className={styles.root}>
      {title && (
        <p className={styles.title}>
          {title} <span className={styles.title_count}>{count}</span>
        </p>
      )}
    </div>
  );
};
