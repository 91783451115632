import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { MainInfoState } from './types';

const initialState: MainInfoState = {
  workerId: '',
  id: 0,
  nameWorker: '',
  surnameWorker: '',
  secondNameWorker: '',
  genderWorker: 1,
  avatarWorker: '',
  dateBirth: '',
  commentWorker: '',
  telWorker: '',
  tel2Worker: '',
  datePassport: '',
  pasportSeria: '',
  passportNum: '',
  passportCode: '',
  passportIssued: '',

  //
  address_passport: '',

  addressRegA: '',
  addressRegCity: '',
  addressRegStreet: '',
  addresRegHome: '',
  addresRegK: '',
  //
  documentTypes: [],
  documentWorker: 21,
  nationalitiesList: [],
  nationalityWorker: '',
  ordersWorker: [],
  appWorker: {},
  workTypes: [],
  statusWorker: 1,
  statusblackList: 0,
  orderReport: {},
  documents: [],
  calendarDatesMenedger: [],
  workerAppStatus: {},
  dataCheckStatus: '',
  invite: [],
  blackReason: '',
  secondBlackReason: '',
  isContinue: {
    isFound: false,
    isBlack: false,
  },
  isDecline: false,
  editDocumentPasportPhotoForSend: [],
  docFile: '',
  listPaymentTypes: [],
  appStatus: 1,
  additionalCards: [],
  errorStatusField: {
    fioI: 0,
    fioF: 0,
    fioO: 0,
    phone: 0,
    passport_s: 0,
    passport_n: 0,
    passport_kod: 0,
    passport_kem: 0,
    passport_date: 0,
    dob: 0,
    'payment_type.card_number': 0,
    'payment_type.name': 0,
    'payment_type.surname': 0,
    'payment_type.patronymic': 0,
    'payment_type.bank': 0,
  },
  openPassport: '',
  openPeyment: '',
  wasChanges: false,
  errorUpdate: false,
  acceptOrder: false,
  curTelWorker: '',
  tempAppStatus: '',

  listTabs: [],
  isWorkerUrl: '',
  isModerated: false,

  coordinates: {
    lat: '',
    lng: '',
  },
};

export const mainInfoSlice = createSlice({
  name: 'mainInfo',
  initialState,
  reducers: {
    setCoordinates: (state, action) => {
      state.coordinates = action.payload;
    },
    setIsModerated: (state, action: PayloadAction<boolean>) => {
      state.isModerated = action.payload;
    },
    setIsWorkerUrl: (state, action: PayloadAction<number | string>) => {
      state.isWorkerUrl = action.payload;
    },
    setlistTabs: (state, action: PayloadAction<any[]>) => {
      state.listTabs = action.payload;
    },
    setAddressPassport: (state, action: PayloadAction<any>) => {
      state.address_passport = action.payload;
    },
    setTempAppStatus: (state, action: PayloadAction<any>) => {
      state.tempAppStatus = action.payload;
    },
    setCurTelWorker: (state, action: PayloadAction<any>) => {
      state.curTelWorker = action.payload;
    },
    setAcceptOrder: (state, action: PayloadAction<boolean>) => {
      state.acceptOrder = action.payload;
    },
    setErrorUpdate: (state, action: PayloadAction<boolean>) => {
      state.errorUpdate = action.payload;
    },
    setWasChanges: (state, action: PayloadAction<boolean>) => {
      state.wasChanges = action.payload;
    },
    setOpenPassport: (state, action: PayloadAction<any>) => {
      state.openPassport = action.payload;
    },
    setOpenPeyment: (state, action: PayloadAction<any>) => {
      state.openPeyment = action.payload;
    },

    setErrorStatusField: (state, action: PayloadAction<any>) => {
      if (action?.payload !== undefined) state.errorStatusField[action?.payload] = 1;
    },

    setNotErrorStatusField: (state, action: PayloadAction<any>) => {
      state.errorStatusField[action.payload] = 0;
    },
    setResetErrorStatusField: (state) => {
      state.errorStatusField = {
        fioI: 0,
        fioF: 0,
        fioO: 0,
        phone: 0,
        passport_s: 0,
        passport_n: 0,
        passport_kod: 0,
        passport_kem: 0,
        passport_date: 0,
        dob: 0,
        'payment_type.card_number': 0,
        'payment_type.name': 0,
        'payment_type.surname': 0,
        'payment_type.patronymic': 0,
        'payment_type.bank': 0,
      };
    },
    setAdditionalCards: (state, action: PayloadAction<string[]>) => {
      state.additionalCards = action.payload;
    },
    setAppStatus: (state, action: PayloadAction<number>) => {
      state.appStatus = action.payload;
    },
    setListPaymentTypes: (state, action: PayloadAction<any>) => {
      state.listPaymentTypes = action.payload;
    },
    setDocFile: (state, action: PayloadAction<any>) => {
      state.docFile = action.payload;
    },
    setIsDecline: (state, action: PayloadAction<any>) => {
      state.isDecline = action.payload;
    },
    setIsContinue: (state, action: PayloadAction<any>) => {
      state.isContinue[action.payload.title] = action.payload.value;
    },
    setBlackReason: (state, action: PayloadAction<any>) => {
      state.blackReason = action.payload;
    },
    setSecondBlackReason: (state, action: PayloadAction<any>) => {
      state.secondBlackReason = action.payload;
    },
    setInvite: (state, action: PayloadAction<any>) => {
      state.invite = action.payload;
    },
    setDataCheckStatus: (state, action: PayloadAction<any>) => {
      state.dataCheckStatus = action.payload;
    },
    setWorkerAppStatus: (state, action: PayloadAction<any>) => {
      state.workerAppStatus = action.payload;
    },
    setVerifiedStatus: (state, action: PayloadAction<any>) => {
      state.workerAppStatus = { ...state.workerAppStatus, verified: action.payload };
    },
    setWorkerId: (state, action: PayloadAction<any>) => {
      state.workerId = action.payload;
    },
    setId: (state, action: PayloadAction<any>) => {
      state.id = action.payload;
    },
    setName: (state, action: PayloadAction<any>) => {
      state.nameWorker = action.payload;
    },
    setSurname: (state, action: PayloadAction<any>) => {
      state.surnameWorker = action.payload;
    },
    setSecondName: (state, action: PayloadAction<any>) => {
      state.secondNameWorker = action.payload;
    },
    setGender: (state, action: PayloadAction<any>) => {
      state.genderWorker = action.payload;
    },
    setAvatar: (state, action: PayloadAction<any>) => {
      state.avatarWorker = action.payload;
    },
    setDateBirth: (state, action: PayloadAction<any>) => {
      state.dateBirth = action.payload;
    },
    setComment: (state, action: PayloadAction<any>) => {
      state.commentWorker = action.payload;
    },

    setTel: (state, action: PayloadAction<any>) => {
      state.telWorker = action.payload;
    },

    setTel2: (state, action: PayloadAction<any>) => {
      state.tel2Worker = action.payload;
    },

    setDatePassport: (state, action: PayloadAction<any>) => {
      state.datePassport = action.payload;
    },

    setPasportSeria: (state, action: PayloadAction<any>) => {
      state.pasportSeria = action.payload;
    },

    setPassportNum: (state, action: PayloadAction<any>) => {
      state.passportNum = action.payload;
    },

    setPassportCode: (state, action: PayloadAction<any>) => {
      state.passportCode = action.payload;
    },

    setPassportIssued: (state, action: PayloadAction<any>) => {
      state.passportIssued = action.payload;
    },

    setAddressRegA: (state, action: PayloadAction<any>) => {
      state.addressRegA = action.payload;
    },

    setAddressRegCity: (state, action: PayloadAction<any>) => {
      state.addressRegCity = action.payload;
    },

    setAddressRegStreet: (state, action: PayloadAction<any>) => {
      state.addressRegStreet = action.payload;
    },

    setAddresRegHome: (state, action: PayloadAction<any>) => {
      state.addresRegHome = action.payload;
    },

    setAddresRegK: (state, action: PayloadAction<any>) => {
      state.addresRegK = action.payload;
    },

    setDocumentTypes: (state, action: PayloadAction<any>) => {
      state.documentTypes = action.payload;
    },

    setDocumentWorker: (state, action: PayloadAction<any>) => {
      state.documentWorker = action.payload;
    },

    setNationalitiesList: (state, action: PayloadAction<any>) => {
      state.nationalitiesList = action.payload;
    },

    setNationalityWorker: (state, action: PayloadAction<any>) => {
      state.nationalityWorker = action.payload;
    },

    setOrdersWorker: (state, action: PayloadAction<any>) => {
      state.ordersWorker = action.payload;
    },

    setAppWorker: (state, action: PayloadAction<any>) => {
      state.appWorker = action.payload;
    },

    setWorkTypes: (state, action: PayloadAction<any>) => {
      state.workTypes = action.payload;
    },

    setStatusWorker: (state, action: PayloadAction<any>) => {
      state.statusWorker = action.payload;
    },

    setStatusblackList: (state, action: PayloadAction<any>) => {
      state.statusblackList = action.payload;
    },

    setOrderReport: (state, action: PayloadAction<any>) => {
      state.orderReport = action.payload;
    },

    //сохраняем в виде бинарного кода в стейт, пока не подключено АПИ
    setDocuments: (state, action: PayloadAction<any>) => {
      state.documents = [...state.documents, action.payload];
    },

    deleteDocument: (state, action: PayloadAction<any>) => {
      state.documents = state.documents.filter((el: any) => el.name !== action.payload);
    },

    setCalendarDatesMenedger: (state, action: PayloadAction<any>) => {
      state.calendarDatesMenedger = action.payload;
    },

    setEditDocumentPasportPhotoForSend: (state, action: PayloadAction<any>) => {
      if (!state.editDocumentPasportPhotoForSend.length) {
        state.editDocumentPasportPhotoForSend = [
          ...state.editDocumentPasportPhotoForSend,
          {
            index: action.payload.index,
            photo: action.payload.photo,
          },
        ];
      } else if (
        state.editDocumentPasportPhotoForSend.find(
          (e: any) => e.index === action.payload.index,
        )
      ) {
        state.editDocumentPasportPhotoForSend =
          state.editDocumentPasportPhotoForSend?.map((item: any) => {
            if (item?.index === action.payload.index) {
              // Обновляем нужный элемент
              return { ...item, photo: action.payload.photo };
            }
            return item;
          });
      } else {
        state.editDocumentPasportPhotoForSend = [
          ...state.editDocumentPasportPhotoForSend,
          {
            index: action.payload.index,
            photo: action.payload.photo,
          },
        ];
      }
    },
  },
});

export const {
  setCoordinates,
  setIsModerated,
  setIsWorkerUrl,
  setlistTabs,
  setAddressPassport,
  setTempAppStatus,
  setCurTelWorker,
  setAcceptOrder,
  setErrorUpdate,
  setWasChanges,
  setOpenPassport,
  setOpenPeyment,
  setNotErrorStatusField,
  setResetErrorStatusField,
  setErrorStatusField,
  setAdditionalCards,
  setAppStatus,
  setListPaymentTypes,
  setDocFile,
  setEditDocumentPasportPhotoForSend,
  setSecondBlackReason,
  setBlackReason,
  setInvite,
  setWorkerAppStatus,
  setId,
  setName,
  setSurname,
  setSecondName,
  setGender,
  setDateBirth,
  setDatePassport,
  setDocuments,
  setAvatar,
  setComment,
  setTel,
  setTel2,
  setPasportSeria,
  setPassportNum,
  setPassportCode,
  setPassportIssued,
  setAddressRegA,
  setAddressRegCity,
  setAddressRegStreet,
  setAddresRegHome,
  setAddresRegK,
  setDocumentTypes,
  setNationalitiesList,
  setNationalityWorker,
  setOrdersWorker,
  setDocumentWorker,
  setAppWorker,
  setWorkTypes,
  setStatusWorker,
  setStatusblackList,
  setOrderReport,
  setCalendarDatesMenedger,
  setWorkerId,
  setDataCheckStatus,
  setIsContinue,
  setIsDecline,
  setVerifiedStatus,
} = mainInfoSlice.actions;

export default mainInfoSlice.reducer;
