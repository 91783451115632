import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
interface DocumentTypes {
  document_date: string | null;
  document_number: string | null;
  file: string;
  is_success_added: boolean;
}
interface TabItemTypes {
  idx: number;
  key: string;
  name: string;
}
interface TabsListTypes {
  available_tabs: TabItemTypes[];
}

interface VirtualAcc {
  cash: number;
  tochka_uuid: string;
  type: string;
}

interface CustomerTypes {
  virtual_accounts: VirtualAcc[];
  is_active: boolean;
  updated_at: string | null;
}

export interface ItemTypes {
  beneficiary: CustomerTypes;
  id: number | null;
  inn: string;
  jur_name: string;
  name: string;
  kpp: string;
}

interface BeneficiariesTypes {
  count: number;
  items: ItemTypes[];
}

interface DirectorTypes {
  fio: string;
}

interface BeneficiaryDetailsTypes
  extends Pick<CustomerTypes, 'is_active' | 'updated_at'> {
  document: DocumentTypes;
}
export interface BeneficiarTypes
  extends Omit<ItemTypes, 'name' | 'beneficiary'>,
    Record<string, any> {
  lists: TabsListTypes;
  beneficiary: BeneficiaryDetailsTypes;
  director: DirectorTypes;
}

const getToken = () => localStorage.getItem('token');
const getRole = () => localStorage.getItem('role');

const getAuthorizationHeader = () => {
  const token = getToken();
  return token ? `Bearer ${token}` : '';
};

// https://{domain}/api/tochka/beneficiaries
export const beneficiariesApi = createApi({
  reducerPath: 'beneficiariesApi',
  tagTypes: ['Beneficiaries', 'Beneficiar', 'Tags'],
  refetchOnReconnect: true,
  keepUnusedDataFor: 10,
  // refetchOnFocus: true,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_SERVER_TEST,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: getAuthorizationHeader(),
    },
  }),
  endpoints: (build) => ({
    getBeneficiaries: build.query<BeneficiariesTypes, void>({
      query: () => ({
        url: `/customers`,
        method: 'GET',
      }),
      providesTags: () => ['Beneficiaries'],
      // transformResponse: (response: any) => response?.data,
      // providesTags: (result, error, arg) =>
      //   result
      //     ? [...result?.data?.items.map(({ id }: any) => ({ type: 'Worker', id })), 'Worker']
      //     : ['Worker']
      // providesTags: (result, error, arg): any => console.log(result, error, arg)
    }),
    getBeneficiar: build.query<BeneficiarTypes | any, { beneficiary_id: number | any }>({
      query: ({ beneficiary_id }) => ({
        url: `/customers/${beneficiary_id}`,
        method: 'GET',
        // params
      }),
      providesTags: () => ['Beneficiar'],
    }),
    //https://{domain}/api/tochka/beneficiaries/{beneficiary_id}/tabs/virtual_accounts
    getTab: build.query<any, any>({
      query: ({ id, title }) => ({
        url: `/customers/${id}/tabs/${title}`,
        method: 'GET',
      }),
      providesTags: () => ['Tags'],
      // transformResponse: (response: any) => response?.data,
      // invalidatesTags: []
    }),
    createBeneficiar: build.mutation({
      query: ({ body }) => ({
        url: `/customers/initialize`,
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['Beneficiaries'],
    }),
  }),
});

export const {
  useGetTabQuery,
  useGetBeneficiarQuery,
  useGetBeneficiariesQuery,
  useCreateBeneficiarMutation,
} = beneficiariesApi;
