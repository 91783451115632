import React, { FC } from 'react';
import { getFotmattedDateCreated, getTitleDate, getTitleDateForEnd } from 'utils/date';
import { addSpaceNumber } from 'utils/addSpaceNumber';
import { BeneficiariesTabBtn } from 'ui/BeneficiariesTabBtn';
import { InfoEmployedItem } from 'components/InfoEmployedItem';
import iconDone from './img/IconDone.svg';

import IconActDone from './img/IconActDone.svg';
import IconBlueDone from './img/IconBlueDone.svg';
import IconBuilder from './img/IconBuilder.svg';
import IconCompleted from './img/IconCompleted.svg';
import IconDraft from './img/IconDraft.svg';
import IconModerationError from './img/IconModerationError.svg';
import IconModerationProgress from './img/IconModerationProgress.svg';
import IconNotBuilder from './img/IconNotBuilder.svg';
import IconPaymentDone from './img/IconPaymentDone.svg';
import IconPaymentError from './img/IconPaymentError.svg';
import IconTime from './img/IconTime.svg';

import styles from './CustomerOrderItem.module.scss';

interface CustomerOrderItemTypes {
  order: any;
}

const objIcons: any = {
  order_draft: IconDraft,
  order_success: IconCompleted,
  moderation_in_progress: IconModerationProgress,
  moderation_failed: IconModerationError,
  moderation_success: IconCompleted,
  worker_approved_by_customer: IconNotBuilder,
  worker_in_progress: IconNotBuilder,
  worker_failed: IconModerationError,
  worker_success: IconBuilder,
  job_in_progress: IconModerationProgress,
  job_success: IconBlueDone,
  work_in_progress: IconModerationProgress,
  work_success: IconBlueDone,
  act_in_progress: IconModerationProgress,
  act_signed_by_customer: IconActDone,
  act_signed_by_worker: IconActDone,
  payment_in_progress: IconModerationProgress,
  payment_failed: IconPaymentError,
  payment_success: IconPaymentDone,
  finished_success: IconCompleted,
  order_canceled: IconModerationError,
};

export const CustomerOrderItem: FC<CustomerOrderItemTypes> = ({ order }) => {
  // console.log('order', order);
  return (
    <div className={styles.root}>
      <div
        className={styles.root_item}
        style={{ display: 'flex', flexDirection: 'column' }}>
        <p className={styles.root_item__standart}>{order?.created_at}</p>
        {/* <p className={styles.root_item__for_ndfl}>
          с {order.date_start.split(' ')[1].slice(0, 5)} до{' '}
          {order.date_end.split(' ')[1].slice(0, 5)}
        </p> */}
      </div>
      <div
        className={styles.root_item}
        style={{ display: 'flex', flexDirection: 'column' }}>
        <p className={styles.root_item__standart}>{order.order_type}</p>
        <p className={styles.root_item__for_ndfl}>{order.description}</p>
      </div>
      <div
        className={styles.root_item}
        style={{ display: 'flex', flexDirection: 'column', padding: '8px 0' }}>
        {order.worker ? (
          <InfoEmployedItem
            fns_status={order.worker?.fns_status}
            orders
            inn={order.worker?.inn}
            fio={`${order.worker?.surname} ${order.worker?.name}`}
            workerAppStatus={order.worker?.app_status}
          />
        ) : null}
      </div>
      <p style={{ textAlign: 'end' }} className={styles.root_item__standart}>
        {addSpaceNumber(order.amount)}
      </p>
      <div className={styles.root_item} style={{ display: 'inline-flex' }}>
        <BeneficiariesTabBtn
          classNameStyles={styles.statusBlock}
          // icon
          // src={objIcons[order.status?.value]}
          title={order.status?.text}
          color={order.status?.color_text}
          bg={order.status?.color_bg}
          isActive={false}
        />
      </div>
    </div>
  );
};
