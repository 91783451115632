import React, { FC } from 'react';
import { getFotmattedDateCreated } from 'utils/date';
import { ReactComponent as Fns1 } from 'assets/img/taxStatus/ФНС.svg';
import { ReactComponent as Fns2 } from 'assets/img/taxStatus/fns2.svg';
import { ReactComponent as Fns3 } from 'assets/img/taxStatus/error.svg';
import styles from './EmployedItem.module.scss';
import { BeneficiariesTabBtn } from 'ui/BeneficiariesTabBtn';
import { InfoEmployeds } from 'components/InfoEmployeds';
import { Employed } from 'redux/employedsWorkSupportApi';

interface EmployedItemTypes {
  employed: Employed;
  role: string;
}

export const EmployedWorkSupportItem: FC<EmployedItemTypes> = ({ employed, role }) => {
  // console.log('employed', employed);
  return (
    <div className={styles.root}>
      <p>{employed.id}</p>
      <div style={{ padding: '0 16px' }}>
        <InfoEmployeds
          status={employed.app_status}
          avatar={employed.avatar}
          id={employed.id}
          name={employed.name}
          surname={employed.surname}
          phone={employed.phone}
        />
      </div>
      <p>{employed.worker_fns?.inn || '-'}</p>
      <p>
        {!employed.worker_fns?.fns_status ? (
          '-'
        ) : employed.worker_fns?.fns_status === 2 ? (
          <Fns1 />
        ) : employed.worker_fns?.fns_status === 1 ? (
          <Fns2 />
        ) : (
          <Fns3 />
        )}
      </p>
      <p>{employed.nationality || '-'}</p>
      <p>
        {employed.worker_fns?.last_info_update
          ? getFotmattedDateCreated(employed.worker_fns?.last_info_update)
          : '-'}
      </p>
      <div style={{ padding: '0 16px', display: 'inline-flex' }}>
        {/* <BeneficiariesTabBtn isActive={true} title={'Активный'} /> */}
      </div>
    </div>
  );
};
