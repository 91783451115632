import React from 'react';
import { getFormatPhone } from 'utils/getFotmatPhone';
import { ReactComponent as IconError } from 'assets/img/error.svg';
import { ReactComponent as IconDone } from 'assets/img/iconDone.svg';
import styles from './EmployeesItem.module.scss';

export const EmployeesItem = ({ employed }: any) => {
  return (
    <div className={styles.root}>
      <p className={styles.root_title}>{employed.fio}</p>
      <p className={styles.root_title}>{getFormatPhone(employed.phone)}</p>
      <p>{employed.is_sign_right ? <IconDone /> : <IconError />}</p>
    </div>
  );
};
