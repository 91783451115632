import { FC } from 'react';
import { Title } from 'components/Title';
import { ReactComponent as NoPhotoSvg } from 'assets/img/worker/noPhoto.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Field } from 'ui/Field';
import { InputMask } from 'components/InputMask';
import styles from './MainInfo.module.scss';

export const MainInfo: FC = () => {
  const { infoEmployeds } = useSelector((state: RootState) => state.employedsDetailSlice);
  const { phone, surname, name, patronymic, worker_app, sex, dob, avatar } =
    infoEmployeds || {};

  return (
    <div className={styles.root}>
      <Title title='Исполнитель' />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.inputs}>
            <Field title={surname} placeholder='Фамилия' />
            <Field title={name} placeholder='Имя' />
            <Field title={patronymic} placeholder='Отчество' />
            <div className={styles.phone}>
              <InputMask
                width
                value={phone}
                setValue={(value) => {
                  // check(value);
                }}
                placeholder='Телефон'
                type='tel'
              />
            </div>
            <Field width title={sex ? 'Мужской' : 'Женский'} placeholder='Пол' />
            <Field width title={dob ? dob : 'дд.мм.гггг'} placeholder='Дата рождения' />
          </div>
          {!avatar ? <NoPhotoSvg /> : <img src={avatar} className={styles.avatar}></img>}
        </div>
      </div>
    </div>
  );
};
