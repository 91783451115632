import React, { FC } from 'react';
import { TitleRowTabBeneficiaries } from 'components/TitleRowTabBeneficiaries';
import { EmployeesItem } from 'ui/EmployeesItem';
import { useGetTabQuery } from 'redux/ProfileApi';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import styles from './Employees.module.scss';
interface EmployeesTypes {
  id?: number | null;
  title?: string;
}

export const Employees: FC<EmployeesTypes> = ({ id, title }) => {
  const { data, isLoading } = useGetTabQuery({ title });
  // console.log('data', data);

  const dataTaxes = ['ФИО', 'Телефон', 'Право подписи'];

  if (isLoading) {
    return (
      <div className={styles.progressBlock}>
        <LoadingSkeleton tabDetails />
      </div>
    );
  }

  return (
    <div style={{ padding: '0 24px' }}>
      <div className={styles.root}>
        <TitleRowTabBeneficiaries data={dataTaxes} title='profileEmployees' />
        {data?.items?.map((employed: any, i: number) => (
          <EmployeesItem employed={employed} key={i} />
        ))}
        {/* {isLoading ? (
          <LoadingSkeleton tabDetails />
        ) : (
          data &&
          data.items.map((elem, i: number) => (
            <TaxesEmployedsItem taxe={elem} key={i} />
          ))
        )} */}
      </div>
    </div>
  );
};
