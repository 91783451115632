import React, { useMemo, useState } from 'react';

export const useSearchOrders = (orders: any[], searchValue: any) => {
  const filtredSearchOrders = useMemo(() => {
    const normalizedSearchValue = searchValue?.replaceAll(' ', '').toLowerCase();

    return orders?.filter(
      (order: any) =>
        order?.worker?.name?.toLowerCase().includes(normalizedSearchValue) ||
        order?.worker?.surname?.toLowerCase().includes(normalizedSearchValue) ||
        order?.worker?.inn?.toLowerCase().includes(normalizedSearchValue) ||
        order?.order_type?.toLowerCase().includes(normalizedSearchValue),
    );
  }, [searchValue, orders]);
  return filtredSearchOrders;
};
