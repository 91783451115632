import { FC } from 'react';
import { getFormatPhone } from 'utils/getFotmatPhone';
import { ReactComponent as CallBackSvg } from 'assets/img/callBackButton.svg';
import styles from './Phone.module.scss';
import { getCallbackMango } from 'api/getApi';

type PhoneProps = {
  phone: string;
  workerPhone?: boolean;
  miniPhone?: boolean;
  list?: boolean;
};

export const Phone: FC<PhoneProps> = ({ phone, workerPhone, miniPhone, list }) => {
  const handlerCall = (e: any) => {
    e.stopPropagation();
    const params = {
      phone,
    };
    getCallbackMango(params)
      .then(() => console.log(`Успешная инициация ${phone}`))
      .catch(() => alert('Ошибка иниации звонка'));
  };
  return (
    <div
      style={workerPhone ? {} : list ? { left: '-8px' } : {}}
      className={styles.root}
      onClick={(e) => {
        // e.stopPropagation();
        e.preventDefault();
      }}>
      <div
        style={
          workerPhone || miniPhone
            ? { width: '20px', height: '20px' }
            : { width: '32px', height: '32px' }
        }
        className={styles.button}
        onClick={handlerCall}>
        <CallBackSvg />
      </div>

      {phone && getFormatPhone(phone)}
    </div>
  );
};
