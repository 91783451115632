import { FC, useEffect } from 'react';
import { MainInfo } from 'components/BlocksContent/MainInfo';
import { MainDocument } from 'components/BlocksContent/MainDocument';
import * as slice from 'redux/mainInfo/slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { TabEmployeds } from 'components/TabEmployeds';
import { setInfoEmployeds } from 'redux/employedDetail/slice';
import { FNSStatus } from 'components/BlocksContent/FNSStatus';
import styles from './WorkerContent.module.scss';

interface WorkerContentTypes {
  workerId: number | string;
  data: any;
}

export const WorkerContent: FC<WorkerContentTypes> = ({ workerId, data }) => {
  const { infoEmployeds } = useSelector((state: RootState) => state.employedsDetailSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      for (const key in data) {
        dispatch(setInfoEmployeds({ key, value: data[key] }));
      }
      dispatch(slice.setlistTabs(data?.lists?.available_tabs));
      dispatch(slice.setWorkerId(workerId));
    }
  }, [workerId, data]);

  return (
    <div className={styles.root}>
      <div className={styles.back}>
        <p className={styles.back_title}>
          {infoEmployeds?.surname +
            ' ' +
            infoEmployeds?.name +
            ' ' +
            infoEmployeds?.patronymic}
        </p>
      </div>
      <div className={styles.content}>
        <div className={styles.left}>
          <MainInfo />
          {/* <Contacts /> */}
          <MainDocument />
          <FNSStatus />
        </div>

        <div className={styles.right}>
          <TabEmployeds />
        </div>
      </div>
    </div>
  );
};
