import React, { FC } from 'react';
import { TitleRowTabBeneficiaries } from 'components/TitleRowTabBeneficiaries';
import { useGetTabEmployedsQuery } from 'redux/employedsWorkSupportApi';
import { TaxesEmployedsItem } from 'ui/TaxesEmployedsItem';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { Nothing } from 'components/Nothing';
import styles from './Taxes.module.scss';

interface TaxesTypes {
  id: number | null;
  title: string;
}

export const Taxes: FC<TaxesTypes> = ({ id = 0, title }) => {
  const { data, isLoading, error } = useGetTabEmployedsQuery({ id: id, title });

  const dataTaxes = [
    'id',
    'Сумма',
    'Бенефициар',
    'Удержано',
    'Оплачено',
    'Статус платежа',
  ];

  return (
    <div className={styles.root}>
      <TitleRowTabBeneficiaries data={dataTaxes} title='taxesEmployeds' />
      {error || !data?.length ? (
        <Nothing style={{ height: '640px' }} title={error ? 'Ошибка, попробуйте позже' : 'Ничего не найдено'} />
      ) : isLoading ? (
        <LoadingSkeleton tabDetails />
      ) : (
        data?.map((elem: any, i: number) => <TaxesEmployedsItem taxe={elem} key={i} />)
      )}
    </div>
  );
};
