import React from 'react';
import type { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
// import { useAppSelector } from '../../redux/hook'

interface TypeAuth {
  children: ReactNode;
}

export const RequireAuth: FC<TypeAuth> = ({ children }) => {
  const location = useLocation();
  const isAuth = useSelector((state: any) => Boolean(state.authSlice.token));
  // const isAuth = false;
  // console.log(isAuth)
  if (isAuth) return <>{children}</>;
  return <Navigate to='/' state={{ from: location }} replace />;
};
