import React, { FC } from 'react';
import classNames from 'classnames/bind';
import styles from './TitleRow.module.scss';

const cx = classNames.bind(styles);

interface TitleRowTypes {
  data: string[];
  title: string;
}

export const TitleRow: FC<TitleRowTypes> = ({ data, title }) => {
  const classNameInput = cx({
    beneficiaries: title === 'beneficiaries',
    employeds: title === 'employeds',
    orders: title === 'orders',
    employedsCustomer: title === 'employedsCustomer',
    ordersCustomer: title === 'ordersCustomer',
    taxesCustomer: title === 'taxesCustomer',
    performersList: title === 'performersList',
  });

  return (
    <div className={classNameInput}>
      {data.map((elem: string, i: number) => (
        <p
          style={
            elem === 'Баланс' ||
            elem === 'В копилке' ||
            elem === 'Начислено' ||
            elem === 'Оплачено'
              ? { textAlign: 'end' }
              : {}
          }
          key={i}>
          {elem}
        </p>
      ))}
    </div>
  );
};
