import React, { FC } from 'react';
import { ReactComponent as DocSvg } from './img/icon_document.svg';
import styles from './DocumentInfo.module.scss';

interface DocumentInfoTypes {
  href?: string;
  number?: string | null;
  date?: string | null;
  onClick?: () => void;
  loading?: boolean;
}

export const DocumentInfo: FC<DocumentInfoTypes> = ({
  href = '',
  number = '',
  date = '',
  onClick,
  loading = false,
}) => {
  return (
    <>
      {loading ? (
        <div className={styles.doc} style={{ cursor: 'not-allowed', minHeight: '59px' }}>
          <DocSvg />
          <p>Загрузка...</p>
        </div>
      ) : href?.length ? (
        <a className={styles.doc} href={href} target='_blank' rel='noreferrer'>
          <DocSvg />
          <p>
            {number} {date ? ` от ${date}` : ''}
          </p>
        </a>
      ) : (
        <div className={styles.doc} onClick={onClick}>
          <DocSvg />
          <p>
            {number} {date ? ` от ${date}` : ''}
          </p>
        </div>
      )}
    </>
  );
};
// href={link} download target='_blank' rel='noreferrer'
