import React, { FC } from 'react';
import styles from './CustomerTaxeItem.module.scss';
import { BeneficiariesTabBtn } from 'ui/BeneficiariesTabBtn';
import { addSpaceNumber } from 'utils/addSpaceNumber';
import { TaxesItemsTypes } from 'redux/taxesApi';

interface CustomerTaxeItemTypes {
  taxe: TaxesItemsTypes;
}

export const CustomerTaxeItem: FC<CustomerTaxeItemTypes> = ({ taxe }) => {
  // console.log('taxe', taxe);
  return (
    <div className={styles.root}>
      <p className={styles.root_text}>
        <span>{taxe.period}</span>
      </p>
      <p className={styles.root_text}>{taxe.customer_jur_name}</p>
      <p className={styles.root_text} style={{ textAlign: 'end' }}>
        {addSpaceNumber(taxe.taxes_on_virtual_account_amount)}
      </p>
      <p className={styles.root_text} style={{ textAlign: 'end' }}>
        {addSpaceNumber(taxe.taxes_accrued_amount)}
      </p>
      <p className={styles.root_text} style={{ textAlign: 'end' }}>
        {addSpaceNumber(taxe.taxes_paid_amount)}
      </p>
      <div className={styles.root_item} style={{ display: 'inline-flex' }}>
        <BeneficiariesTabBtn
          bg={taxe.status?.color_bg}
          color={taxe.status?.color_text}
          title={taxe.status.text}
        />
      </div>
    </div>
  );
};
