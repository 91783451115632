import { FC, useEffect, useRef, useState } from 'react';
import { FixedSizeList } from 'react-window';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { RootState } from 'redux/store';
import { TitleRow } from 'components/TitleRow';
import { Link } from 'react-router-dom';
import { EmployedItem } from 'ui/EmployedItem';
import { Nothing } from 'components/Nothing';
import styles from './EmployedsList.module.scss';

interface workerAppTypes {
  app_status: number;
  avatar?: string | null;
}

export interface EmpoyedTypes {
  created_at: string;
  fns_status: number;
  id: number;
  inn: string;
  last_info_update: string;
  name: string;
  nationality: string;
  patronymic: string;
  phone: string;
  surname: string;
  worker_app: workerAppTypes;
}
interface EmployedsListTypes {
  data: EmpoyedTypes[];
}

export const EmployedsList: FC<EmployedsListTypes> = ({ data }) => {
  const refList = useRef<any>(null);
  const [parent] = useAutoAnimate({ duration: 300 });
  const pageHeight = document.documentElement.scrollHeight;

  const role = localStorage.getItem('role') || 'WorkSupport';

  const dataTitle = [
    'Исполнитель',
    'Телефон',
    'ИНН',
    // 'Приложение Скилла Работа',
    'Подключение в Мой налог',
    'Дата добавления',
    // 'Налоговый статус',
  ];

  const Row = ({ index, style = {} }: any) => {
    return (
      <div style={style}>
        <EmployedItem role={role} employed={data[index]} />
      </div>
    );
  };

  return (
    <div ref={parent} className={styles.root}>
      <TitleRow
        data={dataTitle}
        title={role === 'director' ? 'employedsCustomer' : 'employeds'}
      />
      {data?.length ? (
        <FixedSizeList
          className={styles.window}
          ref={refList}
          height={data?.length < 10 ? 83 * data?.length : pageHeight - 300}
          width='100%'
          itemCount={data?.length ? data?.length : 0}
          itemSize={75}>
          {Row}
        </FixedSizeList>
      ) : (
        <Nothing style={{ height: '640px' }} title='Ничего не найдено' />
      )}
    </div>
  );
};
