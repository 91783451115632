// eslint-disable-next-line
export const searchList = (arr: any, value: string, forTypes: boolean = false) => {
  return arr.filter((item: any) => {
    const initials = forTypes
      ? item.name.trim().toLowerCase()
      : item.trim().toLowerCase();

    if (initials.indexOf(value.toLowerCase()) > -1) {
      return true;
    }
  });
};
