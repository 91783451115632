import React, { FC, useState } from 'react';
import { ProgressBar } from 'ui/ProgressBar';
import { InfoProgressPrice } from 'ui/InfoProgressPrice';
import { SummaryHistoryItem } from 'ui/SummaryHistoryItem';
import { Title } from 'components/Title';
import { SelectedMounth } from 'components/SelectedMounth';
import { useGetTabQuery } from 'redux/ProfileApi';
import { currentYearNewFormat, getOnlyMounth, listMounth } from 'utils/date';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { Nothing } from 'components/Nothing';
import styles from './Summary.module.scss';
import { NewSelectedMounth } from 'components/NewSelectedMounth';
interface SummaryTypes {
  title: string;
}

interface AmountPaymentItemTypes {
  payment_type: string;
  amount: number;
}

export interface DataItemTypes {
  amount: number;
  description: string;
  id: number;
  incoming: boolean;
  name: string;
  order_link: string;
  paid_date: string;
  paid_time: string;
}

export const Summary: FC<SummaryTypes> = ({ title }) => {
  const [selectedMounth, setSelectedMounth] = useState(getOnlyMounth());
  // console.log('selectedMounth', getOnlyMounth());

  const { data, isLoading } = useGetTabQuery({
    title,
    params: {
      year: currentYearNewFormat,
      month: listMounth.find((elem) => elem.name === selectedMounth)?.key,
    },
  });

  if (isLoading) {
    return (
      <div className={styles.progressBlock}>
        <LoadingSkeleton tabDetails />
      </div>
    );
  }

  // console.log('data', data);

  // const arr = [{}];

  // console.log(data?.data);
  return (
    <div>
      <div className={styles.progressBlock}>
        <div>
          <NewSelectedMounth
            total_amount={data?.total_amount}
            listMounth={listMounth}
            selectedMounth={listMounth?.find((elem: any) => elem.name === selectedMounth)}
            setSelectedMounth={setSelectedMounth}
          />
        </div>
        <ProgressBar
          amounts={data?.amount_by_payment_types}
          total_amount={data?.total_amount}
        />
        <div className={styles.payment_types}>
          {data?.amount_by_payment_types?.map(
            (price: AmountPaymentItemTypes, i: number) => (
              <InfoProgressPrice
                key={i}
                color={price.payment_type === 'Оплата по заказам' ? 'orders' : 'taxes'}
                text={price.payment_type}
                price={price.amount}
              />
            ),
          )}
        </div>
      </div>
      <div className={styles.historyBlock}>
        <Title title='История' />
        {data?.data.length ? (
          data.data?.map((elem: DataItemTypes, i: number) => (
            <SummaryHistoryItem item={elem} key={elem.id} />
          ))
        ) : (
          <Nothing
            style={{ height: '500px' }}
            title='В этом месяце еще не было действий'
          />
        )}
      </div>
    </div>
  );
};
