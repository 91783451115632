import React, { FC } from 'react';
import { currentDay, getFotmattedDateCreated } from 'utils/date';
import { ReactComponent as IconSvg } from './img/icon.svg';
import { addSpaceNumber } from 'utils/addSpaceNumber';
import { BeneficiariesTabBtn } from 'ui/BeneficiariesTabBtn';
import { ReactComponent as IconDone } from './img/iconDone.svg';
import { ReactComponent as IconError } from 'assets/img/error.svg';
import { containsLetters } from 'utils/strActions';
import styles from './OrdersEmployedsItem.module.scss';

interface OrdersEmployedsItemTypes {
  order: any;
}

export const OrdersEmployedsItem: FC<OrdersEmployedsItemTypes> = ({ order }) => {
  // console.log('order', order);

  return (
    <div className={styles.root}>
      <p className={styles.root_text}>
        {order.date_start
          ? currentDay(order.date_start?.split(' ')[0])
          : currentDay(order?.status?.created_at?.split(' ')[0])}
      </p>
      <div>
        <p className={styles.root_text}>{order.order_type}</p>
        <p className={styles.root_subText}>
          {order.is_remote ? 'Удалённая работа' : order.order_address}
        </p>
      </div>
      <p style={{ textAlign: 'end' }}>{addSpaceNumber(order.amount)}</p>

      <div>
        <p className={styles.root_text}>{order?.withdraw_method?.method}</p>
        <p className={styles.root_subText}>{order?.withdraw_method?.number}</p>
      </div>
      <div>
        <p>
          {order.withdraw_status ? (
            <IconDone />
          ) : order.withdraw_status === false ? (
            <IconError />
          ) : (
            ''
          )}
        </p>
      </div>
      <div className={styles.iconTitle}>
        <div style={{ padding: '0 16px' }}>
          <BeneficiariesTabBtn
            classNameStyles={styles.statusBlock}
            bg={order.status?.color_bg}
            color={order.status?.color_text}
            title={order.status.text}
          />
        </div>
        <p className={styles.root_subText}>
          <IconSvg /> {getFotmattedDateCreated(order.status?.created_at)}. назад
        </p>
      </div>
    </div>
  );
};
