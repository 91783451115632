import React, { FC, useRef, useState } from 'react';
import { ReactComponent as Fns1 } from 'assets/img/taxStatus/ФНС.svg';
import { ReactComponent as Fns2 } from 'assets/img/taxStatus/fns2.svg';
import { ReactComponent as Fns3 } from 'assets/img/taxStatus/error.svg';
import { ReactComponent as BasketIcon } from 'assets/img/basket.svg';
import { ReactComponent as CrossIcon } from 'assets/img/iconCross.svg';
import styles from './InfoEmployedItem.module.scss';
import { getFormatPhone } from 'utils/getFotmatPhone';
import { AppStatus } from 'components/AppStatus';
import { AnyNaptrRecord } from 'dns';
import classNames from 'classnames';
import { Tooltip } from 'ui/Tooltip';

interface InfoEmployedItemTypes {
  fns_status?: number;
  fio: string;
  inn: string;
  phone?: string;
  phoneStatus?: null | number;
  chooseHandler?: () => void;
  workerAppStatus: number;
  orders?: boolean;
  style?: any;
  taxe?: boolean;
  deleteEmployedHandler?: () => void;
  isDelete?: boolean;
  workSupport?: boolean;
}

export const InfoEmployedItem: FC<InfoEmployedItemTypes> = ({
  fns_status = 0,
  fio,
  inn,
  phone,
  workerAppStatus,
  chooseHandler,
  orders,
  style,
  taxe,
  deleteEmployedHandler,
  isDelete,
  workSupport,
}) => {
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);

  // const iconRef = useRef<any>(null);

  const mouseOver = () => {
    setIsOpenTooltip(true);
  };

  const mouseOut = () => {
    setIsOpenTooltip(false);
  };

  return (
    <div
      style={style}
      onClick={chooseHandler}
      className={classNames(styles.root, !taxe && styles.root_hover)}>
      <div style={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
        <div
          // ref={iconRef}
          style={{ position: 'relative' }}
          onMouseOver={mouseOver}
          onMouseOut={mouseOut}>
          {fns_status === 2 ? <Fns1 /> : fns_status === 1 ? <Fns2 /> : <Fns3 />}

          {isOpenTooltip && (
            <Tooltip
              text={
                fns_status === 1
                  ? 'Привязан к платформе без права оплаты налога'
                  : 'Не привязан к платформе'
              }
              // text={statuts[workerAppStatus][1]}
              position={'bottom'}
              isFns
            />
          )}
        </div>
        <div className={styles.root_info}>
          <div className={styles.root_info_fio}>
            <p className={styles.root_name}>{fio}</p>
            {/* {orders && <AppStatus workerAppStatus={workerAppStatus} />} */}
          </div>
          {inn && <p className={styles.root_info_inn}>{`ИНН ${inn}`}</p>}
          {!orders && (
            <div className={styles.root_info_phone}>
              <p>Телефон {phone ? getFormatPhone(phone) : ''}</p>
              {/* <AppStatus workerAppStatus={workerAppStatus} /> */}
            </div>
          )}
        </div>
      </div>
      {isDelete && workSupport && (
        <div onClick={deleteEmployedHandler} className={styles.choose_icon}>
          <CrossIcon />
        </div>
      )}
    </div>
  );
};
