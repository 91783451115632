import { FC, useEffect, useRef, useState, memo } from 'react';
import { ReactComponent as CalendarSvg } from 'assets/img/filter/calendar.svg';
import { ReactComponent as LeftArrow } from 'assets/img/slider/leftArrow.svg';
import { ReactComponent as RightArrow } from 'assets/img/slider/rightArrow.svg';
import { DatePickerСhoose } from 'components/DateFilter/DatePickerСhoose';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import {
  getCurrentDay,
  getMonth,
  getThreeDay,
  getTitleDateNewFormat,
  getWeek,
  getYear,
} from 'utils/date';
import {
  setDateStart,
  setDateEnd,
  setDateStartPicker,
  setDateEndPicker,
  prevActiveHandlerDate,
  nextActiveHandlerDate,
} from 'redux/dateRange/slice';
import styles from './DateFilter.module.scss';
import classNames from 'classnames';

export const DateFilter: FC = memo(() => {
  const dispatch = useDispatch();
  const { dateStart, dateEnd, dateEndPicker, dateStartPicker, activeHandlerDate } =
    useSelector((state: RootState) => state.dateRange);

  const [nameSelectDate, setNameSelectDate] = useState('week');
  // const [activeHandlerDate, stActiveHandlerDate] = useState(1);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dateTitleRef = useRef<HTMLDivElement>(null);
  const dateRef = useRef<HTMLDivElement>(null);

  const resetDate = () => {
    if (dateStartPicker && !dateEndPicker) {
      dispatch(setDateStartPicker(dateStart ? new Date(dateStart) : null));
      dispatch(setDateEndPicker(dateEnd ? new Date(dateEnd) : null));
    }
  };

  const handlerOpen = () => {
    setIsOpen(!isOpen);
    resetDate();
  };

  const handlerThreeDays = () => {
    setNameSelectDate('day');
    dispatch(setDateStart(getThreeDay()));
    dispatch(setDateEnd(getCurrentDay()));
    // setIsOpen(false);
  };

  const handlerWeek = () => {
    setNameSelectDate('week');
    dispatch(setDateStart(getWeek()));
    dispatch(setDateEnd(getCurrentDay()));
  };

  const handlerMonth = () => {
    setNameSelectDate('month');
    dispatch(setDateStart(getMonth()));
    dispatch(setDateEnd(getCurrentDay()));
  };

  const handlerYear = () => {
    setNameSelectDate('year');
    dispatch(setDateStart(getYear()));
    dispatch(setDateEnd(getCurrentDay()));
  };

  const prevHandlerDate = () => {
    // stActiveHandlerDate((prev) => (prev === 0 ? 3 : prev - 1));
    dispatch(prevActiveHandlerDate());
  };

  const nextHandlerDate = () => {
    // stActiveHandlerDate((prev) => (prev === 3 ? 0 : prev + 1));
    dispatch(nextActiveHandlerDate());
  };

  const mapping: Record<number, any> = {
    0: handlerThreeDays,
    1: handlerWeek,
    2: handlerMonth,
    3: handlerYear,
  };

  useEffect(() => {
    resetDate();
  }, []);

  useEffect(() => {
    const handler = mapping[activeHandlerDate];
    // console.log('activeHandlerDate', activeHandlerDate);
    if (handler) {
      handler();
    }
  }, [activeHandlerDate]);

  const DateMenu = () => {
    return (
      <div className={styles.menu} onClick={(e) => e.stopPropagation()}>
        <ul className={styles.list}>
          <li
            className={classNames(
              styles.item,
              nameSelectDate === 'day' ? styles.item_active : '',
            )}
            onClick={handlerThreeDays}>
            3 дня
          </li>
          <li
            className={classNames(
              styles.item,
              nameSelectDate === 'week' ? styles.item_active : '',
            )}
            onClick={handlerWeek}>
            Неделя
          </li>
          <li
            className={classNames(
              styles.item,
              nameSelectDate === 'month' ? styles.item_active : '',
            )}
            onClick={handlerMonth}>
            Месяц
          </li>
          <li
            className={classNames(
              styles.item,
              nameSelectDate === 'year' ? styles.item_active : '',
            )}
            onClick={handlerYear}>
            Год
          </li>
          <li className={`${styles.item} ${styles.item_period}`}>
            <p>Период</p> {getTitleDateNewFormat(dateStart, dateEnd)}
          </li>
          {/* <li className={`${styles.item} ${styles.item_month}`} onClick={handlerBeforeLastMonth}>
            {getBeforeLastMonth('title')}
          </li> */}
        </ul>
        <div className={styles.date}>
          <DatePickerСhoose
            setOpenDateFilter={setIsOpen}
            setNameSelectDate={setNameSelectDate}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    const clickOutside = (event: MouseEvent) => {
      if (dateRef.current && !event.composedPath().includes(dateRef.current)) {
        setIsOpen(false);
      }
    };
    document.body.addEventListener('click', clickOutside);
    return () => document.body.removeEventListener('click', clickOutside);
  }, []);

  return (
    <div className={styles.root} ref={dateRef}>
      <div className={styles.root_blockSelect}>
        {/* <LeftArrow onClick={prevHandlerDate} /> */}
        <div onClick={handlerOpen}>
          <CalendarSvg />
          <span ref={dateTitleRef} className={styles.range}>
            {nameSelectDate === 'day'
              ? '3 дня'
              : getTitleDateNewFormat(dateStart, dateEnd)}
          </span>
        </div>
        {/* <RightArrow onClick={nextHandlerDate} /> */}
      </div>
      {isOpen && <DateMenu />}
    </div>
  );
});
