import React, { FC, useState } from 'react';
import { Modal } from 'components/Modal';
import { Title } from 'components/Title';
import { InputText } from 'ui/InputText';
import { Button } from 'ui/Button';
import { ReactComponent as AvatarSvg } from './img/avatar.svg';
import downloadSvg from './img/download.svg';
import styles from './ModalProfile.module.scss';
import { InfoBlock } from 'ui/InfoBlock';
import { InformationOrderBlock } from 'components/InformationOrderBlock';

interface ModalProfileTypes {
  avatar: string;
  sum: string;
  title: string;
  ls: string;
  setShowModalProfile: (e: boolean) => void;
  showModalProfile?: boolean;
}

export const ModalProfile: FC<ModalProfileTypes> = ({
  avatar,
  sum,
  setShowModalProfile,
  ls,
  title,
  showModalProfile,
}) => {
  const [value, setValue] = useState(sum);
  const [showRequisites, setShowRequisites] = useState(showModalProfile);

  const objRequisites = {
    Получатель: 'ООО "СКИЛЛА ИННОВАЦИИ"',
    ИНН: '4706050219',
    КПП: '470601001',
    'Банк получателя': 'ООО "Банк Точка"',
    БИК: '044525104',
    'Номер счёта': '40702810220000115800',
    'Корр. счет': '30101810745374525104',
    'Назначение платежа':
      'Пополнение номинального счета на площадке Скилла Работа без НДС',
  };

  return (
    <Modal className={styles.cross} setOpen={setShowModalProfile}>
      <div style={showRequisites ? { width: '616px' } : {}} className={styles.root}>
        {showRequisites ? (
          <>
            <Title className={styles.root_title} title='Реквизиты для пополнения счета' />
            <InfoBlock
              theme='warning'
              title={`Мы не зачисляем платежи от 3х лиц.
              При совпадении назначения платежа с образцом, срок зачисления средств - до 1 рабочего дня, иначе - до 7 дней`}
            />
            <InformationOrderBlock
              className={styles.root_info}
              objectInfo={objRequisites}
            />
          </>
        ) : (
          <>
            <Title className={styles.root_title} title='Пополнение лицевого счета' />
            <div className={styles.root_infoBlock}>
              {avatar ? (
                <img src='' alt='' />
              ) : (
                <AvatarSvg className={styles.root_infoBlock_avatar} />
              )}
              <div className={styles.root_infoBlock_textBlock}>
                <b>{title}</b>
                <p>ЛС №{ls}</p>
              </div>
            </div>
            <div className={styles.root_inputBlock}>
              <p>Сумма зачисления на счет</p>
              <InputText
                style={{ border: 'none' }}
                price='руб'
                value={value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setValue(e.target.value)
                }
              />
            </div>
            <Button
              width='24'
              height='24'
              firstIcon
              src={downloadSvg}
              className={styles.root_btn}>
              Скачать счет на оплату
            </Button>
            <p onClick={() => setShowRequisites(true)} className={styles.root_link}>
              Показать банковские реквизиты
            </p>
          </>
        )}
      </div>
    </Modal>
  );
};
