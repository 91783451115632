import React from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from 'features/Layouts/Layouts';
import { WorkerContent } from 'components/WorkerContent';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { useGetEmployedQuery } from 'redux/employedsWorkSupportApi';
import styles from './EmployedsDetails.module.scss';

export const EmployedsDetails = () => {
  const { id = 0 } = useParams();
  const { data, isLoading } = useGetEmployedQuery({ id });

  return (
    <Layout>
      {isLoading ? <LoadingSkeleton mt /> : <WorkerContent workerId={id} data={data} />}
    </Layout>
  );
};
