import { configureStore } from '@reduxjs/toolkit';
import { workerApi } from './workerApi';
import { employedsApi } from './employedsApi';
import { operationsApi } from './operationsApi';
import { beneficiariesApi } from './beneficiariesApi';
import beneficiarSlice from './beneficiarDetail/slice';
import operationInfoSlice from './operationInfo/slice';
import ordersDetailsSlice from './ordersDetails/slice';
import mainInfoSlice from './mainInfo/slice';
import dateRangeSlice from './dateRange/slice';
import employedsDetailSlice from './employedDetail/slice';
import createProfile from './createProfile/slice';
import authSlice from './auth/slice';
import { ordersApi } from './ordersApi';
import { profileApi } from './ProfileApi';
import { taxesApi } from './taxesApi';
import { employedsWorkSupportApi } from './employedsWorkSupportApi';
import { performersWorkSupport } from './performersWorkSupport';

export const store = configureStore({
  reducer: {
    mainInfo: mainInfoSlice,
    dateRange: dateRangeSlice,
    beneficiarSlice,
    operationInfoSlice,
    ordersDetailsSlice,
    employedsDetailSlice,
    createProfile,
    authSlice,
    [workerApi.reducerPath]: workerApi.reducer,
    [beneficiariesApi.reducerPath]: beneficiariesApi.reducer,
    [employedsApi.reducerPath]: employedsApi.reducer,
    [operationsApi.reducerPath]: operationsApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [taxesApi.reducerPath]: taxesApi.reducer,
    [employedsWorkSupportApi.reducerPath]: employedsWorkSupportApi.reducer,
    [performersWorkSupport.reducerPath]: performersWorkSupport.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(
      workerApi.middleware,
      beneficiariesApi.middleware,
      employedsApi.middleware,
      operationsApi.middleware,
      ordersApi.middleware,
      profileApi.middleware,
      taxesApi.middleware,
      employedsWorkSupportApi.middleware,
      performersWorkSupport.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
