import { FC, useEffect, useState } from 'react';
import { TabMenu } from 'components/TabMenu';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Payments } from './Payments';
import { Orders } from './Orders';
import { Taxes } from './Taxes';
import { HistoryTab } from './History';
import styles from './TabBeneficiaries.module.scss';
import { Documents } from './Documents';

export const TabEmployeds: FC = () => {
  // const windowWorkerId = window.location.pathname.split('/').slice(-1)[0];
  // const { workerId, isWorkerUrl } = useSelector((state: RootState) => state.mainInfo);
  const [active, setActive] = useState<string>('orders');
  const { listTabs, workerId } = useSelector((state: RootState) => state.mainInfo);

  // console.log('listTabs', listTabs);

  // console.log('infoBeneficiar', infoBeneficiar);

  // useEffect(() => {
  //   setActive(isWorkerUrl ? 'work' : 'docs');
  // }, [isWorkerUrl]);

  const content: Record<string, React.ReactNode> = {
    payments: <Payments id={workerId} title={active} />,
    orders: <Orders id={workerId} title={active} />,
    taxes: <Taxes id={workerId} title={active} />,
    history: <HistoryTab id={workerId} title={active} />,
    documents: <Documents id={workerId} title={active} />,
    // orders,
  };

  return (
    <div className={styles.root}>
      <TabMenu
        cls={{ padding: 0 }}
        lists={listTabs}
        active={active}
        setActive={setActive}
      />

      {listTabs && workerId && <div className={styles.content}>{content[active]}</div>}
    </div>
  );
};
