import React, { FC } from 'react';
import { TitleRowTabBeneficiaries } from 'components/TitleRowTabBeneficiaries';
import { PaymentsEmployedsItem } from 'ui/PaymentsEmployedsItem';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { useGetTabEmployedsQuery } from 'redux/employedsWorkSupportApi';
import { Nothing } from 'components/Nothing';
import styles from './Payments.module.scss';
interface PaymentsTypes {
  id: number | null;
  title: string;
}

export const Payments: FC<PaymentsTypes> = ({ id, title }) => {
  const { data, isLoading, error } = useGetTabEmployedsQuery({ id, title });

  // console.log('data', data);

  const dataPayments = ['id', 'Бенефициар', 'Тип платежа', 'Сумма', 'Статус'];

  return (
    <div className={styles.root}>
      <TitleRowTabBeneficiaries data={dataPayments} title='paymentsEmployeds' />
      {error || !data?.length ? (
        <Nothing
          style={{ height: '640px' }}
          title={error ? 'Ошибка, попробуйте позже' : 'Ничего не найдено'}
        />
      ) : isLoading ? (
        <LoadingSkeleton tabDetails />
      ) : (
        data?.map((elem: any, i: number) => (
          <PaymentsEmployedsItem payment={elem} key={i} />
        ))
      )}
    </div>
  );
};
