import { FC } from 'react';
import styles from './ButtonSave.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import classNames from 'classnames';
import { useSaveCardWorker } from 'hooks/useSaveCardWorker';

interface buttonType {
  button_draft: string;
  button_disabled: string;
}

const themes: buttonType | any = {
  button_draft: styles.button_draft,
  button_disabled: styles.button_disabled,
};
interface ButtonSaveTypes {
  draft?: boolean;
  onClick: (a: React.MouseEvent<HTMLElement> | any) => void;
  disabled?: boolean;
  // theme?: keyof typeof themes | any;
  theme?: keyof typeof themes | any;
  text?: string;
}

export const ButtonSave: FC<ButtonSaveTypes> = ({
  draft,
  onClick,
  disabled = true,
  theme,
  text,
}) => {
  const { isContinue, wasChanges, errorStatusField, isWorkerUrl } = useSelector(
    (state: RootState) => state.mainInfo,
  );
  // const workerId = window.location.pathname.split('/').slice(-1)[0];

  const { isLoading, handleSendInfo } = useSaveCardWorker();

  const disableBtn = Object.values(errorStatusField).includes(1);

  // console.log(wasChanges, disableBtn);

  return (
    <div className={styles.container}>
      <button
        disabled={!disabled}
        onClick={onClick}
        className={classNames(
          styles.button,
          theme ? themes[theme] : null,
          // !draft
          //   ? //  && (isContinue.isFound || !wasChanges || disableBtn)
          //     styles.button_dis
          //   : styles.button_draft,
        )}>
        {isLoading ? 'Сохраняем...' : text}
      </button>
    </div>
  );
};
