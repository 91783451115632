import React, { FC, useState } from 'react';
import { BeneficiariesTabBtn } from 'ui/BeneficiariesTabBtn';
import classNames from 'classnames';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { currentDay, getFotmattedDateCreated } from 'utils/date';
import { getOperationsDetailsTypes } from 'redux/operationsApi';
import { ReactComponent as IconSvg } from './img/icon.svg';
import { ReactComponent as ArrowSvg } from './img/Arrow.svg';
import styles from './DealDetailsItem.module.scss';

interface DealDetailsItemTypes {
  data: getOperationsDetailsTypes;
}

export const DealDetailsItem: FC<DealDetailsItemTypes> = ({ data }) => {
  const [parent] = useAutoAnimate();
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  // console.log('data', data);

  const showHandler = () => {
    if (showMoreInfo) {
      setShowMoreInfo(false);
    } else {
      setShowMoreInfo(true);
    }
  };

  return (
    <div className={styles.rootleft}>
      <div className={styles.left}>
        <div className={styles.left_title}>
          <div className={styles.titleBlock}>
            <p className={styles.title}>Сделка ID {data.id}</p>
            <p>
              {data.created_at &&
                currentDay(data.created_at?.split(' ')[0]) +
                  ' ' +
                  data.created_at?.split(' ')[1]}
              {` по заказу №${data.order.number}`}
            </p>
          </div>
          <div className={styles.titleBlock}>
            <BeneficiariesTabBtn
              style={{ padding: '2px 8px' }}
              oldStyle
              title={data.status.text}
              color={data.status.color_text}
              bg={data.status.color_bg}
            />
            {data.updated_at && (
              <p className={styles.root_subText}>
                <IconSvg /> {getFotmattedDateCreated(data.updated_at)}. назад
              </p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.table}>
        <div style={{ border: '1px solid #ECF2FC' }} id='tempTable'>
          <div className={styles.tempTable}>
            <p>Общая сумма сделки</p>
            <p>{data.total_amount}</p>
          </div>
          <div className={styles.tempTable}>
            <p>Внешний ключ сделки</p>
            <p>{data.ext_key}</p>
          </div>
          <div
            style={{ cursor: 'pointer', alignItems: 'center' }}
            onClick={showHandler}
            className={styles.tempTable}>
            <p>Плательщик</p>
            <ArrowSvg
              className={
                showMoreInfo
                  ? styles.tempTableItemRotate_active
                  : styles.tempTableItemRotate
              }
            />
          </div>
          <div ref={parent}>
            {showMoreInfo && (
              <div>
                <div className={styles.tempTableItem}>
                  <p>Виртуальный счёт</p>
                  <p>{data.payer?.virtual_account}</p>
                </div>
                <div className={styles.tempTableItem}>
                  <p>Сумма оплаты</p>
                  <p>{data.payer?.amount}</p>
                </div>
                <div className={styles.tempTableItem}>
                  <p>ID документа</p>
                  <p style={{ maxWidth: '400px' }}>
                    {data.payer?.documents?.length ? data.payer?.documents[0]?.id : null}
                  </p>
                </div>
                <div className={styles.tempTableItem}>
                  <p>Детали</p>
                  <p>
                    {data.payer?.documents?.length
                      ? data.payer?.documents[0]?.success_added_desc
                      : null}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
