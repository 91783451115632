import React, { FC } from 'react';
import styles from './Nothing.module.scss';

interface NothingTypes {
  title?: string;
  style?: any;
}

export const Nothing: FC<NothingTypes> = ({ title = 'Ничего не найдено', style }) => {
  return (
    <div style={style} className={styles.root_nothing}>
      <p>{title}</p>
    </div>
  );
};
