import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { AnyIfEmpty } from 'react-redux';

// const token = localStorage.getItem('token');
// const role = localStorage.getItem('role');
// https://{domain}/api/orders

// export const ordersApi = createApi({
//   reducerPath: 'ordersApi',
//   tagTypes: ['Orders', 'Order', 'Workers', 'TypesOfServices'],
//   // refetchOnMountOrArgChange
//   // refetchOnFocus: true,
//   baseQuery: fetchBaseQuery({
//     baseUrl: '',
//     headers: {
//       'Content-Type': 'application/json',
//       // Accept: 'application/json',
//     },
//   }),
//   endpoints: (build) => ({
//     getOrders: build.query<any, any>({
//       query: () => {
//         const token = localStorage.getItem('token');
//         const role = localStorage.getItem('role');
//         const url =
//           role !== 'director'
//             ? process.env.REACT_APP_API_SERVER_TEST
//             : process.env.REACT_APP_API_SERVER_TEST_EMPLOYEDS;
//         const Authorization = token ? `Bearer ${token}` : undefined;
//         return {
//           url: `${url}/orders`,
//           method: 'GET',
//           headers: {
//             Authorization,
//             Accept: 'application/json',
//           },
//         };
//       },
//       providesTags: () => ['Orders'],
//       // transformResponse: (response: any) => response?.data,
//       // providesTags: (result, error, arg) =>
//       //   result
//       //     ? [...result?.data?.items.map(({ id }: any) => ({ type: 'Worker', id })), 'Worker']
//       //     : ['Worker']
//       // providesTags: (result, error, arg): any => console.log(result, error, arg)
//     }),
//     // orders/27
//     getOrder: build.query<any, any>({
//       query: ({ id }) => {
//         const token = localStorage.getItem('token');
//         const role = localStorage.getItem('role');
//         const url =
//           role !== 'director'
//             ? process.env.REACT_APP_API_SERVER_TEST
//             : process.env.REACT_APP_API_SERVER_TEST_EMPLOYEDS;
//         const Authorization = token ? `Bearer ${token}` : undefined;
//         return {
//           url: `${url}/orders/${id}`,
//           method: 'GET',
//           headers: {
//             Authorization,
//             Accept: 'application/json',
//           },
//         };
//       },
//       providesTags: () => ['Order'],
//     }),
//     getWorkers: build.query<any, void>({
//       query: () => {
//         const token = localStorage.getItem('token');
//         const role = localStorage.getItem('role');
//         const url =
//           role !== 'director'
//             ? process.env.REACT_APP_API_SERVER_TEST
//             : process.env.REACT_APP_API_SERVER_TEST_EMPLOYEDS;
//         const Authorization = token ? `Bearer ${token}` : undefined;
//         return {
//           url: `${url}/workers?for_select=1`,
//           method: 'GET',
//           headers: {
//             Authorization,
//             Accept: 'application/json',
//           },
//         };
//       },
//       transformResponse: (response: any) => response?.items,
//       providesTags: () => ['Workers'],
//     }),
//     getTypesOfServices: build.query<any, void>({
//       query: () => {
//         const token = localStorage.getItem('token');
//         const role = localStorage.getItem('role');
//         const url =
//           role !== 'director'
//             ? process.env.REACT_APP_API_SERVER_TEST
//             : process.env.REACT_APP_API_SERVER_TEST_EMPLOYEDS;
//         const Authorization = token ? `Bearer ${token}` : undefined;
//         return {
//           url: `${url}/orders/types`,
//           method: 'GET',
//           headers: {
//             Authorization,
//             Accept: 'application/json',
//           },
//         };
//       },
//       transformResponse: (response: any) => response?.items,
//       providesTags: () => ['TypesOfServices'],
//     }),
//     createOrder: build.mutation<any, any>({
//       query: ({ body }) => {
//         const token = localStorage.getItem('token');
//         const role = localStorage.getItem('role');
//         const url =
//           role !== 'director'
//             ? process.env.REACT_APP_API_SERVER_TEST
//             : process.env.REACT_APP_API_SERVER_TEST_EMPLOYEDS;
//         const Authorization = token ? `Bearer ${token}` : undefined;
//         return {
//           url: `${url}/orders`,
//           method: 'POST',
//           body,
//           headers: {
//             Accept: 'application/json',
//             Authorization,
//           },
//         };
//       },
//       invalidatesTags: ['Orders'],
//     }),
//     updateOrder: build.mutation<any, any>({
//       query: ({ id, body }) => {
//         const token = localStorage.getItem('token');
//         const url = process.env.REACT_APP_API_SERVER_TEST_EMPLOYEDS;
//         const Authorization = token ? `Bearer ${token}` : undefined;
//         return {
//           url: `${url}/orders/${id}`,
//           method: 'PATCH',
//           body,
//           headers: {
//             Accept: 'application/json',
//             Authorization,
//           },
//         };
//       },
//       invalidatesTags: ['Order'],
//     }),
//     sendActForOrder: build.mutation({
//       query: ({ id }) => {
//         const token = localStorage.getItem('token');
//         const url = process.env.REACT_APP_API_SERVER_TEST_EMPLOYEDS;
//         const Authorization = token ? `Bearer ${token}` : undefined;
//         return {
//           url: `${url}/orders/${id}/accept`,
//           method: 'POST',
//           headers: {
//             Accept: 'application/json',
//             Authorization,
//           },
//         };
//       },
//       invalidatesTags: ['Order'],
//     }),
//   }),
// });

// export const {
//   useGetOrderQuery,
//   useGetOrdersQuery,
//   useGetWorkersQuery,
//   useCreateOrderMutation,
//   useGetTypesOfServicesQuery,
//   useSendActForOrderMutation,
//   useUpdateOrderMutation,
// } = ordersApi;

const getToken = () => localStorage.getItem('token');
const getRole = () => localStorage.getItem('role');

const getBaseUrl = () => {
  const role = getRole();
  return role !== 'director'
    ? process.env.REACT_APP_API_SERVER_TEST
    : process.env.REACT_APP_API_SERVER_TEST_EMPLOYEDS;
};

const getAuthorizationHeader = () => {
  const token = getToken();
  return token ? `Bearer ${token}` : '';
};

export const ordersApi = createApi({
  reducerPath: 'ordersApi',
  tagTypes: ['Orders', 'Order', 'Workers', 'TypesOfServices'],
  refetchOnReconnect: true,
  keepUnusedDataFor: 3,
  // refetchOnMountOrArgChange: true,
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: getBaseUrl(),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
    }),
    { maxRetries: 1 },
  ),
  endpoints: (build) => ({
    getOrders: build.query<any, any>({
      query: () => ({
        url: `/orders`,
        method: 'GET',
      }),
      providesTags: () => ['Orders'],
    }),
    getOrder: build.query<any, any>({
      query: ({ id }) => ({
        url: `/orders/${id}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 3,
      providesTags: ['Order'],
    }),
    getWorkers: build.query<any, void>({
      query: () => ({
        url: `/workers?for_select=1`,
        method: 'GET',
      }),
      providesTags: () => ['Workers'],
      transformResponse: (response: any) => response.items,
    }),
    getTypesOfServices: build.query<any, void>({
      query: () => ({
        url: `/orders/types`,
        method: 'GET',
      }),
      providesTags: () => ['TypesOfServices'],
      transformResponse: (response: any) => response.items,
    }),
    createOrder: build.mutation<any, any>({
      query: ({ body }) => ({
        url: `/orders`,
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['Orders'],
    }),
    updateOrder: build.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/orders/${id}`,
        method: 'PATCH',
        body,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      }),
      // invalidatesTags: (result, error, arg) => console.log('arg', arg),
      invalidatesTags: (result, error, arg) => [{ type: 'Orders', id: arg.id }, 'Order'],
    }),
    // УБРАТЬ
    sendActForOrder: build.mutation({
      query: ({ id }) => ({
        url: `/orders/${id}/accept`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['Order'],
    }),
    //УБРАТЬ
    actionForOrders: build.mutation<any, any>({
      query: ({ url }) => ({
        url: `/orders/${url}`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      }),
      // invalidatesTags: ['Order'],
      invalidatesTags: (result, error, arg) => [{ type: 'Orders', id: arg.id }, 'Order'],
    }),
  }),
});

export const {
  useGetOrderQuery,
  useGetOrdersQuery,
  useGetWorkersQuery,
  useCreateOrderMutation,
  useGetTypesOfServicesQuery,
  useLazyGetTypesOfServicesQuery,
  useSendActForOrderMutation,
  useUpdateOrderMutation,
  useActionForOrdersMutation,
} = ordersApi;
