import { FC, useEffect, useRef, useState } from 'react';
import { ReactComponent as CalendarSvg } from 'assets/img/filter/iconCalendarBig.svg';
import { DatePickerChoose } from 'components/DatePicker';

import {
  currentNewDay,
  currentYearNewFormat,
  formattedDateMozilla,
  getDateFormattedCreateProfile,
  getDateFormattedDot,
  isAfterDate,
} from 'utils/date';
import classNames from 'classnames/bind';
import styles from './InputDate.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  setErrorStatusField,
  setNotErrorStatusField,
  setResetErrorStatusField,
  setWasChanges,
} from 'redux/mainInfo/slice';
import { RootState } from 'redux/store';

const cx = classNames.bind(styles);

type InputDateProps = {
  placeholder: string;
  date: any;
  setDate: any;
  error?: boolean;
  nameError?: string;
  withoutHour?: boolean;
  draft?: boolean;
  style?: any;
};

export const InputDate: FC<InputDateProps> = ({
  placeholder,
  date,
  setDate,
  error,
  nameError,
  withoutHour = true,
  draft,
  style,
}) => {
  const dateRef = useRef<any>(null);
  const { isModerated } = useSelector((state: RootState) => state.mainInfo);
  // const [value, setValue] = useState<string>(draft ? date : getDateFormattedDot(date));
  const [value, setValue] = useState<any>(date);
  const [openDate, setOpenDate] = useState<boolean>(false);
  const dispatch = useDispatch();
  const classNameInput = cx({
    input: true,
    input_value: value,
    inputError: error,
  });

  const classNamePlaceholder = cx({
    placeholder: true,
    inputPlaceholderError: error,
  });

  const classNameCalendar = cx({
    calendar: true,
    inputCalendarError: error,
    isModerated: isModerated,
  });

  const openCalendar = (e: any) => {
    dispatch(setDate(''));
    e.stopPropagation();
    setOpenDate(!openDate);
    if (error) dispatch(setNotErrorStatusField(nameError));
  };

  const changeDate = (e: any) => {
    dispatch(setErrorStatusField({ key: nameError, value: 0 }));
    dispatch(setWasChanges(true));
    // const regExp = /[^0-9-.]/g;
    const regExp = /[^0-9-.:\s]/g;
    const value = e.target.value.replace(regExp, '');
    // if (error) dispatch(setNotErrorStatusField(nameError));

    // Доработать!!!
    if (value.length < 10) {
      dispatch(setErrorStatusField({ key: nameError, value: 1 }));
    }
    if (value.length >= 10 && withoutHour) {
      if (
        // +value.slice(0, 2) > 31 ||
        // +value.slice(3, 5) > 12 ||
        +value.slice(6) > +currentYearNewFormat
        // +value.slice(6, 11) > +currentYearNewFormat;
      ) {
        dispatch(setErrorStatusField(nameError));
      } else {
        dispatch(setNotErrorStatusField(nameError));
      }
      // const date = `${value.slice(3, 5)}.${value.slice(0, 2)}.${value.slice(6)}`;
      const parts = e.target.value.split('.');
      dispatch(setDate(new Date(formattedDateMozilla(parts))));
      +value.slice(6, 11) > +currentYearNewFormat;
    } else {
      // dispatch(setDate(value));
    }
    //

    if (isNaN(date)) {
      setValue('');
    }

    if (value?.length === 2) {
      // const formattedvalue = value < currentDay ? currentDay : value;
      setValue(`${value}.`);
    } else if (value?.length === 5) {
      setValue(`${value}.`);
    } else if (value?.length === 10 && !withoutHour) {
      setValue(`${value} `);
    } else if (value?.length === 13 && !withoutHour) {
      setValue(`${value}:`);
    } else setValue(value);
  };

  const keyDown = (e: any) => {
    dispatch(setWasChanges(true));

    if (e.key === 'Backspace') setValue('');
  };

  useEffect(() => {
    const clickOutside = (event: MouseEvent) => {
      if (dateRef.current && !event.composedPath().includes(dateRef.current)) {
        setOpenDate(false);
      }
    };
    document.body.addEventListener('click', clickOutside);
    return () => document.body.removeEventListener('click', clickOutside);
  }, []);

  useEffect(() => {
    if (!isNaN(date) && typeof date === 'object') {
      // console.log('getDateFormattedDot', date);
      setValue(getDateFormattedDot(date));
      // setValue(getDateFormattedDot(isAfterDate(date)));
      // setValue(isAfterDate(date));
    } else {
      // console.log('setValue', date);
      setValue(date);
    }
  }, [date]);

  const onBlur = () => {
    if (!value && nameError === 'doc_date') {
      dispatch(setErrorStatusField({ key: nameError, value: 1 }));
    }
  };

  return (
    <div className={styles.root}>
      <span
        style={isModerated ? { background: 'transparent' } : {}}
        className={classNamePlaceholder}>
        {placeholder}
      </span>

      <input
        style={style}
        disabled={isModerated}
        className={classNameInput}
        type='text'
        onChange={changeDate}
        onKeyDown={keyDown}
        value={value}
        placeholder='дд.мм.гггг'
        maxLength={withoutHour ? 10 : 16}
        onBlur={onBlur}
      />
      <div className={classNameCalendar} onClick={isModerated ? undefined : openCalendar}>
        <CalendarSvg />
      </div>

      {openDate && (
        <div className={styles.datePicker} ref={dateRef}>
          <DatePickerChoose
            withoutHour={true}
            setOpen={setOpenDate}
            setDate={setDate}
            date={date}
            nameError={nameError}
          />
        </div>
      )}
    </div>
  );
};
