import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
// import { InfoBeneficiarTypes, documentTypes, listTypes, customerTypes } from './types';
import { BeneficiarTypes } from 'redux/beneficiariesApi';

interface StateType {
  infoBeneficiar: BeneficiarTypes;
  location: number;
}

const initialState: StateType = {
  infoBeneficiar: {
    id: null,
    jur_name: '',
    inn: '',
    kpp: '',
    lists: {
      available_tabs: [],
    },
    director: {
      fio: '',
    },
    beneficiary: {
      document: {
        document_date: '',
        document_number: '',
        file: '',
        is_success_added: false,
      },
      is_active: false,
      updated_at: '',
    },
    commission: {
      min_amount: '',
      percent: '',
    },
  },
  location: 0,
};

export const beneficiarSlice = createSlice({
  name: 'beneficiarSlice',
  initialState,
  reducers: {
    setInfoBeneficiar: (state, action) => {
      if (!action.payload.key) return;
      if (action.payload.step) {
        const { key, secondKey, value } = action.payload;

        if (action.payload.secondKey && typeof state.infoBeneficiar[key] === 'object') {
          state.infoBeneficiar[key][secondKey] = value;
        }
      } else {
        const { key, value } = action.payload;
        state.infoBeneficiar[key] = value;
      }
    },
    setLocation: (state, action: PayloadAction<number>) => {
      state.location = action.payload;
    },
  },
});

export const { setInfoBeneficiar, setLocation } = beneficiarSlice.actions;
export default beneficiarSlice.reducer;
