import { FC, useEffect } from 'react';
import { Layout } from 'features/Layouts/Layouts';
import { MainTitle } from 'components/MainTitle';
// import { SubHeader } from 'components/SubHeader';
import { useGetOperationsQuery } from 'redux/operationsApi';
import { TabOperations } from 'components/TabOperations';
import styles from './OperationsContent.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export const OperationsContent: FC = () => {
  const { infoTab } = useSelector((state: RootState) => state.operationInfoSlice);

  return (
    <Layout>
      <div className={styles.root}>
        <MainTitle title={infoTab?.nameTab} count={infoTab?.countTab} />
        <TabOperations />
        {/* <SubHeader create /> */}
        {/* <EmployedsList data={data?.items} /> */}
      </div>
    </Layout>
  );
};
