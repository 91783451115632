import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
// import { MainInfoState } from './types';

const initialState: any = {
  doc_code: 21,
  nationality: 'Россия',
  series: '',
  number: '',
  dep_code: '',
  doc_place: '',
  doc_date: '',

  //
  birth_place: '',
  reg_address: '',
  jur_address: '',
  //
  errorStatusField: {
    series: 0,
    number: 0,
    dep_code: 0,
    doc_place: 0,
    doc_date: 0,
    birth_place: 0,
    reg_address: 0,
    jur_address: 0,
  },

  // coordinates: {
  //   lat: '',
  //   lng: '',
  // },
};

export const createProfile = createSlice({
  name: 'mainInfo',
  initialState,
  reducers: {
    setInfoProfileCreate: (state, action: PayloadAction<any>) => {
      const { key, value }: any = action.payload;
      state[key] = value;
    },
    setDatePassport: (state, action: PayloadAction<any>) => {
      state.doc_date = action.payload;
    },
    setErrorStatusField: (state, action: PayloadAction<any>) => {
      const { key, value }: any = action.payload || {};
      state.errorStatusField[key] = value;
    },
    setResetField: (state) => {
      state.series = '';
      state.number = '';
      state.dep_code = '';
      state.doc_place = '';
      state.doc_date = '';
      state.birth_place = '';
      state.reg_address = '';
      state.jur_address = '';
    },
  },
});

export const {
  setInfoProfileCreate,
  setDatePassport,
  setResetField,
  setErrorStatusField,
} = createProfile.actions;

export default createProfile.reducer;
