import React, { FC } from 'react';
import styles from './OrderActionBtn.module.scss';
import { ButtonSave } from 'components/ButtonSave';

interface OrderActionBtnTypes {
  worker_id: number;
  data: any;
  description: string;
  amount: number;
  isCanceled: boolean;
  id: number;
  actionWithOrder: any;
  updateOrderHandler: any;
  actionOrders: any;
  minMaxPrice: any;
  balance: string | number;
  is_remote: string;
  street: string;
  city: string;
  disabledBtnPercent: boolean;
}

export const OrderActionBtn: FC<OrderActionBtnTypes> = ({
  worker_id,
  data,
  description,
  amount,
  isCanceled,
  id,
  actionWithOrder,
  updateOrderHandler,
  actionOrders,
  minMaxPrice,
  balance,
  is_remote,
  street,
  city,
  disabledBtnPercent,
}) => {
  const isModerationSuccess =
    data?.status?.value === 'moderation_success' ||
    data?.status?.value === 'worker_rejected_by_customer' ||
    data?.status?.value === 'order_published';
  const isDraft = data?.is_draft;
  const isWorkSuccess = data?.status?.value === 'act_signed_by_worker';
  const isWorkerTookOrder = data?.status?.value === 'worker_took_order';

  const handleSaveAndSend = () => actionWithOrder(`${id}/workers/${worker_id}/invite`);
  const handleSaveDraft = (isDraft: boolean) => updateOrderHandler(isDraft);
  const handleCancel = () => actionWithOrder(`${id}/cancel`);
  const handleSignAndSend = () => actionWithOrder(`${id}/accept`);
  const handleApproveWorker = () => actionWithOrder(`${id}/approve-worker`);

  // console.log(worker_id, isModerationSuccess);

  const disabled =
    is_remote === 'Удаленно'
      ? Boolean(description && amount)
      : Boolean(description && amount) && Boolean(city || street);

  const disabledAmount = minMaxPrice.min > +amount || minMaxPrice.max < +amount;

  const disabledBtn = !disabledAmount && disabled && +balance >= +amount;

  return (
    <div className={styles.btnBlock}>
      {(data?.status?.value === 'worker_rejected_order' && worker_id) ||
        (worker_id && isModerationSuccess && (
          <ButtonSave onClick={handleSaveAndSend} text='Сохранить и отправить' />
        ))}
      {isDraft ? (
        <div className={styles.root_blockBtn}>
          <ButtonSave
            disabled={!disabledAmount && +balance >= +amount && !disabledBtnPercent}
            onClick={() => handleSaveDraft(true)}
            // theme='button_d'
            theme={'button_draft'}
            text={'Сохранить как черновик'}
          />
          <ButtonSave
            disabled={disabledBtn && !disabledBtnPercent}
            onClick={() => handleSaveDraft(false)}
            // theme='button_d'
            // theme={!worker_id && 'button_draft'}
            text={'Сохранить и опубликовать заказ'}
          />
        </div>
      ) : isCanceled && data?.is_ready ? (
        <ButtonSave onClick={handleCancel} text='Отменить' />
      ) : isWorkSuccess ? (
        <div className={styles.root_blockBtn}>
          <ButtonSave onClick={handleCancel} text='Отменить' />
          <ButtonSave onClick={handleSignAndSend} text='Подписать акт' /></div>
      ) : isWorkerTookOrder ? (
        <ButtonSave onClick={handleApproveWorker} text='Утвердить исполнителя' />
      ) : null}
    </div>
  );
};
