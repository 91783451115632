import React, { FC } from 'react';
import { TitleRowTabBeneficiaries } from 'components/TitleRowTabBeneficiaries';
import { OrdersEmployedsItem } from 'ui/OrdersEmployedsItem';
import { useGetTabEmployedsQuery } from 'redux/employedsWorkSupportApi';
import { Nothing } from 'components/Nothing';
import styles from './Orders.module.scss';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
interface OrdersTypes {
  id: number | null;
  title: string;
}

export const Orders: FC<OrdersTypes> = ({ id = 0, title }) => {
  const { data, isLoading, error } = useGetTabEmployedsQuery({ id, title });

  // console.log('ordersEmployeds', data);

  const dataOrders = [
    'Дата начала',
    'Тип услуги',
    'Сумма',
    'Оплата',
    'Выплата',
    'Статус',
  ];

  return (
    <div className={styles.root}>
      <TitleRowTabBeneficiaries data={dataOrders} title='ordersEmployeds' />
      {error || !data?.length ? (
        <Nothing
          style={{ height: '640px' }}
          title={error ? 'Ошибка, попробуйте позже' : 'Ничего не найдено'}
        />
      ) : isLoading ? (
        <LoadingSkeleton tabDetails />
      ) : (
        data?.map((elem: any, i: number) => <OrdersEmployedsItem order={elem} key={i} />)
      )}
    </div>
  );
};
