import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PatternFormat } from 'react-number-format';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { auth } from 'api/getApi';
import { logIn } from 'redux/auth/slice';
import s from './Login.module.scss';

export const Login = () => {
  // const [valuePhone, setValuePhone] = useState('');
  const [width, setWidth] = useState(window?.innerWidth);
  const [loading, setLoading] = useState(false);
  const [formatState, setFormatState] = useState('+7 (###) ###-##-##');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const phoneRegExp = /^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/;
  const currentYear = new Date().getUTCFullYear();

  const location = useLocation();

  const fromPage = location.state?.from?.pathname;

  const phone = localStorage.getItem('phone');

  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      phone: phone?.slice(1),
      password: '',
    },
  });
  // {    resolver: (!phoneFromUrl['phone'] || valuePhone) && yupResolver(schema),}

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', cb);
    window.scroll(0, 0);

    return () => window.removeEventListener('resize', cb);
  }, []);

  const onSubmit = async (data) => {
    const phone = data.phone.length < 11 ? `7${data.phone}` : data.phone;

    try {
      setLoading(true);
      const result = await auth({ password: data.password, phone: phone });

      if (result.token) {
        dispatch(logIn(result.token));
        localStorage.setItem('token', result.token);
        localStorage.setItem('role', result.role);
        localStorage.setItem('phone', phone);
        if (localStorage.getItem('token')) {
          navigate(
            fromPage || result.role === 'director' ? '/orders' : '/beneficiaries',
            {
              replace: true,
            },
          );
          reset();
          window.location.reload();
        }
      } else {
        alert('Неправильный логин или пароль!');
      }
    } catch (error) {
      console.log('error', error);
      // alert('Ошибка, попробуйте позже');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate(
        localStorage.getItem('role') === 'director' ? '/orders' : '/beneficiaries',
        {
          replace: true,
        },
      );
    }
  }, [navigate]);

  return (
    <div className={s.enter}>
      <div className={s.inner}>
        <div className={s.item_logo}>
          <div className={s.logo}>
            <div>
              <img src='/static/logo-skilla.svg' alt='Логотип' />
            </div>
          </div>
          {/* {width > 575 && (
              <div className={s.rectangle}>
                <img src='/static/logo-skilla.png' alt='' />
              </div>
            )} */}
        </div>

        <h1 className={s.title}>Личный кабинет</h1>

        <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name={'phone'}
            rules={{
              required: 'Поле обязательно',
              // minLength: {
              //   value: elem.minLength,
              //   message: elem?.minLengthMessage,
              // },
            }}
            render={({ field: { value, onChange, onBlur } }) => (
              <PatternFormat
                // className={s.input}
                className={classNames(s.input, errors?.phone?.message && s.input_error)}
                format={formatState}
                placeholder={errors?.phone?.message || '+7 (___) ___-__-__'}
                onBlur={onBlur}
                value={value}
                onPaste={(event) => {
                  const pastedText = event.clipboardData.getData('text');
                  if (pastedText.startsWith('7') || pastedText.startsWith('+7')) {
                    setFormatState('+# (###) ###-##-##');
                    // setValueFormat(pastedText)
                  } else {
                    setFormatState('+7 (###) ###-##-##');
                  }
                  onChange(pastedText);
                }}
                onValueChange={(values) => {
                  onChange(values.value);
                }}
              />
            )}
          />

          <input
            placeholder={errors?.password?.message || 'Пароль'}
            className={classNames(s.input, errors?.password?.message && s.input_error)}
            type='password'
            {...register('password', { required: 'Поле обязательно' })}
          />

          <div className={s.wrapper_btn}>
            <button style={loading ? { cursor: 'not-allowed' } : {}} disabled={loading}>
              {loading
                ? 'Загрузка...'
                : width > 575
                ? 'Войти в личный кабинет'
                : 'Войти в кабинет'}
              {!loading && (
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z'
                    fill='white'
                  />
                </svg>
              )}
            </button>
          </div>
        </form>

        {/* {width <= 575 && (
            <div className={s.rectangle}>
              <img src='static/logo-skilla.png' alt='' />
            </div>
          )} */}
        <p className={s.descrlink}>
          Отправляя форму, вы соглашаетесь с{' '}
          <a target='_blank' href=' https://skilla.ru/politics'>
            условиями
          </a>
        </p>
        <span className={s.years}>© 2010-{currentYear} </span>
      </div>
    </div>
  );
};
