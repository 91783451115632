export const GENDER = ['Мужской', 'Женский'];
export const MENU_ITEM = ['app', 'docs', 'partners'];

export const MANAGERS = [];

export const GROUPS = [
  { id: 0, name: 'Погрузка – Разгрузка' },
  { id: 1, name: 'Квартирный/офисный переезд' },
  { id: 2, name: 'Такелажные работы' },
  { id: 3, name: 'Помощь по дому/участку' },
  { id: 4, name: 'Работы разнорабочего' },
  { id: 5, name: 'Земляные работы' },
  { id: 6, name: 'Уборка' },
];

export const WEEK_DAYS = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];
export const MONTH = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

import operation from 'assets/img/navSvg/operation.svg';
import benef from 'assets/img/navSvg/benef.svg';
import iconSettings from 'assets/img/navSvg/iconSettings.svg';
import employed from 'assets/img/navSvg/IconBuilder.svg';
import help from 'assets/img/navSvg/help.svg';
import tax from 'assets/img/navSvg/iconTax.svg';
import profile from 'assets/img/navSvg/iconProfile.svg';

export const navListWorkSupport = [
  ['Заказы', operation, '/orders'],
  ['Бенефициары', benef, '/beneficiaries'],
  ['Операции', operation, '/operations'],
  ['Самозанятые', employed, '/employedsWorkSupport'],
  ['Модерация', employed, '/performersWorkSupport'],
  ['Помощь', help, '/help'],
  // ['Настройки', iconSettings, '/settings'],
];

export const navListСustomer = [
  ['Заказы', operation, '/orders'],
  ['Исполнители', employed, '/employeds'],
  // ['Выплаты', operation, '/operations'],
  // ['Налоги', tax, '/taxes'],
  ['Профиль', profile, '/profile'],
];

export const navListСustomerProfile = [['Профиль', profile, '/profile']];

export const tempObj: Record<string, string> = {
  'Тип услуги': 'Мойка окон',
  Описание:
    'Нужно помыть окна в трехкомнатной квартире, нужно сделать быстро и качественно, иметь все необходимое оборудования для мойки с собой',
  'Дата начала ': '22.12.2023  17:00',
  'Дата окончания': '22.12.2023  17:00',
  Стоимость: '26 500 ₽',
  Локация:
    'г. Санкт-Петербург, пр-т Народного Ополчения, дом 123, корпус 1, квартира 116',
};

export const objPay: Record<string, string> = {
  Дата: '24 февраля 2024',
  Получатель: 'Пупкин Василий Васильевич',
  Телефон: '+7 (910) 797-71-70',
  ИНН: '1234567890',
  Сумма: '26 500 ₽',
  Выплата: '22 500 ₽',
  Комиссия: '4 000 ₽',
  'В копилку': '1 500 ₽',
  'Юр. лицо': 'ООО “Ромашка Сервис Экспресс”',
  Назначение: 'Оплата услуг по договору № 123456 от 22 апреля 2024',
  Реквизиты: '2245 5566 6677 7788',
};

export const fieldsArrJur = [
  {
    items: [
      {
        required: 'Поле обязательно',
        minLength: '10',
        minLengthMessage: 'Минимум 10 символов',
        maxLengthMessage: 'Максимум 10 символов',
        name: 'inn',
        placeholder: 'ИНН',
        type: 'number',
      },
      {
        required: 'Поле обязательно',
        minLength: '9',
        minLengthMessage: 'Минимум 9 символов',
        maxLengthMessage: 'Максимум 9 символов',
        name: 'kpp',
        placeholder: 'КПП',
        type: 'number',
      },
    ],
  },
  {
    required: 'Поле обязательно',
    minLength: '13',
    minLengthMessage: 'Минимум 13 символов',
    maxLength: 13,
    maxLengthMessage: 'Максимум 13 символов',
    name: 'ogrn',
    placeholder: 'ОГРН',
    type: 'number',
  },
  {
    required: 'Поле обязательно',
    minLength: '4',
    name: 'jur_name',
    placeholder: 'Наименование',
    type: 'text',
  },
  {
    required: 'Поле обязательно',
    // minLength: '4',
    name: 'jur_address',
    placeholder: 'Юридический адрес компании',
    type: 'text',
  },
  {
    required: 'Поле обязательно',
    // minLength: '4',
    name: 'phone',
    placeholder: 'Телефон',
    type: 'text',
    width: true,
  },
  {
    required: 'Поле обязательно',
    // minLength: '4',
    name: 'email',
    placeholder: 'E-mail',
    type: 'mail',
    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    patternMessage: 'Недопустимый формат',
    width: true,
    autocomplete: 'off',
  },
  {
    required: 'Поле обязательно',
    minLength: '6',
    minLengthMessage: 'Минимум 6 символов',
    name: 'password',
    placeholder: 'Пароль',
    type: 'password',
    width: true,
    autocomplete: 'off',
  },

  {
    required: 'Поле обязательно',
    // minLength: '4',
    maxLengthMessage: 'Максимум 50 символов',
    maxLength: 50,
    name: 'name',
    placeholder: 'Имя',
    type: 'text',
    width: true,
  },
  {
    required: 'Поле обязательно',
    // minLength: '4',
    maxLengthMessage: 'Максимум 50 символов',
    maxLength: 50,
    name: 'surname',
    placeholder: 'Фамилия',
    type: 'text',
    width: true,
  },
  {
    required: false,
    // minLength: '4',
    maxLengthMessage: 'Максимум 50 символов',
    maxLength: 50,
    name: 'patronymic',
    placeholder: 'Отчество',
    type: 'text',
    width: true,
  },

  {
    items: [
      {
        required: 'Поле обязательно',
        // minLength: '10',
        // minLengthMessage: 'Минимум 10 символов',
        // maxLengthMessage: 'Максимум 10 символов',
        name: 'commission_percent',
        placeholder: '%',
        type: 'number',
      },
      {
        required: 'Поле обязательно',
        // minLength: '9',
        // minLengthMessage: 'Минимум 9 символов',
        // maxLengthMessage: 'Максимум 9 символов',
        name: 'commission_min_amount',
        placeholder: 'но не менее',
        type: 'number',
      },
    ],
  },
];

export const fieldsArrIP = [
  {
    required: 'Поле обязательно',
    minLength: '12',
    minLengthMessage: 'Минимум 12 символов',
    maxLengthMessage: 'Максимум 12 символов',
    name: 'inn',
    placeholder: 'ИНН',
    type: 'number',
  },
  {
    required: 'Поле обязательно',
    minLength: '15',
    maxLength: 15,
    minLengthMessage: 'Минимум 15 символов',
    maxLengthMessage: 'Максимум 15 символов',
    name: 'ogrn',
    placeholder: 'ОГРН',
    type: 'number',
  },
  {
    required: 'Поле обязательно',
    minLength: '4',
    name: 'jur_name',
    placeholder: 'Наименование',
    type: 'text',
  },
  {
    required: 'Поле обязательно',
    // minLength: '4',
    name: 'jur_address',
    placeholder: 'Юридический адрес компании',
    type: 'text',
  },
  {
    required: 'Поле обязательно',
    // minLength: '4',
    maxLengthMessage: 'Максимум 50 символов',
    maxLength: 50,
    name: 'surname',
    placeholder: 'Фамилия',
    type: 'text',
    width: true,
  },
  {
    required: 'Поле обязательно',
    // minLength: '4',
    maxLengthMessage: 'Максимум 50 символов',
    maxLength: 50,
    name: 'name',
    placeholder: 'Имя',
    type: 'text',
    width: true,
  },
  {
    required: false,
    // minLength: '4',
    maxLengthMessage: 'Максимум 50 символов',
    maxLength: 50,
    name: 'patronymic',
    placeholder: 'Отчество',
    type: 'text',
    width: true,
  },

  {
    required: 'Поле обязательно',
    minLength: '4',
    name: 'phone',
    placeholder: 'Телефон',
    type: 'text',
    width: true,
  },
  {
    required: 'Поле обязательно',
    minLength: '4',
    name: 'email',
    placeholder: 'E-mail',
    type: 'mail',
    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    patternMessage: 'Недопустимый формат',
    width: true,
    autocomplete: 'off',
  },
  {
    required: 'Поле обязательно',
    minLength: '6',
    minLengthMessage: 'Минимум 6 символов',
    name: 'password',
    placeholder: 'Пароль',
    type: 'password',
    width: true,
    autocomplete: 'off',
  },

  {
    items: [
      {
        required: 'Поле обязательно',
        // minLength: '10',
        // minLengthMessage: 'Минимум 10 символов',
        // maxLengthMessage: 'Максимум 10 символов',
        name: 'commission_percent',
        placeholder: '%',
        type: 'string',
      },
      {
        required: 'Поле обязательно',
        // minLength: '9',
        // minLengthMessage: 'Минимум 9 символов',
        // maxLengthMessage: 'Максимум 9 символов',
        name: 'commission_min_amount',
        placeholder: 'но не менее',
        type: 'number',
      },
    ],
  },
];

export const fieldsArrF = [
  {
    required: 'Поле обязательно',
    minLength: '12',
    minLengthMessage: 'Минимум 12 символов',
    name: 'inn',
    placeholder: 'ИНН',
    type: 'number',
  },
  {
    required: 'Поле обязательно',
    // minLength: '4',
    maxLengthMessage: 'Максимум 50 символов',
    maxLength: 50,
    name: 'surname',
    placeholder: 'Фамилия',
    type: 'text',
    width: true,
  },
  {
    required: 'Поле обязательно',
    // minLength: '4',
    maxLengthMessage: 'Максимум 50 символов',
    maxLength: 50,
    name: 'name',
    placeholder: 'Имя',
    type: 'text',
    width: true,
  },
  {
    required: false,
    // minLength: '4',
    maxLengthMessage: 'Максимум 50 символов',
    maxLength: 50,
    name: 'patronymic',
    placeholder: 'Отчество',
    type: 'text',
    width: true,
  },
  {
    required: 'Поле обязательно',
    // minLength: '4',
    name: 'dob',
    placeholder: 'Дата рождения',
    type: 'date',
  },
  {
    required: 'Поле обязательно',
    minLength: 11,
    minLengthMessage: 'Минимум 11 символов',
    name: 'phone',
    placeholder: 'Телефон',
    type: 'text',
  },
  {
    required: 'Поле обязательно',
    // minLength: '4',
    name: 'email',
    placeholder: 'E-mail',
    type: 'mail',
    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    patternMessage: 'Недопустимый формат',
    autocomplete: 'off',
  },
  {
    required: 'Поле обязательно',
    minLength: '6',
    minLengthMessage: 'Минимум 6 символов',
    name: 'password',
    placeholder: 'Пароль',
    type: 'password',
    autocomplete: 'off',
  },
];
