import React, { FC, useState } from 'react';
import { currentDay, getFotmattedDateCreated } from 'utils/date';
import styles from './DocumentsEmployedsItem.module.scss';
import { addSpaceNumber } from 'utils/addSpaceNumber';
import { downloadFile } from 'api/getApi';

interface DocumentsEmployedsItemTypes {
  doc: any;
}

export const DocumentsEmployedsItem: FC<DocumentsEmployedsItemTypes> = ({ doc }) => {
  const [downloadLoading, setDownloadLoading] = useState(false);

  const downloadFileAction = async () => {
    try {
      setDownloadLoading(true);
      const response = await downloadFile(
        `${process.env.REACT_APP_API_SERVER_TEST_EMPLOYEDS}/worksupport/doc/download`,
        { type: doc.type, order_id: doc.order_id },
      );
      if (!response.data) {
        throw new Error('Ошибка при загрузке файла');
      }
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);
      // Открываем файл в новой вкладке
      window.open(url, '_blank');
      // Освобождаем ресурсы URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      setDownloadLoading(false);
    }
  };

  return (
    <div className={styles.root}>
      <div>
        <p className={styles.root_text}>{currentDay(doc.created_at?.split(' ')[0])}</p>
        <p className={styles.root_subText}>{doc.created_at?.split(' ')[1]}</p>
      </div>
      <p
        onClick={downloadFileAction}
        style={{ width: '100%', textDecoration: 'underline', cursor: 'pointer' }}
        className={`${styles.root_text}, ${styles.textHover}`}>
        {downloadLoading ? 'Загрузка...' : doc.name}
      </p>
      <div>
        <p className={styles.root_text}>
          {doc?.signed_at ? currentDay(doc?.signed_at?.split(' ')[0]) : null}
        </p>
        <p className={styles.root_subText}>{doc?.signed_at?.split(' ')[1]}</p>
      </div>
    </div>
  );
};
