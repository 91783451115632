import React, { FC, useEffect, useRef } from 'react';
import { FixedSizeList } from 'react-window';
import { TitleRowTabOperations } from 'components/TitleRowTabOperations';
import { useGetTabOperationsQuery } from 'redux/operationsApi';
import { DealsOperationsItem } from 'ui/DealsOperationsItem';
import { useDispatch } from 'react-redux';
import { setCountTab, setShowModalDetailsInfo } from 'redux/operationInfo/slice';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { Link } from 'react-router-dom';
import { Nothing } from 'components/Nothing';
import styles from './Deals.module.scss';
interface DealsTypes {
  title: string;
}

export const Deals: FC<DealsTypes> = ({ title }) => {
  const refList = useRef<any>(null);
  const pageHeight = document.documentElement.scrollHeight;
  const { data, isLoading } = useGetTabOperationsQuery({ title });
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) dispatch(setCountTab({ countTab: data.count, nameTab: 'Сделки' }));
  }, [data]);

  const dataDeals = [
    'id',
    'Сумма',
    'Бенефициар',
    'Получатель',
    'Ответ комплайнс',
    'Статус',
  ];

  const Row = ({ index, style = {} }: any) => {
    return (
      <div style={style}>
        <Link to={`/operations/deals/${data?.items[index]?.id}`}>
          <DealsOperationsItem deal={data?.items[index]} />
        </Link>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      {isLoading ? (
        <LoadingSkeleton widthTab />
      ) : (
        <>
          <TitleRowTabOperations data={dataDeals} title={title} />
          {data ? (
            <FixedSizeList
              className={styles.window}
              ref={refList}
              height={
                data?.items.length < 10 ? 83 * data?.items.length : pageHeight - 300
              }
              width='100%'
              itemCount={data?.items.length ? data?.items.length : 0}
              itemSize={81}>
              {Row}
            </FixedSizeList>
          ) : (
            <Nothing style={{ height: '660px' }} title='Ничего не найдено' />
          )}
        </>
      )}
    </div>
  );
};
