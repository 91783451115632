import { FC, useEffect, useState } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { TitleRow } from 'components/TitleRow';
import { Nothing } from 'components/Nothing';
import { PerformersItem } from 'ui/PerformersItem';
import { Performers } from 'redux/performersWorkSupport';
import styles from './PerformersList.module.scss';

interface PerformersListTypes {
  data: Performers[];
}

export const PerformersList: FC<PerformersListTypes> = ({ data }) => {
  // console.log('data', data);
  const [parent] = useAutoAnimate({ duration: 300 });

  const role = localStorage.getItem('role') || 'WorkSupport';

  const dataTitle = ['Исполнитель', 'Телефон', 'Статус'];

  return (
    <div ref={parent} className={styles.root}>
      <TitleRow data={dataTitle} title={'performersList'} />
      {data?.length ? (
        data.map((item: Performers) => (
          <div key={item.id}>
            <PerformersItem employed={item} />
          </div>
        ))
      ) : (
        <Nothing style={{ height: '640px' }} title='Ничего не найдено' />
      )}
    </div>
  );
};
