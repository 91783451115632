import { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './InputText.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorStatusField, setWasChanges } from 'redux/mainInfo/slice';

const cx = classNames.bind(styles);

type InputTextProps = {
  placeholder?: string;
  placeholderValue?: string;
  value: string;
  // setValue?: any;
  onChange?: any;
  props?: any;
  type?: 'text' | 'email' | 'tel' | 'number';
  classNameFocus?: boolean;
  error?: boolean | undefined;
  nameError?: string;
  isModerated?: boolean;
  style?: any;
  isDisabled?: boolean;
  price?: string;
  maxLength?: number;
  isfocus?: boolean;
  onBlurHandler?: any;
  beneficiaries?: boolean;
};

export const InputText: FC<InputTextProps> = ({
  placeholder,
  placeholderValue = '',
  value,
  // setValue,
  onChange,
  onBlurHandler,
  props,
  type = 'text',
  classNameFocus,
  error = false,
  nameError,
  maxLength,
  style,
  isDisabled = false,
  price,
  isfocus = false,
  beneficiaries = false,
}) => {
  const inputRef = useRef<any>(null);
  const placeholderRef = useRef<any>(null);
  const [focus, setFocus] = useState<boolean>(false);
  const dispatch = useDispatch();

  // console.log('error', error);

  const classNameInput = cx({
    input: true,
    focus: focus || classNameFocus,
    errorInput: error,
  });

  const classNamePlaceholder = cx({
    placeholder: true,
    transition: !value,
    errorPlaceholder: error,
  });

  // console.log('object', nameError);

  const changeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // dispatch(setValue(e.target.value.trimStart()));
    dispatch(setWasChanges(true));
  };

  const focusInput = () => {
    setFocus(true);
  };

  const blurInput = (e: any) => {
    if (beneficiaries) onBlurHandler(e);
    if (!inputRef.current.value) {
      setFocus(false);
    }
    if (!value) {
      // nameError
      dispatch(setErrorStatusField({ key: nameError, value: 1 }));
    }
  };

  useEffect(() => {
    if (inputRef.current.value || value) setFocus(true);
  }, [value]);

  return (
    <div className={styles.root}>
      <input
        maxLength={maxLength}
        placeholder={placeholderValue}
        style={style}
        disabled={isDisabled}
        className={classNameInput}
        ref={inputRef}
        type={type}
        // onChange={changeInput}
        onChange={onChange}
        onFocus={focusInput}
        autoComplete='disabled'
        onBlur={blurInput}
        value={value}
        {...props}
      />
      {placeholder && (
        <span
          style={isDisabled ? { background: 'transparent' } : {}}
          className={classNamePlaceholder}
          ref={placeholderRef}>
          {placeholder}
        </span>
      )}
      {price && <span className={styles.price}>{price}</span>}
    </div>
  );
};
