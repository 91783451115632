import React, { FC } from 'react';
import { TitleRow } from 'components/TitleRow';
import { Link } from 'react-router-dom';
import { CustomerTaxeItem } from 'ui/CustomerTaxeItem';
import { TaxesItemsTypes } from 'redux/taxesApi';
import styles from './TaxesList.module.scss';
interface TaxesListTypes {
  taxesList: TaxesItemsTypes[];
}

export const TaxesList: FC<TaxesListTypes> = ({ taxesList }) => {
  const dataTitle = [
    'Месяц',
    'Юридические лицо',
    'В копилке',
    'Начислено',
    'Оплачено',
    'Статус',
  ];

  return (
    <div className={styles.root}>
      <TitleRow data={dataTitle} title={'taxesCustomer'} />
      {taxesList ? (
        taxesList.map((elem: TaxesItemsTypes, i) => (
          <Link key={elem.id} to={`/taxes/${elem.id}`}>
            <CustomerTaxeItem taxe={elem} />
          </Link>
        ))
      ) : (
        <div className={styles.root_nothing}>
          <p>Ничего не найдено</p>
        </div>
      )}
    </div>
  );
};
