import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { BeneficiariesTabBtn } from 'ui/BeneficiariesTabBtn';
import { TabTaxeDetails } from 'components/TabTaxeDetails';
import { InformationOrderBlock } from 'components/InformationOrderBlock';
import { Title } from 'components/Title';
import { useGetTabsQuery, useGetTaxeQuery } from 'redux/taxesApi';
import styles from './TaxeDetails.module.scss';

export const TaxeDetails = () => {
  const [tempLoading, setTempLoading] = useState(false);
  const { id } = useParams();
  const { data, isLoading, error } = useGetTaxeQuery({ id });
  const {
    data: tabsData,
    isLoading: tabsLoading,
    error: tabsError,
  } = useGetTabsQuery({ id });

  const { id: idTaxe, status, ...obj } = data || {};

  return (
    <div>
      {isLoading || tabsLoading ? (
        <LoadingSkeleton mt />
      ) : (
        <div className={styles.root}>
          <Title className={styles.root_title} title='Налоговая ведомость' />
          <div className={styles.content}>
            <div className={styles.left}>
              <div className={styles.left_title}>
                <p className={styles.title}>Детали ведомости</p>
                <BeneficiariesTabBtn
                  bg={status?.color_bg}
                  color={status?.color_text}
                  title={status?.text}
                />
              </div>
              <div className={styles.blockDetailRoot}>
                <InformationOrderBlock objectInfo={obj} />
              </div>
            </div>

            <div className={styles.right}>
              {<TabTaxeDetails id={id} tabs={tabsData} />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
