import { FC, useEffect, useState } from 'react';
import { TabMenu } from 'components/TabMenu';
import { Summary } from './Summary';
import { Employees } from './Employees';
import { TabsTypes } from 'redux/ProfileApi';
import { Nothing } from 'components/Nothing';
import styles from './TabProfile.module.scss';
interface TabProfileTypes {
  tabsData: TabsTypes[];
}

export const TabProfile: FC<TabProfileTypes> = ({ tabsData }) => {
  // console.log('tabsData', tabsData);
  const [active, setActive] = useState<string>('summary');

  const content: Record<string, React.ReactNode> = {
    summary: <Summary title={active} />,
    staff: <Employees title={active} />,
    templates: <Nothing style={{ height: '640px' }} title='Ничего не найдено' />,
  };

  return (
    <div className={styles.root}>
      <TabMenu lists={tabsData} active={active} setActive={setActive} />

      {tabsData && <div className={styles.content}>{content[active]}</div>}
    </div>
  );
};
