import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Layout } from 'features/Layouts/Layouts';
import { useGetBeneficiariesQuery } from 'redux/beneficiariesApi';
import { MainTitle } from 'components/MainTitle';
// import { SubHeader } from 'components/SubHeader';
import { BeneficiariesList } from 'components/BeneficiariesList';
import { InputSearch } from 'ui/InputSearch';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import styles from './BeneficiariesContent.module.scss';

export const BeneficiariesContent: FC = () => {
  const { data, isLoading, error } = useGetBeneficiariesQuery();
  const [searchValue, setSearchValue] = useState('');

  // console.log('data', data);

  const filtredArr = data?.items?.filter(
    (elem: any) =>
      elem?.inn
        ?.replaceAll(' ', '')
        ?.toLowerCase()
        ?.includes(searchValue?.replaceAll(' ', '').toLowerCase()) ||
      elem?.jur_name
        ?.replaceAll(' ', '')
        ?.toLowerCase()
        ?.includes(searchValue?.replaceAll(' ', '').toLowerCase()),
  );

  return (
    <Layout>
      <div className={styles.root}>
        <MainTitle title='Бенефициары' count={data?.count} />
        <div className={styles.root_search}>
          <InputSearch
            notFocus
            placeholder='Искать'
            value={searchValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchValue(e.target.value)
            }
          />
        </div>
        {/* <SubHeader create /> */}
        {isLoading ? (
          <LoadingSkeleton />
        ) : (
          <BeneficiariesList data={filtredArr ? filtredArr : []} />
        )}
      </div>
    </Layout>
  );
};
