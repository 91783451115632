import React, { FC, useState } from 'react';
import { TabMenu } from 'components/TabMenu';
import { TaxeReady } from './TaxeReady';
import { TaxeError } from './TaxeError';
import { ItemTaxeTab } from 'redux/taxesApi';
import styles from './TabTaxeDetails.module.scss';
interface TabTaxeDetailsTypes {
  tabs: ItemTaxeTab[];
  id?: string;
}

export const TabTaxeDetails: FC<TabTaxeDetailsTypes> = ({ tabs, id }) => {
  const [active, setActive] = useState<string>(tabs[0]?.key);

  const content: Record<string, React.ReactNode> = {
    ['taxes-ready-to-be-paid' || 'potential-errors']: (
      <TaxeReady title={active} id={id} />
    ),
    ['potential-errors' || 'errors']: <TaxeError title={active} id={id} />,
  };

  // console.log('active', active);

  return (
    <div className={styles.root}>
      <TabMenu cls={{ padding: 0 }} lists={tabs} active={active} setActive={setActive} />

      <div className={styles.content}>{content[active]}</div>
    </div>
  );
};
