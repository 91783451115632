import React, { FC } from 'react';
import styles from './BeneficiariesTabBtn.module.scss';
import classNames from 'classnames/bind';

interface BeneficiariesTabBtnTypes {
  isActive?: boolean;
  title?: string;
  bg?: string;
  color?: string;
  icon?: boolean;
  src?: string;
  oldStyle?: boolean;
  style?: any;
  classNameStyles?: any;
}

const cx = classNames.bind(styles);

export const BeneficiariesTabBtn: FC<BeneficiariesTabBtnTypes> = ({
  isActive,
  title = '',
  bg = '',
  color = '',
  icon,
  src,
  oldStyle = false,
  style,
  classNameStyles,
}) => {
  const classNameBtn = cx({
    root: true,
    root_isActive: isActive,
  });

  return (
    <div className={`${classNameBtn} ${classNameStyles}`}>
      {icon && <img src={src} alt='Icon' />}
      {!oldStyle
        ? bg && (
            <div className={oldStyle ? '' : styles.circle} style={{ background: bg }} />
          )
        : null}
      <p
        className={oldStyle ? styles.oldStyle : ''}
        style={oldStyle ? { background: bg, color, ...style } : {}}
        // style={color ? { color: color } : {}}
      >
        {title}
      </p>
    </div>
  );
};
