import React, { FC, useEffect, useRef, useState } from 'react';
import { FixedSizeList } from 'react-window';
import Pagination from '@mui/material/Pagination';
import { TitleRowTabOperations } from 'components/TitleRowTabOperations';
import { PaymentsOperationsItem } from 'ui/PaymentsOperationsItem';
import { useGetTabOperationsQuery } from 'redux/operationsApi';
import { useDispatch } from 'react-redux';
import { setCountTab } from 'redux/operationInfo/slice';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { Link } from 'react-router-dom';
import { Nothing } from 'components/Nothing';
import styles from './Payments.module.scss';
interface PaymentsTypes {
  title: string;
}

export const Payments: FC<PaymentsTypes> = ({ title }) => {
  const refList = useRef<any>(null);
  const pageHeight = document.documentElement.scrollHeight;
  const { data, isLoading } = useGetTabOperationsQuery({ title });
  const dispatch = useDispatch();
  // console.log('Payments', data);

  useEffect(() => {
    if (data) dispatch(setCountTab({ countTab: data.count, nameTab: 'Платежи' }));
  }, [data]);

  const dataPayments = [
    'Дата создания',
    'id',
    'Бенефициар',
    'Получатель',
    'Тип платежа',
    'Сумма',
    'Дата обновления',
    'Статус',
  ];

  const [curBtn, setCurBtn] = useState(1);

  const Row = ({ index, style = {} }: any) => {
    return (
      <div style={style}>
        <Link to={`/operations/payments/${data?.items[index]?.id}`}>
          <PaymentsOperationsItem payment={data?.items[index]} />
        </Link>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      {isLoading ? (
        <LoadingSkeleton widthTab />
      ) : (
        <>
          <TitleRowTabOperations data={dataPayments} title={title} />
          {/* {data && (
            <Pagination
              onChange={(_, num) => setCurBtn(num)}
              count={Math.ceil(data.items.length / 30)}
              page={curBtn}
              boundaryCount={2}
              sx={{ marginY: 1, paddingLeft: 3 }}
            />
          )} */}
          {data ? (
            <FixedSizeList
              className={styles.window}
              ref={refList}
              height={
                data?.items.length < 10 ? 83 * data?.items.length : pageHeight - 300
              }
              width='100%'
              itemCount={data?.items.length ? data?.items.length : 0}
              itemSize={73}>
              {Row}
            </FixedSizeList>
          ) : (
            <Nothing style={{ height: '660px' }} title='Ничего не найдено' />
          )}
        </>
      )}
    </div>
  );
};
