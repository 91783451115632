/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState, useRef } from 'react';
import { AddressSuggestions, DaDataSuggestion, DaDataAddress } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';
import './react-address-dadata.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { setErrorStatusField, setWasChanges } from 'redux/mainInfo/slice';
import { setInfoProfileCreate } from 'redux/createProfile/slice';

interface AddressDadataReg {
  placeholder: string;
  objKey: string;
}

export const AddressDadataReg: FC<AddressDadataReg> = ({ placeholder, objKey }) => {
  const [value, setValue] = useState<DaDataSuggestion<DaDataAddress> | undefined>();

  const { jur_address } = useSelector((state: RootState) => state.createProfile);
  const [defaultValue, setDefaultValue] = useState<any>();

  // console.log('jur_address', jur_address);

  const suggestionsRef = useRef<AddressSuggestions>(null);
  const dispatch = useDispatch();

  // console.log('suggestionsRef', suggestionsRef?.current);

  useEffect(() => {
    if (objKey && value) {
      dispatch(setErrorStatusField({ key: objKey, value: 0 }));
    }
    if (value) {
      dispatch(setInfoProfileCreate({ key: objKey, value: value?.value }));
      dispatch(setWasChanges(true));
    }
    if (defaultValue === '' && suggestionsRef.current) {
      suggestionsRef.current.setInputValue('');
      dispatch(setInfoProfileCreate({ key: objKey, value: '' }));
      dispatch(setWasChanges(true));
    }
  }, [value, defaultValue]);

  //Следим за jur_address и подставляем в поле Юр адресс при воборе определённого инн (создание бенефициара)
  useEffect(() => {
    if (suggestionsRef.current && jur_address) {
      // console.log('first');
      suggestionsRef.current.setInputValue(jur_address);
      dispatch(setWasChanges(true));
    }
  }, [jur_address]);

  return (
    <AddressSuggestions
      ref={suggestionsRef}
      token={`${process.env.REACT_APP_API_KEY}`}
      value={value}
      onChange={setValue}
      defaultQuery=''
      count={5}
      selectOnBlur={true}
      highlightClassName='suggestion_highlight'
      currentSuggestionClassName='suggestion_select'
      inputProps={{
        placeholder: placeholder,
        onChange: (e: any) => {
          if (e) setDefaultValue(e.target.value.trimStart());
        },
        onBlur: (e: any) => {
          if (!e.target.value.trimStart() && objKey)
            dispatch(setErrorStatusField({ key: objKey, value: 1 }));
          // console.log('object', e.target.value);
          // console.log('value', value);
        },
      }}
    />
  );
};
