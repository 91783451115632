import React from 'react';
import { MainTitle } from 'components/MainTitle';
import { TaxesList } from 'components/TaxesList';
import { useGetTaxesQuery } from 'redux/taxesApi';
import { Nothing } from 'components/Nothing';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import styles from './Taxes.module.scss';

export const Taxes = () => {
  const { data, isLoading, error } = useGetTaxesQuery();

  if (isLoading) {
    return <LoadingSkeleton mt />;
  }
  return (
    <div className={styles.root}>
      <MainTitle title='Налоги' count={data?.count} />
      {data?.items?.length ? (
        <TaxesList taxesList={data?.items} />
      ) : (
        <Nothing style={{ height: '750px' }} title='Ничего не найдено' />
      )}
    </div>
  );
};
