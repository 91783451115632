import { FC, useEffect, useRef, useState } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { FixedSizeList } from 'react-window';
import { TitleRow } from 'components/TitleRow';
import { Link } from 'react-router-dom';
import { OrderItem } from 'ui/OrderItem';
import { CustomerOrderItem } from 'ui/CustomerOrderItem';
import { Nothing } from 'components/Nothing';
import styles from './OrdersList.module.scss';

interface OrdersListTypes {
  data: any;
}

export const OrdersList: FC<OrdersListTypes> = ({ data }) => {
  const role = localStorage.getItem('role') || 'WorkSupport';
  const refList = useRef<any>(null);
  const [parent] = useAutoAnimate({ duration: 300 });

  // const heightBlock = window.screen.availHeight;
  const pageHeight = document.documentElement.scrollHeight;

  const dataTitle =
    role === 'director'
      ? ['Дата добавления', 'Услуга', 'Исполнитель', 'Стоимость', 'Статус']
      : [
          'Период',
          'Заказчик',
          'Исполнитель',
          'Услуга',
          'Стоимость',
          'Комиссия',
          'Статус',
        ];

  const Row = ({ index, style = {} }: any) => {
    return (
      <div style={style}>
        {data && (
          <Link to={`/orders/${data[index]?.id}`}>
            {role === 'director' ? (
              <CustomerOrderItem order={data[index]} />
            ) : (
              <OrderItem order={data[index]} />
            )}
          </Link>
        )}
      </div>
    );
  };

  return (
    <div ref={parent} className={styles.root}>
      <TitleRow
        data={dataTitle}
        title={role === 'director' ? 'ordersCustomer' : 'orders'}
      />
      {data?.length ? (
        <FixedSizeList
          className={styles.window}
          ref={refList}
          height={data?.length < 10 ? 83 * data?.length : pageHeight - 300}
          width='100%'
          itemCount={data?.length ? data?.length : 0}
          itemSize={80}>
          {Row}
        </FixedSizeList>
      ) : (
        <Nothing style={{ height: '640px' }} title='Ничего не найдено' />
      )}
    </div>
  );
};
