import { FC, useEffect, useState } from 'react';
import { TabMenu } from 'components/TabMenu';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { VirtualAccounts } from './VirtualAccounts';
import { Deals } from './Deals';
import { HistoryTab } from './History';
import { Payments } from './Payments';
import styles from './TabBeneficiaries.module.scss';

export const TabBeneficiaries: FC = () => {
  // const windowWorkerId = window.location.pathname.split('/').slice(-1)[0];
  // const { workerId, isWorkerUrl } = useSelector((state: RootState) => state.mainInfo);
  const [active, setActive] = useState<string>('virtual_accounts');
  const { infoBeneficiar } = useSelector((state: RootState) => state.beneficiarSlice);

  // console.log('infoBeneficiar', infoBeneficiar);

  // useEffect(() => {
  //   setActive(isWorkerUrl ? 'work' : 'docs');
  // }, [isWorkerUrl]);

  const content: Record<string, React.ReactNode> = {
    virtual_accounts: <VirtualAccounts id={infoBeneficiar.id} title={active} />,
    deals: <Deals id={infoBeneficiar?.id} title={active} />,
    history: <HistoryTab id={infoBeneficiar?.id} title={active} />,
    payments: <Payments id={infoBeneficiar?.id} title={active} />,
  };

  return (
    <div className={styles.root}>
      <TabMenu
        lists={infoBeneficiar?.lists?.available_tabs}
        active={active}
        setActive={setActive}
      />

      {infoBeneficiar?.lists?.available_tabs && (
        <div className={styles.content}>{content[active]}</div>
      )}
    </div>
  );
};
