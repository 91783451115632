import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { operationInfoTypes } from './types';

const initialState: operationInfoTypes = {
  infoTab: {
    countTab: 0,
    nameTab: '',
  },
  searchValue: '',
  modalDetailsInfo: {},
  showModal: false,
};

export const operationInfoSlice = createSlice({
  name: 'operationInfoSlice',
  initialState,
  reducers: {
    setCountTab: (state, action) => {
      state.infoTab = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setShowModalDetailsInfo: (state, action) => {
      state.modalDetailsInfo = action.payload;
      state.showModal = true;
    },
    setCloseModalDetailsInfo: (state) => {
      state.modalDetailsInfo = {};
      state.showModal = false;
    },
  },
});

export const {
  setCountTab,
  setSearchValue,
  setShowModalDetailsInfo,
  setCloseModalDetailsInfo,
} = operationInfoSlice.actions;
export default operationInfoSlice.reducer;
