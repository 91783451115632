import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InfoOrderDetailTypes, EmployedsInfoTypes } from './types';
import { getDateFormattedDateForParams } from 'utils/date';

interface HourMinutesTypes {
  hour: string;
  minutes: string;
}

interface StateType {
  infoOrderDetail: InfoOrderDetailTypes;
  employedInfo: EmployedsInfoTypes;
  dateStart: any;
  dateEnd: any;
  dateStartHourMinutes: HourMinutesTypes;
  dateEndHourMinutes: HourMinutesTypes;
}

const initialState: StateType = {
  infoOrderDetail: {
    order_type_id: 0,
    description: '',
    date_start: new Date(),
    date_end: new Date(),
    amount: '',
    is_remote: 'Удаленно',
    city: '',
    street: '',
    home: '',
    apartment: '',
    worker_id: null,
    is_draft: 0,
  },
  employedInfo: {
    name: '',
    inn: '',
    status: 1,
    phone: '',
  },
  dateStart: new Date(),
  dateEnd: new Date(),
  dateStartHourMinutes: {
    hour: '00',
    minutes: '00',
  },
  dateEndHourMinutes: {
    hour: '00',
    minutes: '00',
  },
};

export const ordersDetailsSlice = createSlice({
  name: 'ordersDetailsSlice',
  initialState,
  reducers: {
    setDateStart: (state, action) => {
      state.infoOrderDetail.date_start = action.payload;
    },
    setDateEnd: (state, action) => {
      state.infoOrderDetail.date_end = action.payload;
    },
    setInfoOrderDetail: (state, action) => {
      const { key, value } = action.payload;
      state.infoOrderDetail[key] = value;
    },
    setEmployedsInfo: (state, action) => {
      state.employedInfo = action.payload;
    },
    setInfoDateHourMinutes: (state, action) => {
      if (action.payload.key === 'date_start') {
        state.dateStartHourMinutes.hour = action.payload.value.slice(0, 2);
        state.dateStartHourMinutes.minutes = action.payload.value.slice(3);
      } else {
        state.dateEndHourMinutes.hour = action.payload.value.slice(0, 2);
        state.dateEndHourMinutes.minutes = action.payload.value.slice(3);
      }
    },
    setDateHourMinutes: (state, action) => {
      if (action.payload.position === 'left') {
        state.dateStartHourMinutes = {
          ...state.dateStartHourMinutes,
          [action.payload.key]: action.payload.value,
        };
      } else {
        state.dateEndHourMinutes = {
          ...state.dateEndHourMinutes,
          [action.payload.key]: action.payload.value,
        };
      }
    },
    resetOrderInfo: (state) => {
      state.infoOrderDetail = {
        order_type_id: 0,
        description: '',
        date_start: new Date(),
        date_end: new Date(),
        amount: 0,
        is_remote: 'Удаленно',
        city: '',
        street: '',
        home: '',
        apartment: '',
        worker_id: null,
        is_draft: 0,
      };
      state.employedInfo = {
        name: '',
        inn: '',
        status: 1,
        phone: '',
      };
      state.dateStartHourMinutes = {
        hour: '00',
        minutes: '00',
      };
      state.dateEndHourMinutes = {
        hour: '00',
        minutes: '00',
      };
    },
  },
});

export const {
  setDateStart,
  setDateEnd,
  setInfoOrderDetail,
  setEmployedsInfo,
  setInfoDateHourMinutes,
  setDateHourMinutes,
  resetOrderInfo,
} = ordersDetailsSlice.actions;
export default ordersDetailsSlice.reducer;
