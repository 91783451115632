import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// const token = localStorage.getItem('token');

interface statusTypes {
  key: string;
  text: string;
  color_text: string;
  color_bg: string;
}

export interface TaxesItemsTypes {
  customer_jur_name: string;
  id: number;
  period: string;
  status: statusTypes;
  taxes_accrued_amount: string;
  taxes_on_virtual_account_amount: string;
  taxes_paid_amount: string;
}

export interface TaxesTypes {
  count: number;
  items: TaxesItemsTypes[];
}

export interface ItemTaxeTab {
  idx: string;
  name: string;
  key: string;
}

export interface TabsTypes {
  items: ItemTaxeTab[];
}

export const taxesApi = createApi({
  reducerPath: 'taxesApi',
  tagTypes: ['Taxes', 'Taxe', 'Tabs', 'Tab'],
  refetchOnReconnect: true,
  keepUnusedDataFor: 15,
  // refetchOnFocus: true,
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_SERVER_TEST_EMPLOYEDS}`,
    headers: {
      'Content-Type': 'application/json',
      // Authorization: token ? `Bearer ${token}` : '',
      // Accept: 'application/json',
    },
  }),
  endpoints: (build) => ({
    getTaxes: build.query<TaxesTypes | any, void>({
      // query: () => ({
      //   url: '/taxes/tax-rolls',
      //   method: 'GET',
      // }),
      query: () => {
        const token = localStorage.getItem('token');
        const Authorization = token ? `Bearer ${token}` : undefined;
        return {
          url: '/taxes/tax-rolls',
          method: 'GET',
          headers: {
            Authorization,
            Accept: 'application/json',
          },
        };
      },
      providesTags: () => ['Taxes'],
    }),
    getTaxe: build.query<TaxesItemsTypes, { id: string | any }>({
      // query: ({ id }) => ({
      //   url: `/taxes/tax-rolls/${id}`,
      //   method: 'GET',
      // }),
      query: ({ id }) => {
        const token = localStorage.getItem('token');
        const Authorization = token ? `Bearer ${token}` : undefined;
        return {
          url: `/taxes/tax-rolls/${id}`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization,
          },
        };
      },
      providesTags: () => ['Taxe'],
    }),
    //http://customer-test.skilla.ru/api/customer/tabs
    getTabs: build.query<TabsTypes | any, { id: string | any }>({
      // query: ({ id }) => ({
      //   url: `/taxes/tax-rolls/${id}/tabs`,
      //   method: 'GET',
      // }),
      query: ({ id }) => {
        const token = localStorage.getItem('token');
        const Authorization = token ? `Bearer ${token}` : undefined;
        return {
          url: `/taxes/tax-rolls/${id}/tabs`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization,
          },
        };
      },
      providesTags: () => ['Tabs'],
      transformResponse: (response: any) => response?.items,
      // invalidatesTags: []
    }),
    getTab: build.query<any, any>({
      // query: ({ title, id }) => ({
      //   url: `/taxes/tax-rolls/${id}/tabs/${title}`,
      //   method: 'GET',
      // }),
      query: ({ title, id }) => {
        const token = localStorage.getItem('token');
        const Authorization = token ? `Bearer ${token}` : undefined;
        return {
          url: `/taxes/tax-rolls/${id}/tabs/${title}`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization,
          },
        };
      },
      providesTags: () => ['Tab'],
      // transformResponse: (response: any) => response?.items,
      // invalidatesTags: []
    }),
  }),
});

export const { useGetTaxesQuery, useGetTabsQuery, useGetTaxeQuery, useGetTabQuery } =
  taxesApi;
