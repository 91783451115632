import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
// import { ordersQueryType, orderApiTypes, OrderTypes } from 'models/orderTypes';

const getToken = () => localStorage.getItem('token');
const getRole = () => localStorage.getItem('role');

const getAuthorizationHeader = () => {
  const token = getToken();
  return token ? `Bearer ${token}` : '';
};

// console.log('getToken', getToken());

export interface TabsTypes {
  idx: number;
  key: string;
  name: string;
}

interface DocTypes {
  file: string;
  uuid: string;
  is_signed: boolean | null;
  date: string | null;
  name: string | null;
  is_added_to_bank: boolean | null;
}

interface VirtualAccTypes {
  uuid: string;
  main_account_cash?: number;
  taxes_account_cash?: number;
  total_cash?: number;
  main_account_hold?: number;
}

interface CustomerTypes {
  email: string;
  inn: string;
  jur_address: string;
  kpp: string;
  legal_type: string;
  name: string | null;
  ogrn: string;
  phone: string;
}

interface BeneficiaryTypes {
  document: DocTypes;
  virtual_account: VirtualAccTypes;
}

interface ProfileTypes {
  id: number;
  beneficiary: BeneficiaryTypes;
  customer: CustomerTypes;
}

// https://{domain}/api/tochka/beneficiaries
export const profileApi = createApi({
  reducerPath: 'ProfileApi',
  tagTypes: ['Profile', 'Tabs', 'Tab', 'UserInfo'],
  refetchOnReconnect: true,
  keepUnusedDataFor: 15,
  // refetchOnFocus: true,
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: `${process.env.REACT_APP_API_SERVER_TEST_EMPLOYEDS}`,
      headers: {
        'Content-Type': 'application/json',
        // Authorization: getAuthorizationHeader(),
        Authorization: localStorage.getItem('token')
          ? `Bearer ${localStorage.getItem('token')}`
          : '',
        Accept: 'application/json',
      },
    }),
    { maxRetries: 1 },
  ),
  endpoints: (build) => ({
    getProfile: build.query<ProfileTypes, void>({
      query: () => ({
        url: `/customer`,
        method: 'GET',
      }),
      providesTags: () => ['Profile'],
    }),
    //http://customer-test.skilla.ru/api/customer/tabs
    getTabs: build.query<TabsTypes[], void>({
      query: () => ({
        url: `/customer/tabs`,
        method: 'GET',
      }),
      providesTags: () => ['Tabs'],
      transformResponse: (response: any) => response?.items,
      // invalidatesTags: []
    }),
    getTab: build.query<any, any>({
      query: ({ title, params = {} }) => ({
        url: `/customer/tabs/${title}`,
        method: 'GET',
        params,
      }),
      providesTags: () => ['Tab'],
      // transformResponse: (response: any) => response?.items,
      // invalidatesTags: []
    }),
    signContact: build.mutation<any, any>({
      query: ({ body }) => ({
        url: `/customer/docs/oferta/sign`,
        method: 'POST',
        body,
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['Profile'],
      // transformResponse: (response: any) => response?.items,
      // invalidatesTags: []
    }),
    getSmsCode: build.query<any, void>({
      query: () => ({
        url: '/customer/docs/oferta/get-sms-code',
        method: 'GET',
      }),
      // providesTags: () => ['UserInfo'],
    }),
    getInfoUser: build.query<any, void>({
      keepUnusedDataFor: 0,
      query: () => ({
        url: 'user',
        method: 'GET',
      }),
      providesTags: () => ['UserInfo'],
    }),
  }),
});

export const {
  useGetTabsQuery,
  useGetTabQuery,
  useGetProfileQuery,
  useSignContactMutation,
  useGetInfoUserQuery,
  useLazyGetInfoUserQuery,
  useLazyGetSmsCodeQuery,
} = profileApi;
