// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { WhiteBlock } from 'components/WhiteBlock';
import { Title } from 'components/Title';
import { InputController } from 'ui/InputController';
import { ListSelect } from 'components/ListSelect';
import { fieldsArrF, fieldsArrIP, fieldsArrJur } from 'constants/stringConstants';
import { DocumentInfo } from 'components/BlocksContent/DocumentInfo';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import classNames from 'classnames';
import { InputMask } from 'components/InputMask';
import { PatternFormat } from 'react-number-format';
import { useCreateBeneficiarMutation } from 'redux/beneficiariesApi';
import { setInfoProfileCreate, setResetField } from 'redux/createProfile/slice';
import { AddressDadataReg } from 'components/AddressDadataReg';
import { useNavigate } from 'react-router-dom';
import { getDateFormatForBack } from 'utils/date';
import { ReactComponent as AccSvg } from './img/iconAccountCard.svg';
import styles from './BeneficiarCreate.module.scss';
import { searchSuggestingCompany } from 'api/getApi';

export const BeneficiarCreate = () => {
  const [suggestingValueArr, setSuggestingValueArr] = useState<any[]>([]);
  const navigate = useNavigate();
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm({
    mode: 'onBlur',
  });

  //Константы для вытягивания из формы определённых значений
  const innValue = watch('inn');
  const ogrnValue = watch('ogrn');
  //

  const dispatch = useDispatch();

  const [createBeneficiar, { data, isLoading, error, isSuccess }] =
    useCreateBeneficiarMutation();

  useEffect(() => {
    if (error?.data) {
      alert(error?.data?.message);
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      // console.log(isSuccess);
      navigate('/beneficiaries');
      dispatch(setResetField());
      reset();
    }
  }, [isSuccess]);

  const {
    doc_code,
    nationality,
    series,
    number,
    dep_code,
    doc_place,
    doc_date,
    birth_place,
    reg_address,
    jur_address,
    errorStatusField,
  } = useSelector((state: RootState) => state.createProfile);
  const lists = [
    { name: 'Юридическое лицо', key: 'J' },
    { name: 'ИП', key: 'I' },
    { name: 'Физическое лицо', key: 'F' },
  ];

  const [activeList, setActiveList] = useState(lists[0]);

  // console.log('errors', errors);
  // console.log('isValid', isValid);

  //Чистим форму и глобальное хранилище
  const handlerResetField = () => {
    dispatch(setResetField());
    reset();
  };

  const onSubmit = (data: any) => {
    if (activeList.key === 'J') {
      const {
        inn,
        kpp,
        phone,
        email,
        ogrn,
        jur_name,
        commission_min_amount,
        commission_percent,
        ...userObj
      } = data;

      // console.log('commission_percent', commission_percent);
      const body = {
        is_fake: false,
        customer: {
          legal_type: activeList.key,
          jur_address,
          phone,
          email,
          inn,
          jur_name,
          ogrn,
          kpp,
          commission_min_amount,
          commission_percent,
        },
        user: {
          ...userObj,
          phone,
          email,
        },
      };
      createBeneficiar({ body });
      // console.log(body);
    } else if (activeList.key === 'I') {
      const {
        password,
        kpp,
        inn,
        ogrn,
        commission_min_amount,
        commission_percent,
        ...obj
      } = data;

      const body = {
        customer: {
          legal_type: activeList.key,
          ogrn,
          kpp,
          inn,
          jur_address,
          commission_min_amount,
          commission_percent,
          ...obj,
        },
        user: { ...obj, password },
      };
      // console.log(body);
      createBeneficiar({ body });
    } else {
      const { dob, is_resident, inn, password, ...obj } = data;
      const body = {
        customer: {
          legal_type: activeList.key,
          birth_place,
          reg_address,
          dob: getDateFormatForBack(dob),
          is_resident,
          inn,
          ...obj,
        },
        user: {
          password,
          ...obj,
          // is_sign_right: 1,
        },
        doc: {
          doc_code,
          nationality,
          series,
          number,
          dep_code: dep_code ? dep_code?.slice(0, 3) + '-' + dep_code?.slice(3) : '',
          doc_place,
          doc_date: getDateFormatForBack(doc_date),
        },
      };
      // console.log(body);
      createBeneficiar({ body });
    }
  };

  //Проверка на заполненность полей в форме
  const checkErrorObj = useMemo(() => {
    const { jur_address, ...errorObj } = errorStatusField || {};
    for (const key in errorObj) {
      if (errorObj[key] === 1) {
        return errorObj[key];
      }
    }

    const docObj = {
      series,
      number,
      dep_code,
      doc_place,
      doc_date,
      birth_place,
      reg_address,
    };

    for (const key in docObj) {
      if (docObj[key] === '') {
        return 1;
      }
    }
  }, [series, number, dep_code, doc_place, doc_date, birth_place, reg_address]);

  const disabledJForm = !isValid || !jur_address.length;
  const disabledFForm = !isValid || !!checkErrorObj;

  // console.log('errors', errors);

  const refPhone = useRef();

  // useEffect(() => {
  //   if (refPhone?.current?.value) {
  //     refPhone?.current?.value = '';
  //   }
  // }, []);

  const setSuggestingValue = async () => {
    const res = await searchSuggestingCompany(innValue);
    setSuggestingValueArr(res);
  };

  //Функция для запроса данных для dadata
  useEffect(() => {
    if (innValue) {
      setSuggestingValue();
    }
  }, [innValue]);

  // функция для атоматического подставления данных в форму при выборе организации из списка Dadata
  const handleSelectItem = (elem) => {
    // console.log('elem', elem?.data);
    setValue('kpp', elem?.data?.kpp);
    setValue('ogrn', elem?.data?.ogrn);
    setValue('jur_name', elem?.value);
    dispatch(
      setInfoProfileCreate({ key: 'jur_address', value: elem?.data?.address?.value }),
    );
    setSuggestingValueArr([]);
  };

  return (
    <WhiteBlock className={styles.root}>
      <div onClick={() => setValue('inn', '12312323')} className={styles.root_title}>
        <AccSvg />
        <Title title='Карточка компании' />
      </div>
      <ListSelect
        handlerResetField={handlerResetField}
        placeholder='Тип'
        list={lists}
        setActiveItem={setActiveList}
        activeItem={activeList.name}
      />
      <form
        autoComplete='off'
        style={activeList.key === 'F' ? { rowGap: 20 } : {}}
        onSubmit={handleSubmit(onSubmit)}>
        {(activeList.key === 'J'
          ? fieldsArrJur
          : activeList.key === 'I'
          ? fieldsArrIP
          : fieldsArrF
        ).map((elem, i) =>
          elem.items ? (
            <div key={i} style={{ display: 'flex', columnGap: 12 }}>
              {elem.items.map((item, ind) => (
                <div key={ind} className={styles.rootInput}>
                  <input
                    autoComplete={item.placeholder || false}
                    step='any'
                    placeholder={item.placeholder}
                    className={styles.input}
                    type={item.type}
                    {...register(`${item.name}`, {
                      required: item.required,
                      minLength: {
                        value: item.minLength,
                        message: item.minLengthMessage,
                      },
                      maxLength: {
                        value: item.minLength,
                        message: item.maxLengthMessage,
                      },
                    })}
                    maxLength={+item.minLength}
                  />
                  <span className={styles.rootInput_placeholder}>{item.placeholder}</span>
                  {errors[item.name] && (
                    <p className={styles.errors}>
                      {errors[item.name]?.message || 'Error'}
                    </p>
                  )}
                </div>
              ))}
            </div>
          ) : elem.name === 'phone' ? (
            <div key={i} style={{ position: 'relative' }}>
              <Controller
                control={control}
                name={elem.name}
                rules={{
                  required: elem.required,
                  minLength: {
                    value: elem.minLength,
                    message: elem?.minLengthMessage,
                  },
                }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <PatternFormat
                    getInputRef={refPhone}
                    style={elem.width ? { width: '378px' } : {}}
                    className={styles.input}
                    format='+# (###) ###-##-##'
                    placeholder='+7 (___) ___-__-__'
                    onBlur={onBlur}
                    onValueChange={(values) => {
                      onChange(values.value);
                    }}
                    value={value}
                  />
                )}
              />
              <span className={styles.rootInput_placeholder}>{elem.placeholder}</span>
              {errors[elem.name] && (
                <p className={styles.errors}>{errors[elem.name].message}</p>
              )}
            </div>
          ) : elem.name === 'jur_address' ? (
            <div key={i}>
              <AddressDadataReg
                placeholder='Юридический адрес компании'
                objKey='jur_address'
              />
              {errorStatusField.jur_address ? (
                <p className={styles.errors}>Поле обязательно</p>
              ) : null}
            </div>
          ) : (
            <div key={i} className={styles.rootInput}>
              <input
                autoComplete={
                  elem.type === 'text' ? 'no-autofill-please' : 'new-password'
                }
                style={
                  elem.width
                    ? { width: '378px' }
                    : elem.name === 'dob'
                    ? { width: '200px' }
                    : {}
                }
                placeholder={elem.placeholder}
                className={styles.input}
                type={elem.type}
                {...register(`${elem.name}`, {
                  required: elem.required,
                  minLength: {
                    value: elem.minLength,
                    message: elem.minLengthMessage,
                  },
                  maxLength: {
                    value: elem.maxLength,
                    message: elem.maxLengthMessage,
                  },
                  pattern: elem.pattern
                    ? { value: elem.pattern, message: elem.patternMessage }
                    : '',
                })}
              />
              <span
                className={styles.rootInput_placeholder}
                // style={isDisabled ? { background: 'transparent' } : {}}
                // className={classNamePlaceholder}
                // ref={placeholderRef}
              >
                {elem.placeholder}
              </span>
              {errors[elem.name] && (
                <p className={styles.errors}>{errors[elem.name]?.message || 'Error'}</p>
              )}
            </div>
          ),
        )}
        {activeList.key === 'F' && (
          <>
            <div className={styles.wrapper}>
              <div className={classNames(styles.switch_box, styles.box_1)}>
                <input
                  type='checkbox'
                  className={styles.switch_1}
                  {...register('is_resident')}
                />
              </div>
              <p>Не является налоговым резидентом РФ</p>
            </div>
            <Title title='Документ' />
            <DocumentInfo />
          </>
        )}
        <input
          type='submit'
          disabled={
            activeList.key === 'J'
              ? disabledJForm
              : activeList.key === 'F'
              ? disabledFForm
              : !isValid
          }
        />
      </form>
      {suggestingValueArr.length ? (
        <ul className={styles.list}>
          {suggestingValueArr?.map((elem: any, index: number) => (
            <li
              className={`${styles.item}
                     ${0 === index ? styles.item_active : ''}
                    `}
              onClick={() => {
                handleSelectItem(elem);
              }}
              key={index}>
              {elem.value}
            </li>
          ))}
        </ul>
      ) : null}
    </WhiteBlock>
  );
};
