import { NotFound } from 'pages/NotFound';
import { Navigate } from 'react-router-dom';
import { ReactNode, Suspense } from 'react';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { BeneficiariesContent } from 'pages/BeneficiariesContent';
import { RequireAuth } from 'features/RequireAuth/RequireAuth';
import { BeneficiariesDetails } from 'pages/BeneficiariesDetails';
import { EmployedsContent } from 'pages/EmployedsContent';
import { EmployedsDetails } from 'pages/EmployedsDetails';
import { OperationsContent } from 'pages/OperationsContent';
import { OperationsDealDetails } from 'pages/OperationsDealDetails';
import { OperationsPaymentDetails } from 'pages/OperationsPaymentDetails';
import { TaxeDetails } from 'pages/TaxeDetails';
import { OrdersContent } from 'pages/OrdersContent';
import { OrdersDetails } from 'pages/OrdersDetails';
import { CreateOrder } from 'pages/CreateOrder';
import { Profile } from 'pages/Profile';
import { Layout } from 'features/Layouts/Layouts';
import { Taxes } from 'pages/Taxes';
import { BeneficiarCreate } from 'pages/BeneficiarCreate';
import { Notifications } from 'pages/Notifications';
import { Login } from 'pages/Login/Login';
import { EmployedsWorkSupportContent } from 'pages/EmployedsWorkSupportContent';
import { PerformersContent } from 'pages/PerformersContent';

// eslint-disable-next-line react/no-children-prop
const withAuth = (jsx: ReactNode) => <RequireAuth children={jsx} />;

export const routes = [
  { path: '/', element: <Navigate to='login' replace /> },
  {
    path: '/login',
    element: (
      <Suspense fallback={<LoadingSkeleton />}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: '/orders',
    element: withAuth(
      <Suspense fallback={<LoadingSkeleton />}>
        <OrdersContent />
      </Suspense>,
    ),
  },
  {
    path: '/orders/create',
    element: withAuth(
      <Suspense fallback={<LoadingSkeleton />}>
        <CreateOrder />
      </Suspense>,
    ),
  },
  {
    path: '/orders/:id',
    element: withAuth(
      <Suspense fallback={<LoadingSkeleton />}>
        <OrdersDetails />
      </Suspense>,
    ),
  },
  {
    path: '/beneficiaries',
    element: withAuth(
      <Suspense fallback={<LoadingSkeleton />}>
        <BeneficiariesContent />
      </Suspense>,
    ),
  },
  {
    path: '/beneficiaries/:id',
    element: withAuth(
      <Suspense fallback={<LoadingSkeleton />}>
        <BeneficiariesDetails />
      </Suspense>,
    ),
  },
  {
    path: '/employeds',
    element: withAuth(
      <Suspense fallback={<LoadingSkeleton />}>
        <EmployedsContent />
      </Suspense>,
    ),
  },
  {
    path: '/employedsWorkSupport',
    element: withAuth(
      <Suspense fallback={<LoadingSkeleton />}>
        <EmployedsWorkSupportContent />
      </Suspense>,
    ),
  },
  {
    path: '/performersWorkSupport',
    element: withAuth(
      <Suspense fallback={<LoadingSkeleton />}>
        <PerformersContent />
      </Suspense>,
    ),
  },
  {
    path: '/employedsWorkSupport/:id',
    element: withAuth(
      <Suspense fallback={<LoadingSkeleton />}>
        <EmployedsDetails />
      </Suspense>,
    ),
  },
  {
    path: '/operations',
    element: withAuth(
      <Suspense fallback={<LoadingSkeleton />}>
        <OperationsContent />
      </Suspense>,
    ),
  },
  {
    path: '/operations/deals/:id',
    element: withAuth(
      <Suspense fallback={<LoadingSkeleton />}>
        <OperationsDealDetails />
      </Suspense>,
    ),
  },
  {
    path: '/operations/payments/:id',
    element: withAuth(
      <Suspense fallback={<LoadingSkeleton />}>
        <OperationsPaymentDetails />
      </Suspense>,
    ),
  },
  {
    path: '/operations/taxes/:id',
    element: withAuth(
      <Suspense fallback={<LoadingSkeleton />}>
        <Layout>
          <TaxeDetails />
        </Layout>
      </Suspense>,
    ),
  },
  {
    path: '/taxes',
    element: withAuth(
      <Suspense fallback={<LoadingSkeleton />}>
        <Layout>
          <Taxes />
        </Layout>
      </Suspense>,
    ),
  },
  {
    path: '/taxes/:id',
    element: withAuth(
      <Suspense fallback={<LoadingSkeleton />}>
        <Layout>
          <TaxeDetails />
        </Layout>
      </Suspense>,
    ),
  },
  {
    path: '/profile',
    element: withAuth(
      <Suspense fallback={<LoadingSkeleton />}>
        <Layout>
          <Profile />
        </Layout>
      </Suspense>,
    ),
  },
  {
    path: '/beneficiar/create',
    element: withAuth(
      <Suspense fallback={<LoadingSkeleton />}>
        <Layout>
          <BeneficiarCreate />
        </Layout>
      </Suspense>,
    ),
  },
  {
    path: '/notifications',
    element: withAuth(
      <Suspense fallback={<LoadingSkeleton />}>
        <Layout>
          <Notifications />
        </Layout>
      </Suspense>,
    ),
  },
  { path: '/*', element: <NotFound /> },
];
