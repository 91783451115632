import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from 'features/Layouts/Layouts';
import { InputText } from 'ui/InputText';
import { setInfoBeneficiar } from 'redux/beneficiarDetail/slice';
import { TabBeneficiaries } from 'components/TabBeneficiaries';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { ReactComponent as DocSvg } from './img/icon_document.svg';
import styles from './OperationsPaymentDetails.module.scss';
import { useGetOperationsDetailsPaymentsQuery } from 'redux/operationsApi';
import { DealDetailsItemRight } from 'components/DealDetailsItemRight';

export const OperationsPaymentDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetOperationsDetailsPaymentsQuery({
    name: `payments/${id}`,
  });

  return (
    <Layout>
      {isLoading ? (
        <LoadingSkeleton mt />
      ) : (
        data && (
          <div className={styles.root}>
            <DealDetailsItemRight data={data} />
          </div>
        )
      )}
    </Layout>
  );
};
