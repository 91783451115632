import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Layout } from 'features/Layouts/Layouts';
import { MainTitle } from 'components/MainTitle';
import { InputSearch } from 'ui/InputSearch';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { OrdersList } from 'components/OrdersList';
import { TabMenuFilter } from 'components/TabMenuFilter';
// import { SubHeader } from 'components/SubHeader';
import { BeneficiariesList } from 'components/BeneficiariesList';
import { DateFilter } from 'components/DateFilter';
import { useGetOrdersQuery } from 'redux/ordersApi';
import { resetOrderInfo } from 'redux/ordersDetails/slice';
import avatar from 'assets/img/navSvg/avatar.png';
import { ReactComponent as FiltersSvg } from 'assets/img/filters.svg';
import { ReactComponent as CalendarSvg } from 'assets/img/filter/calendar.svg';
import styles from './OrdersContent.module.scss';
import { useFilterOrders } from 'hooks/useFilterOrders';
import { useSearchOrders } from 'hooks/useSearchOrders';
// interface ActiveTypes {
// key: string
// filter
// }

export const OrdersContent: FC = () => {
  const [active, setActive] = useState<any>({
    name: 'Все активные',
    key: 'Все активные',
    // filter: null,
  });

  const { data, isLoading } = useGetOrdersQuery({});
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [itemsArr, setItemsArr] = useState([]);

  const filtredOrders = useFilterOrders(data?.items, active);
  const filtredSearchOrders = useSearchOrders(filtredOrders, searchValue);

  useEffect(() => {
    //очистка состояния деталей заказа
    dispatch(resetOrderInfo());
  }, []);

  const role = localStorage.getItem('role');

  // console.log('role', role);

  const lists = [
    { name: 'Все активные', key: 'Все активные' },
    { name: 'Требуют действия', key: 'Требуют действия' },
    { name: 'Требуют действия исполнителя', key: 'Требуют действия исполнителя' },
    { name: 'Завершенные', key: 'Завершенные' },
    { name: 'Черновики', key: 'Черновики' },
  ];

  const listsWorkSupport = [
    { name: 'Все активные', key: 'Все активные' },
    { name: 'Требуют действия', key: 'Требуют действия' },
    { name: 'Требуют действия исполнителя', key: 'Требуют действия исполнителя' },
    { name: 'Завершенные', key: 'Завершенные' },
    { name: 'Черновики', key: 'Черновики' },
    { name: 'Все', key: 'Все' },
  ];

  return (
    <Layout>
      <div className={styles.root}>
        <MainTitle title='Заказы' count={filtredSearchOrders?.length || 0} />
        <div className={styles.root_header}>
          <div className={styles.root_headerItem}>
            <InputSearch
              notFocus
              placeholder='Искать'
              value={searchValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                // dispatch(setSearchValue(e.target.value))
                setSearchValue(e.target.value)
              }
            />
            <TabMenuFilter
              orders
              count
              lists={role === 'director' ? lists : listsWorkSupport}
              active={localStorage.getItem('ordersFilter') || active.key}
              setActive={setActive}
            />
          </div>
          {/* <div className={styles.root_btn}>
            <div className={styles.filters}>
              <FiltersSvg />
              Фильтры
            </div>
            <DateFilter />
          </div> */}
        </div>
        {/* <SubHeader create /> */}
        {isLoading ? <LoadingSkeleton /> : <OrdersList data={filtredSearchOrders} />}
      </div>
    </Layout>
  );
};
