import { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './TextArea.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setWasChanges } from 'redux/mainInfo/slice';

const cx = classNames.bind(styles);

type TextAreaProps = {
  placeholder?: string;
  value: string;
  onChange?: any;
  props?: any;
  classNameFocus?: boolean;
  error?: boolean;
  nameError?: string;
  style?: any;
  orderFocus?: boolean;
};

export const TextArea: FC<TextAreaProps> = ({
  placeholder,
  value,
  // setValue,
  onChange,
  props,
  classNameFocus,
  error,
  nameError,
  style,
  orderFocus,
}) => {
  const inputRef = useRef<any>(null);
  const placeholderRef = useRef<any>(null);
  const [focus, setFocus] = useState<boolean>(false);
  const dispatch = useDispatch();

  const classNameInput = cx({
    input: true,
    focus: focus || classNameFocus || orderFocus,
    errorInput: error,
  });

  const classNamePlaceholder = cx({
    placeholder: true,
    transition: !value,
    errorPlaceholder: error,
  });

  const focusInput = () => {
    setFocus(true);
  };

  const blurInput = () => {
    if (!inputRef.current.value) {
      setFocus(false);
    }
  };

  useEffect(() => {
    if (inputRef.current.value || value) setFocus(true);
  }, [value]);

  return (
    <div className={styles.root}>
      <textarea
        style={style}
        className={classNameInput}
        ref={inputRef}
        // onChange={changeInput}
        onChange={onChange}
        onFocus={focusInput}
        autoComplete='disabled'
        onBlur={blurInput}
        value={value}
        {...props}
      />
      <span className={classNamePlaceholder} ref={placeholderRef}>
        {placeholder}
      </span>
    </div>
  );
};
