import { FC, useEffect, useState, useRef } from 'react';
import { AddressSuggestions, DaDataSuggestion, DaDataAddress } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';
import './react-address-dadata.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { setInfoOrderDetail } from 'redux/ordersDetails/slice';

export const AddressDadata: FC = () => {
  // const { infoOrderDetail } = useSelector((state: RootState) => state.ordersDetailsSlice);
  // const { city, apartment, street, home } = infoOrderDetail;

  const [value, setValue] = useState<any>();
  const [defaultValue, setDefaultValue] = useState<any>();
  const suggestionsRef = useRef<AddressSuggestions>(null);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (suggestionsRef.current) {
  //     console.log('first');
  // dispatch(setCoordinates())
  // suggestionsRef.current.setInputValue(
  // `${addressCity !== '' && addressCity !== null ? `г. ${addressCity},` : ''} ${
  //   addressStreet !== '' && addressStreet !== null ? `${addressStreet},` : ''
  // }${addresHome !== '' && addresHome !== null ? ' д ' : ''}${
  //   addresHome !== null ? addresHome : ''
  // }${addresK !== '' && addresK !== null ? ' к ' : ''}${
  //   addresK !== null ? addresK : ''
  // }${addressA !== '' && addressA !== null ? ' кв ' : ''}${
  //   addressA !== null ? addressA : ''
  // }`.trimStart(),
  // );
  // }
  // }, [city, apartment, street, home]);

  useEffect(() => {
    if (value) {
      // dispatch(setCoordinates({ lat: value?.data.geo_lat, lng: value?.data.geo_lon }));
      // dispatch(setWasChanges(true));
      value?.data.flat !== null
        ? dispatch(setInfoOrderDetail({ key: 'apartment', value: value.data.flat }))
        : dispatch(setInfoOrderDetail({ key: 'apartment', value: '' }));
      value?.data.city !== null
        ? dispatch(setInfoOrderDetail({ key: 'city', value: value.data.city }))
        : dispatch(setInfoOrderDetail({ key: 'city', value: '' }));
      value?.data.street_with_type !== null
        ? dispatch(
            setInfoOrderDetail({ key: 'street', value: value.data.street_with_type }),
          )
        : dispatch(setInfoOrderDetail({ key: 'street', value: '' }));
      value?.data.house !== null
        ? dispatch(setInfoOrderDetail({ key: 'home', value: value.data.house }))
        : dispatch(setInfoOrderDetail({ key: 'home', value: '' }));
    }
    if (defaultValue === '' && suggestionsRef.current) {
      // dispatch(setWasChanges(true));
      // dispatch(setCoordinates({ lat: '', lng: '' }));

      suggestionsRef.current.setInputValue('');
      setValue('');
      dispatch(setInfoOrderDetail({ key: 'apartment', value: '' }));
      dispatch(setInfoOrderDetail({ key: 'city', value: '' }));
      dispatch(setInfoOrderDetail({ key: 'street', value: '' }));
      dispatch(setInfoOrderDetail({ key: 'home', value: '' }));
    }
  }, [value, defaultValue]);

  return (
    <div style={{ position: 'relative' }}>
      <AddressSuggestions
        ref={suggestionsRef}
        token={`${process.env.REACT_APP_API_KEY}`}
        value={value}
        onChange={setValue}
        defaultQuery={''}
        selectOnBlur={true}
        count={5}
        highlightClassName='suggestion_highlight'
        currentSuggestionClassName='suggestion_select'
        inputProps={{
          placeholder: 'Начните вводить адрес',
          onChange: (e: any) => {
            if (e) setDefaultValue(e.target.value.trimStart());
          },
        }}
      />
      <span className='placeholder'>Адрес</span>
    </div>
  );
};
