import React, { FC } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import { ReactComponent as IconDone } from './img/iconDone.svg';
import { ReactComponent as IconError } from 'assets/img/error.svg';
import { getFotmattedDateCreated, getShortDate } from 'utils/date';
import { addSpaceNumber } from 'utils/addSpaceNumber';
import { BeneficiariesTabBtn } from 'ui/BeneficiariesTabBtn';
import styles from './DealsItem.module.scss';

interface DealsItemTypes {
  deal: any;
}

export const DealsItem: FC<DealsItemTypes> = ({ deal }) => {
  // console.log('deal', deal);
  return (
    <div className={styles.root}>
      <p style={{ padding: '0 16px' }} className={styles.root_title}>
        {deal.id}
      </p>
      <p style={{ padding: '0 16px', textAlign: 'end' }} className={styles.root_title}>
        {deal.total_amount ? addSpaceNumber(deal.total_amount) : '-'}
      </p>
      <p style={{ padding: '0 16px' }}>
        {deal.compliance ? <IconDone /> : deal.compliance === false ? <IconError /> : ''}
      </p>
      <div>
        <BeneficiariesTabBtn
          oldStyle
          classNameStyles={styles.statusBlock}
          bg={deal.status?.color_bg}
          color={deal.status?.color_text}
          // isActive={false}
          title={deal.status.text}
        />
        {deal.tochka_created_at && (
          <p className={styles.root_subTitle}>
            {getFotmattedDateCreated(deal.tochka_created_at)}. назад
          </p>
        )}
      </div>
    </div>
  );
};
