import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Payments } from './Payments';
import { Taxes } from './Taxes';
import { TabMenuFilter } from 'components/TabMenuFilter';
import { Deals } from './Deals';
import { InputSearch } from 'ui/InputSearch';
import { setSearchValue } from 'redux/operationInfo/slice';
import { ReactComponent as FiltersSvg } from 'assets/img/filters.svg';
import styles from './TabOperations.module.scss';

// import { ModalDetails } from 'components/ModalDetails';

export const TabOperations: FC = () => {
  const [localActive, setActive] = useState<string>(
    localStorage.getItem('operationsFilter') || 'deals',
  );
  const { searchValue } = useSelector((state: RootState) => state.operationInfoSlice);
  const dispatch = useDispatch();
  // const localActive = localStorage.getItem('operationsFilter') || active;

  // useEffect(() => {
  //   setActive(isWorkerUrl ? 'work' : 'docs');
  // }, [isWorkerUrl]);

  const content: Record<string, React.ReactNode> = {
    deals: <Deals title={localActive} />,
    payments: <Payments title={localActive} />,
    taxes: <Taxes title={localActive} />,
  };

  return (
    <div className={styles.root}>
      <div className={styles.root_header}>
        <div className={styles.root_headerItem}>
          {/* <InputSearch
            style={{ width: '280px' }}
            value={searchValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(setSearchValue(e.target.value))
            }
          /> */}
          <TabMenuFilter operations active={localActive} setActive={setActive} />
        </div>
        {/* <div className={styles.filters}>
          <FiltersSvg />
          Фильтры
        </div> */}
        {/* <button>Фильтры</button> */}
      </div>

      <div className={styles.content}>{content[localActive]}</div>
    </div>
  );
};
