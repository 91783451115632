export const getFormatPhone = (tel: any) => {
  if (isNaN(tel)) return tel;
  if (!tel) return '';

  // if (tel.length === 10) {
  //   return `+7 ${tel.substring(0, 3)} ${tel.substring(3, 6)}-${tel.substring(6, 8)}-${tel.substring(
  //     8,
  //     10
  //   )}`;
  // }

  if (tel.length === 11) {
    if (tel[0] === '8')
      return `+7 ${tel.substring(1, 4)} ${tel.substring(4, 7)}-${tel.substring(
        7,
        9
      )}-${tel.substring(9, 11)}`;

    if (tel[0] === '7')
      return `+7 ${tel.substring(1, 4)} ${tel.substring(4, 7)}-${tel.substring(
        7,
        9
      )}-${tel.substring(9, 11)}`;
  }
};
