import { FC, useEffect, useState } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './BeneficiariesList.module.scss';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { RootState } from 'redux/store';
import { TitleRow } from 'components/TitleRow';
import { BeneficiarItem } from 'ui/BeneficiarItem';
import { Link } from 'react-router-dom';
import { ItemTypes } from 'redux/beneficiariesApi';
interface GroupsListTypes {
  data: ItemTypes[];
}

export const BeneficiariesList: FC<GroupsListTypes> = ({ data }) => {
  // console.log('data', data);
  const [parent] = useAutoAnimate({ duration: 300 });
  const pageHeight = document.documentElement.scrollHeight;

  const dataTitle = [
    'id',
    'Статус',
    'Наименование',
    'Виртуальные счета',
    'Баланс',
    'Дата обновления',
  ];

  // console.log('data', data);
  return (
    // <div className={styles.root_nothing}>
    //   <p>Ничего не найдено</p>
    // </div>

    <div ref={parent} className={styles.root}>
      <TitleRow data={dataTitle} title='beneficiaries' />
      {data?.length ? (
        data?.map((item: ItemTypes) => (
          <Link key={item.id} to={`/beneficiaries/${item.id}`}>
            <BeneficiarItem group={item} />
          </Link>
        ))
      ) : (
        <div className={styles.root_nothing}>
          <p>Ничего не найдено</p>
        </div>
      )}
    </div>
  );
};
