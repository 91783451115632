import React, { FC } from 'react';
import { currentDay, getFotmattedDateCreated } from 'utils/date';
import { ReactComponent as IconSvg } from './img/icon.svg';
import styles from './TaxesEmployedsItem.module.scss';
import { addSpaceNumber } from 'utils/addSpaceNumber';
import { BeneficiariesTabBtn } from 'ui/BeneficiariesTabBtn';

interface TaxesEmployedsItemTypes {
  taxe: any;
}

export const TaxesEmployedsItem: FC<TaxesEmployedsItemTypes> = ({ taxe }) => {
  // console.log('TaxesEmployeds', taxe);
  return (
    <div className={styles.root}>
      <p>{taxe.id}</p>
      <p style={{ textAlign: 'end' }}>{addSpaceNumber(taxe.amount)}</p>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p className={styles.root_text}>{taxe.beneficiary?.customer?.name}</p>
        <p className={styles.root_subText}>{taxe.beneficiary?.tochka_uuid}</p>
      </div>
      <p style={{ textAlign: 'end' }}>{addSpaceNumber(14)}</p>
      <p style={{ textAlign: 'end' }}>{addSpaceNumber(200)}</p>
      <div className={styles.iconTitle}>
        <div style={{ padding: '0 16px' }}>
          <BeneficiariesTabBtn
            bg={taxe.status?.color_bg}
            color={taxe.status?.color_text}
            title={taxe.status.text}
          />
        </div>
        <p className={styles.root_subText}>
          <IconSvg /> {getFotmattedDateCreated(taxe.tochka_updated_at)}. назад
        </p>
      </div>
    </div>
  );
};
