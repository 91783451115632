import React, { FC } from 'react';
import styles from './InfoProgressPrice.module.scss';
import classNames from 'classnames';
import { addSpaceNumber } from 'utils/addSpaceNumber';

interface buttonType {
  orders: string;
  taxes: string;
  disabled: string;
}

const themes: buttonType = {
  orders: styles.orders,
  taxes: styles.taxes,
  disabled: styles.dis,
};

interface InfoProgressPriceTypes {
  color?: keyof typeof themes;
  price: number;
  text: string;
}

export const InfoProgressPrice: FC<InfoProgressPriceTypes> = ({
  color,
  text,
  price = 0,
}) => {
  return (
    <div className={styles.root}>
      <div className={classNames(styles.root_range, color ? themes[color] : null)} />
      <div className={styles.root_blockText}>
        <span>{text}</span>
        <p>{addSpaceNumber(price)} ₽</p>
      </div>
    </div>
  );
};
