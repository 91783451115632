import React, { useEffect, useState } from 'react';
import { Title } from 'components/Title';
import { WhiteBlock } from 'components/WhiteBlock';
import { DocumentInfo } from 'components/DocumentInfo';
import { InformationOrderBlock } from 'components/InformationOrderBlock';
import { InfoBlock } from 'ui/InfoBlock';
import { Button } from 'ui/Button';
import classNames from 'classnames';
import { PriceItem } from 'ui/PriceItem';
import { TabProfile } from 'components/TabProfile';
import { ModalProfile } from 'components/ModalProfile';
import { ModalContract } from 'components/ModalContract';
import {
  useGetInfoUserQuery,
  useGetProfileQuery,
  useGetTabsQuery,
} from 'redux/ProfileApi';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { Nothing } from 'components/Nothing';
import { addSpaceNumber } from 'utils/addSpaceNumber';
import { ReactComponent as DocSvg } from './img/iconDocument.svg';
import { ReactComponent as AccSvg } from './img/iconAccountCard.svg';
import { ReactComponent as WalSvg } from './img/iconWallet.svg';
import { ReactComponent as ArrowSvg } from 'assets/img/arrowOpenBlock.svg';
import styles from './Profile.module.scss';

export const Profile = () => {
  const { data: tabsData, isLoading: tabsLoading, error: tabsError } = useGetTabsQuery();
  const { data, isLoading, error } = useGetProfileQuery();
  const [showModalProfile, setShowModalProfile] = useState(false);
  const [showModalContract, setShowModalContract] = useState(false);
  const [openContent, setOpenContent] = useState(false);
  const [timer, setTimer] = useState(60);
  const [startTimer, setStartTimer] = useState(false);

  useEffect(() => {
    if (startTimer) {
      const timeout = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [startTimer]);

  useEffect(() => {
    if (timer === 0) {
      setStartTimer(false);
      setTimer(60);
    }
  }, [timer]);

  if (tabsLoading || isLoading || error || tabsError) {
    return (
      <div className={styles.root}>
        {error || tabsError ? <Nothing /> : <LoadingSkeleton mt />}
      </div>
    );
  }

  const arrPayment = [
    {
      sum: data?.beneficiary?.virtual_account?.main_account_cash,
      type: 'standard',
    },
    // {
    //   sum: data?.beneficiary?.virtual_account?.taxes_account_cash,
    //   type: 'standard2',
    // },
  ];

  const Balance = () => {
    return (
      <>
        <div className={styles.root_info_title}>
          <WalSvg />
          <Title
            title={`Лицевой счет № ${data?.beneficiary?.virtual_account?.uuid || ''}`}
          />
        </div>
        <div
          className={classNames(
            styles.root_price,
            openContent ? styles.root_price_open : null,
          )}>
          <div
            onClick={() => setOpenContent(!openContent)}
            className={classNames(
              styles.root_info_blockPrice,
              openContent ? styles.root_info_blockPrice_open : null,
            )}>
            <div>
              <p>Текущий баланс</p>
              <b>
                {data?.beneficiary?.virtual_account?.total_cash
                  ? addSpaceNumber(data?.beneficiary?.virtual_account?.total_cash)
                  : '0'}{' '}
                ₽
              </b>
            </div>
            <ArrowSvg />
          </div>
          <div className={styles.cashBlock}>
            {arrPayment.map((elem, i) => (
              <PriceItem
                hold={data?.beneficiary?.virtual_account?.main_account_hold}
                key={i}
                type={elem.type}
                cash={elem?.sum}
              />
            ))}
          </div>
        </div>
        {!data?.beneficiary?.document?.is_added_to_bank && (
          <InfoBlock title='Пополнение счета станет доступно после проверки договора банком' />
        )}
        <Button
          className={styles.btn}
          theme={!data?.beneficiary?.document?.is_added_to_bank ? 'disabled' : 'info'}
          onClick={() => {
            if (data?.beneficiary?.document?.is_added_to_bank) {
              setShowModalProfile(true);
            }
          }}>
          Пополнить
        </Button>
      </>
    );
  };

  return (
    <div className={styles.root}>
      <Title className={styles.root_title} title='Профиль' />
      <div className={styles.root_info}>
        <div className={styles.root_info_left}>
          {data?.beneficiary && (
            <WhiteBlock className={styles.root_info_left_block}>
              {data?.beneficiary?.document?.is_signed ? (
                <Balance />
              ) : (
                <>
                  <div className={styles.root_info_title}>
                    <DocSvg />
                    <Title title='Документы' />
                  </div>
                  <div>
                    <DocumentInfo
                      href={
                        data?.beneficiary?.document?.file ||
                        'https://api.go.skilla.ru/storage/oferta_skilla.pdf'
                      }
                      number={data?.beneficiary?.document?.name || 'Оферта'}
                      date={data?.beneficiary?.document?.date}
                    />
                  </div>
                  <Button
                    onClick={() => {
                      setStartTimer(true);
                      setShowModalContract(true);
                    }}
                    theme='outline'>
                    Подписать договор
                  </Button>
                </>
              )}
            </WhiteBlock>
          )}
          <WhiteBlock className={styles.root_info_left_block}>
            <div className={styles.root_info_title}>
              <AccSvg />
              <Title title='Карточка компании' />
            </div>
            <InformationOrderBlock objectInfo={data?.customer} />

            {data?.beneficiary?.document?.is_signed && (
              <DocumentInfo
                href={data?.beneficiary?.document?.file}
                // loading={downloadLoading}
                // onClick={() => console.log('first')}
                number={data?.beneficiary?.document?.name}
                date={data?.beneficiary?.document?.date}
              />
            )}
          </WhiteBlock>
          {!data?.beneficiary?.document?.is_signed && (
            <WhiteBlock className={styles.root_info_left_block}>
              <Balance />
            </WhiteBlock>
          )}
        </div>
        <div className={styles.root_info_right}>
          <WhiteBlock className={styles.padding}>
            {tabsData && <TabProfile tabsData={tabsData} />}
          </WhiteBlock>
        </div>
      </div>
      {showModalProfile && (
        <ModalProfile
          showModalProfile={showModalProfile}
          title='ООО “Рога и Копыта”'
          ls='123456789'
          setShowModalProfile={setShowModalProfile}
          avatar=''
          sum='123'
        />
      )}
      {showModalContract && (
        <ModalContract
          startTimer={startTimer}
          setStartTimer={setStartTimer}
          timer={timer}
          setShowModalContract={setShowModalContract}
        />
      )}
    </div>
  );
};
