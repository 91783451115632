import { FC, useEffect, useMemo, useState } from 'react';
import { Layout } from 'features/Layouts/Layouts';
import { MainTitle } from 'components/MainTitle';
// import { SubHeader } from 'components/SubHeader';
import { EmployedsList } from 'components/EmployedsList';
import { InputSearch } from 'ui/InputSearch';
import { useGetEmployedsWorkSupportQuery } from 'redux/employedsWorkSupportApi';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { TabMenuFilter } from 'components/TabMenuFilter';
import { EmployedsWorkSupportList } from 'components/EmployedsWorkSupportList';
import { Button } from 'ui/Button';
import plus from 'assets/img/plus.svg';
import styles from './EmployedsContent.module.scss';

export const EmployedsWorkSupportContent: FC = () => {
  // const role = localStorage.getItem('role') || 'WorkSupport';
  const [searchValue, setSearchValue] = useState('');
  const [focus, setFocus] = useState(false);

  const [page, setPage] = useState(1);
  const [fetching, setFetching] = useState(true);

  const { data, isLoading, isFetching } = useGetEmployedsWorkSupportQuery();

  useEffect(() => {
    const scrollHandler = (e: any) => {
      if (
        // e?.target?.documentElement.scrollHeight -
        //   (e.target.documentElement.scrollTop + window.innerHeight) <
        // 100
        e.target.scrollHeight - (e.target.scrollTop + window.innerHeight) <
        100
      ) {
        // console.log('object');
        // setFetching(true);
      }
    };

    document.addEventListener('scroll', scrollHandler, { capture: true });
    return () => document.removeEventListener('scroll', scrollHandler);
  }, []);

  const filtredArr = data?.items?.filter(
    (elem: any) =>
      elem?.name
        ?.replaceAll(' ', '')
        ?.toLowerCase()
        ?.includes(searchValue?.replaceAll(' ', '').toLowerCase()) ||
      elem?.surname
        ?.replaceAll(' ', '')
        ?.toLowerCase()
        ?.includes(searchValue?.replaceAll(' ', '').toLowerCase()) ||
      elem?.phone
        ?.replaceAll(' ', '')
        ?.toLowerCase()
        ?.includes(searchValue?.replaceAll(' ', '').toLowerCase()) ||
      elem?.inn
        ?.replaceAll(' ', '')
        ?.toLowerCase()
        ?.includes(searchValue?.replaceAll(' ', '').toLowerCase()),
  );

  return (
    <Layout>
      <div className={styles.root}>
        <MainTitle title='Самозанятые' count={data?.count} />
        <div className={styles.root_search}>
          <div className={styles.root_search_item}>
            <InputSearch
              notFocus
              placeholder='Искать'
              value={searchValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchValue(e.target.value)
              }
            />
          </div>
        </div>
        {isLoading ? (
          // isLoading || isLoadingTabs
          // || isFetching
          <LoadingSkeleton />
        ) : (
          <EmployedsWorkSupportList data={filtredArr} />
        )}
      </div>
    </Layout>
  );
};
