import React, { FC } from 'react';
import { getFotmattedDateCreated } from 'utils/date';
import styles from './BeneficiarItem.module.scss';
import { BeneficiariesTabBtn } from 'ui/BeneficiariesTabBtn';
import { addSpaceNumber } from 'utils/addSpaceNumber';
import { ItemTypes } from 'redux/beneficiariesApi';

interface BeneficiarItemTypes {
  group: ItemTypes;
}

export const BeneficiarItem: FC<BeneficiarItemTypes> = ({ group }) => {
  // console.log('group', group);
  return (
    <div className={styles.root}>
      <p className={styles.root_item__standart}>{group.id}</p>
      <div className={styles.root_item} style={{ display: 'inline-flex' }}>
        <BeneficiariesTabBtn
          title={group?.beneficiary?.is_active ? 'Активен' : 'Не активен'}
          isActive={group?.beneficiary?.is_active}
        />
      </div>
      <div
        className={styles.root_item}
        style={{ display: 'flex', flexDirection: 'column' }}>
        <p className={styles.root_item__standart}>{group?.jur_name || group?.name}</p>
        <div style={{ display: 'flex', columnGap: '10px' }}>
          <p className={styles.root_item__for_ndfl}>{group.inn}</p>
          <p className={styles.root_item__for_ndfl}>{group.kpp}</p>
        </div>
      </div>
      <div
        className={styles.root_item}
        style={{ display: 'flex', flexDirection: 'column' }}>
        {group?.beneficiary?.virtual_accounts?.map((elem: any) => (
          <p
            className={
              elem.type === 'standard'
                ? styles.root_item__standart
                : styles.root_item__for_ndfl
            }
            key={elem.tochka_uuid}>
            {elem.tochka_uuid}
          </p>
        ))}
      </div>
      <div
        className={styles.root_item}
        style={{ display: 'flex', flexDirection: 'column' }}>
        {group?.beneficiary?.virtual_accounts?.map((elem: any) => (
          <p
            style={{ textAlign: 'end' }}
            className={
              elem.type === 'standard'
                ? styles.root_item__standart
                : styles.root_item__for_ndfl
            }
            key={elem.tochka_uuid}>
            {addSpaceNumber(elem.cash)}
          </p>
        ))}
      </div>
      <p className={styles.root_item__standart}>
        {group?.beneficiary?.updated_at
          ? getFotmattedDateCreated(group?.beneficiary?.updated_at)
          : ''}
      </p>
    </div>
  );
};
