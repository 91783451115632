import React, { FC, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { Modal } from 'components/Modal';
import { Title } from 'components/Title';
import { ReactComponent as CloseIcon } from 'assets/img/close.svg';
import styles from './PushInvite.module.scss';
import { InputText } from 'ui/InputText';
import { Button } from 'ui/Button';
import { SuccessInfo } from 'components/SuccessInfo';
import { useInviteEmployedsMutation } from 'redux/employedsApi';
import classNames from 'classnames';

interface PushInviteTypes {
  setShowPushInvite: (e: boolean) => void;
  showPushInvite: boolean;
}

export const PushInvite: FC<PushInviteTypes> = ({
  setShowPushInvite,
  showPushInvite,
  // ...rest
}) => {
  const [valuePhone, setValuePhone] = useState('');
  const disabled = valuePhone.length < 10;

  // console.log('valuePhone', `7${valuePhone}`);

  const [invite, { data, isLoading, error }] = useInviteEmployedsMutation();

  const inviteHandler = async () => {
    await invite({
      body: {
        phone: `7${valuePhone}`,
      },
    }).then((data: any) => {
      if (data?.data) {
        setTimeout(() => {
          setShowPushInvite(false);
          setValuePhone('');
        }, 2000);
      }
    });
  };

  const keyHandler = (e: any) => {
    if (e.key === 'Enter' && !disabled) {
      inviteHandler();
    }
  };

  // console.log('data', data);
  // console.log('error', error);
  return (
    <Modal setOpen={setShowPushInvite} open={showPushInvite}>
      {data ? (
        <SuccessInfo />
      ) : (
        <div className={styles.root}>
          <div>
            <Title title='Добавление исполнителя' />
            {/* <CloseIcon /> */}
          </div>
          <div className={styles.rootPhone}>
            <PatternFormat
              onKeyDown={(e) => keyHandler(e)}
              className={classNames(styles.input, error && styles.input_error)}
              format='+7 (###) ###-##-##'
              placeholder='+7 (___) ___-__-__'
              // onBlur={onBlur}
              onValueChange={(values) => {
                setValuePhone(values.value);
              }}
              value={valuePhone}
            />
            <span
              className={classNames(
                styles.placeholder,
                valuePhone && styles.placeholder_active,
                error ? styles.placeholderError : '',
              )}>
              {error ? 'Ошибка' : 'Телефон'}
            </span>
          </div>
          <Button
            onClick={disabled ? undefined : inviteHandler}
            disabled={disabled || isLoading}
            className={styles.btn}>
            {isLoading ? 'Проверка...' : 'Добавить'}
          </Button>
        </div>
      )}
    </Modal>
  );
};
