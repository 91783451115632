import React, { FC, useState } from 'react';
import { TitleRowTabBeneficiaries } from 'components/TitleRowTabBeneficiaries';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { TaxeErrorItem } from 'ui/TaxeErrorItem';
import { useGetTabQuery } from 'redux/taxesApi';
import { TaxeReadyTab } from '../TaxeReady';
import { Nothing } from 'components/Nothing';
import styles from './TaxeError.module.scss';
interface TaxeErrorTypes {
  id?: string;
  title?: string;
}

export interface TaxeErrorTabTypes extends Omit<TaxeReadyTab, ''> {
  error: string;
}

export const TaxeError: FC<TaxeErrorTypes> = ({ id, title }) => {
  const { data, isLoading } = useGetTabQuery({ id, title });

  const dataTaxes = ['Исполнитель', 'В копилке', 'Начислено', 'Комментарий'];

  return (
    <div className={styles.root}>
      <TitleRowTabBeneficiaries data={dataTaxes} title='taxesDetailError' />
      {isLoading ? (
        <LoadingSkeleton tabDetails />
      ) : data?.length ? (
        data?.map((elem: TaxeErrorTabTypes, i: number) => (
          <TaxeErrorItem item={elem} key={i} />
        ))
      ) : (
        <Nothing style={{ height: '500px' }} title='Ничего не найдено' />
      )}
    </div>
  );
};
