import React, { FC } from 'react';
import { useGetTabQuery } from 'redux/beneficiariesApi';
import { VirtualAccountsItem } from 'ui/VirtualAccountsItem';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { Nothing } from 'components/Nothing';
import styles from './VirtualAccounts.module.scss';
interface VirtualAccountsTypes {
  id: number | null;
  title: string;
}

export const VirtualAccounts: FC<VirtualAccountsTypes> = ({ id, title }) => {
  const { data, isLoading, error } = useGetTabQuery({ id, title });

  // console.log('data', data);
  return (
    <div className={styles.root}>
      {error || !data?.items?.length ? (
        <Nothing
          style={{ height: '640px' }}
          title={error ? 'Ошибка, попробуйте позже' : 'Ничего не найдено'}
        />
      ) : isLoading ? (
        <LoadingSkeleton tabDetails />
      ) : (
        data?.items.map((elem: any, i: number) => (
          <VirtualAccountsItem item={elem} key={i} />
        ))
      )}
    </div>
  );
};
