import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import styles from './Button.module.scss';
import classNames from 'classnames';

interface buttonType {
  info: string;
  outline: string;
  disabled: string;
}

const themes: buttonType = {
  info: styles.info,
  outline: styles.outline,
  disabled: styles.dis,
};
interface ButtonProps {
  className?: string;
  icon?: boolean;
  firstIcon?: boolean;
  src?: string;
  children: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  theme?: keyof typeof themes;
  width?: string;
  height?: string;
  spanStyle?: any;
}

export const Button: FC<ButtonProps> = ({
  onClick,
  className,
  children,
  src,
  icon,
  firstIcon,
  disabled,
  theme,
  width = '16',
  height = '16',
  spanStyle,
  ...rest
}) => {
  return (
    <div
      onClick={disabled ? undefined : onClick}
      className={classNames(
        styles.btn,
        theme ? themes[theme] : null,
        className,
        disabled && styles.btn_disabled,
      )}
      {...rest}>
      {/* //менять расположение с помощью гридов? */}
      {firstIcon && (
        <>
          <img src={src} alt='icon' width={width} height={height} />
        </>
      )}
      <span style={spanStyle}>{children}</span>

      {icon && (
        <>
          <img src={src} alt='icon' width={width} height={height} />
        </>
      )}
    </div>
  );
};
