import React, { FC, useState } from 'react';
import { getFotmattedDateCreated } from 'utils/date';
import { InfoEmployeds } from 'components/InfoEmployeds';
import styles from './PerformersItem.module.scss';
import { Performers } from 'redux/performersWorkSupport';
import { getFormatPhone } from 'utils/getFotmatPhone';

interface PerformersItemTypes {
  employed: Performers;
}

export const PerformersItem: FC<PerformersItemTypes> = ({ employed }) => {
  const textStatus: any = {
    '-1': ['Анкета исполнителя отклонена. Не получает заказы в приложении', '#E10D0D'],
    0: [
      'Исполнитель не отправил документы на проверку. Не получает заказы в приложении',
      'rgb(113, 134, 157)',
    ],
    1: ['Документы проверены', '#28A879'],
    2: ['Исполнитель отправил документы, но они еще не проверены', '#FFD500'],
  };
  return (
    <div className={styles.root}>
      <div style={{ padding: '0 16px' }}>
        <InfoEmployeds
          avatar=''
          status={0}
          id={employed.id}
          name={employed?.fioI || 'исполнитель'}
          surname={employed?.fioF || 'Неизвестный'}
          // phone={employed.phone}
        />
      </div>
      <p>{getFormatPhone(employed.phone) || ''}</p>
      <div className={styles.blockCircle}>
        <div
          className={styles.circle}
          style={{ background: textStatus[employed.verified][1] }}
        />
        <p>{textStatus[employed.verified][0]}</p>
      </div>
    </div>
  );
};
