import React, { FC } from 'react';
import { getFotmattedDateCreated, getTitleDate, getTitleDateForEnd } from 'utils/date';
import styles from './OrderItem.module.scss';
import { BeneficiariesTabBtn } from 'ui/BeneficiariesTabBtn';
import { addSpaceNumber } from 'utils/addSpaceNumber';
import { InfoEmployedItem } from 'components/InfoEmployedItem';

interface OrderItemTypes {
  order: any;
}

export const OrderItem: FC<OrderItemTypes> = ({ order }) => {
  // console.log('order', order);
  return (
    <div className={styles.root}>
      <p className={styles.root_item__standart}>{order?.created_at}</p>
      {/* <div
        className={styles.root_item}
        style={{ display: 'flex', flexDirection: 'column' }}>
        <p className={styles.root_item__standart}>
          {order?.date_start
            ? getTitleDate(order?.date_start?.split(' ')[0], order.date_end.split(' ')[0])
            : `до ${getTitleDateForEnd(order.date_end.split(' ')[0])}`}
        </p>
        <p className={styles.root_item__for_ndfl}>
          с {order?.date_start?.split(' ')[1].slice(0, 5)} до{' '}
          {order.date_end.split(' ')[1].slice(0, 5)}
        </p>
      </div> */}
      <div
        className={styles.root_item}
        style={{ display: 'flex', flexDirection: 'column' }}>
        <p className={styles.root_item__standart}>{order.customer?.jur_name}</p>
        <div style={{ display: 'flex', columnGap: '10px' }}>
          <p className={styles.root_item__for_ndfl}>ИНН {order.customer?.inn}</p>
          <p className={styles.root_item__for_ndfl}>КПП {order.customer?.kpp}</p>
        </div>
      </div>

      <div
        className={styles.root_item}
        style={{ display: 'flex', flexDirection: 'column', padding: '8px 0' }}>
        {order.worker ? (
          <InfoEmployedItem
            fns_status={order.worker?.fns_status}
            orders
            inn={order.worker?.inn}
            fio={`${order.worker?.surname} ${order.worker?.name}`}
            workerAppStatus={order.worker?.app_status}
          />
        ) : (
          <p style={{ padding: '12px 16px' }} className={styles.root_item__standart}>
            {' '}
            -{' '}
          </p>
        )}
      </div>
      <div
        className={styles.root_item}
        style={{ display: 'flex', flexDirection: 'column' }}>
        <p className={styles.root_item__standart}>{order.order_type}</p>
        <p className={styles.root_item__for_ndfl}>{order.description}</p>
      </div>
      <p style={{ textAlign: 'end' }} className={styles.root_item__standart}>
        {addSpaceNumber(order.amount)}
      </p>
      <p style={{ textAlign: 'end' }} className={styles.root_item__standart}>
        {addSpaceNumber(order.commission_amount)}
      </p>
      <div className={styles.root_item} style={{ display: 'inline-flex' }}>
        <BeneficiariesTabBtn
          title={order.status?.text}
          color={order.status?.color_text}
          bg={order.status?.color_bg}
        />
      </div>
    </div>
  );
};
