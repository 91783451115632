import React, { FC } from 'react';
import { ReactComponent as IconSvg } from './img/icon.svg';
import { ReactComponent as IconDone } from './img/iconDone.svg';
import { ReactComponent as IconError } from 'assets/img/error.svg';
import { addSpaceNumber } from 'utils/addSpaceNumber';
import styles from './TaxeReadyItem.module.scss';
import { TaxeReadyTab } from 'components/TabTaxeDetails/TaxeReady';
import { InfoEmployedItem } from 'components/InfoEmployedItem';

interface TaxeReadyItemTypes {
  item: TaxeReadyTab;
}

export const TaxeReadyItem: FC<TaxeReadyItemTypes> = ({ item }) => {
  // console.log('item', item);

  return (
    <div className={styles.root}>
      <div className={styles.root_item}>
        <InfoEmployedItem
          taxe
          style={{ padding: '0' }}
          fns_status={item.worker?.fns_status}
          orders
          inn={item.worker?.inn}
          fio={`${item.worker?.name.split(' ')[0]} ${item.worker?.name.split(' ')[1]}`}
          workerAppStatus={item.worker?.app_status}
        />
      </div>
      <p className={styles.root_title} style={{ textAlign: 'end' }}>
        {addSpaceNumber(item.taxes_amount.in_virtual_account)}
      </p>
      <p className={styles.root_title} style={{ textAlign: 'end' }}>
        {addSpaceNumber(item.taxes_amount.accrued)}
      </p>
    </div>
  );
};
