import { FC, useEffect } from 'react';
import avatar from 'assets/img/navSvg/avatar.png';
import { ReactComponent as NoAvatarSvg } from 'assets/img/noAvatar.svg';
import styles from './Client.module.scss';
import { useNavigate } from 'react-router-dom';

interface ClientTypes {
  showMenu: () => void;
  fio?: string;
}

export const Client: FC<ClientTypes> = ({ showMenu, fio = '' }) => {
  const phone = localStorage.getItem('phone');
  // const navigate = useNavigate();

  return (
    <div className={styles.root} onClick={showMenu}>
      <div className={styles.client}>
        {/* <span>{fio}</span>
        <svg
          width='12'
          height='8'
          viewBox='0 0 12 8'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M1.41 0.590088L6 5.17009L10.59 0.590088L12 2.00009L6 8.00009L0 2.00009L1.41 0.590088Z'
            fill='#ADBFDF'
          />
        </svg> */}
      </div>
      <div className={styles.avatar}>
        <NoAvatarSvg />
        {/* <img
          src={'https://lk.skilla.ru/images/persons/chat/3613_mini.jpg?v=1672314042'}
          alt='Avatar'
        /> */}
        {/* <svg
          width='12'
          height='8'
          viewBox='0 0 12 8'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M1.41 0.590088L6 5.17009L10.59 0.590088L12 2.00009L6 8.00009L0 2.00009L1.41 0.590088Z'
            fill='#ADBFDF'
          />
        </svg> */}
      </div>
    </div>
  );
};
