import { FC, useEffect, useState } from 'react';
import { ReactComponent as IconAlert } from 'assets/img/statuses/iconAlert.svg';
import styles from './TabMenuFilter.module.scss';
interface ListItemTypes {
  name: string;
  key: string;
  count?: number;
}

type TabMenuFilterProps = {
  setActive?: any;
  active: string;
  lists?: ListItemTypes[];
  count?: boolean;
  orders?: boolean;
  operations?: boolean;
};

export const TabMenuFilter: FC<TabMenuFilterProps> = ({
  setActive,
  active,
  lists = [
    { name: 'Сделки', key: 'deals' },
    { name: 'Платежи', key: 'payments' },
    { name: 'Налоги', key: 'taxes' },
  ],
  count,
  orders,
  operations,
}) => {
  const handlerActive = (item: ListItemTypes | any) => {
    if (count) {
      if (orders) localStorage.setItem('ordersFilter', item.name);
      setActive(item);
    } else {
      if (operations) localStorage.setItem('operationsFilter', item.key);
      setActive(item.key);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.root}>
        {lists.map((item: ListItemTypes, i: number) => (
          <div
            className={`${styles.item} ${item.key === active ? styles.active : ''}`}
            key={i}
            onClick={() => handlerActive(item)}>
            <p>{item.name}</p>
            {/* {count ? <span>{item.count}</span> : null} */}
          </div>
        ))}
      </div>
    </div>
  );
};
