import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setErrorStatusField,
  setErrorUpdate,
  setOpenPassport,
  setOpenPeyment,
  setSecondBlackReason,
  setWasChanges,
} from 'redux/mainInfo/slice';
import { RootState } from 'redux/store';
import { useUpdateWorkerMutation } from 'redux/workerApi';
import { getDatePicker } from 'utils/date';

export const useSaveCardWorker = () => {
  const [saveProcess, setSaveProcess] = useState(false);
  const dispatch = useDispatch();
  const {
    workerId,
    nameWorker,
    surnameWorker,
    secondNameWorker,
    genderWorker,
    dateBirth,
    commentWorker,
    telWorker,
    nationalityWorker,
    pasportSeria,
    passportNum,
    passportCode,
    passportIssued,
    datePassport,
    addressRegCity,
    addressRegStreet,
    addresRegHome,
    addresRegK,
    addressRegA,
    address_passport,
    statusWorker,
    statusblackList,
    blackReason,
    secondBlackReason,
    coordinates,
  } = useSelector((state: RootState) => state.mainInfo);
  const [updateWorker, { data, isLoading, error }] = useUpdateWorkerMutation<any>();

  // console.log('object', coordinates);

  useEffect(() => {
    if (error) {
      Object.keys(error?.data.errors).forEach((elem) => {
        if (elem.includes('passport')) dispatch(setOpenPassport('passport'));
        if (elem.includes('payment')) dispatch(setOpenPeyment('payment'));
        dispatch(setErrorStatusField(elem));
      });
      // dispatch(setErrorUpdate(true));
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      dispatch(setSecondBlackReason(''));
      dispatch(setWasChanges(false));
    }
  }, [data]);

  // console.log('getDatePicker(dateBirth)', getDatePicker(dateBirth));
  // console.log('datePassport', getDatePicker(datePassport));

  function handleSendInfo(docs_accept = 0) {
    dispatch(setOpenPassport(''));
    dispatch(setOpenPeyment(''));
    const passportCodeSlice = passportCode?.slice(0, 3) + '-' + passportCode?.slice(3);
    updateWorker({
      idWorker: workerId,
      params: {
        fioI: nameWorker,
        fioF: surnameWorker,
        fioO: secondNameWorker,
        sex: genderWorker,
        dob: dateBirth ? getDatePicker(dateBirth) : '',
        comment: commentWorker,
        phone: `7${telWorker}`,
        nationality: nationalityWorker,
        passport_s: pasportSeria,
        passport_n: passportNum,
        passport_kod: passportCode
          ? passportCode?.slice(0, 3) + '-' + passportCode?.slice(3)
          : '',
        passport_kem: passportIssued,
        passport_date: datePassport ? getDatePicker(datePassport) : '',
        address_reg_city: addressRegCity,
        address_reg_street: addressRegStreet,
        address_reg_home: addresRegHome,
        address_reg_k: addresRegK,
        address_reg_a: addressRegA,
        address_passport: address_passport,
        status: statusWorker,
        is_black: statusblackList,
        black_reason: statusblackList
          ? [blackReason, secondBlackReason].filter((e) => Boolean(e)).join(', ')
          : '',
        ...coordinates,
      },
      docs_accept: docs_accept,
    });
  }

  return { data, isLoading, saveProcess, handleSendInfo };
};
