import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setEmployedsInfo, setInfoOrderDetail } from 'redux/ordersDetails/slice';
import { useGetWorkersQuery } from 'redux/ordersApi';
import { WhiteBlock } from 'components/WhiteBlock';
import { Title } from 'components/Title';
import { InputText } from 'ui/InputText';
import { InputSearch } from 'ui/InputSearch';
import { InfoEmployedItem } from 'components/InfoEmployedItem';
import { ReactComponent as BasketIcon } from 'assets/img/basket.svg';
import { ReactComponent as CrossIcon } from 'assets/img/iconCross.svg';
import styles from './EmployedsSearchBlock.module.scss';
import { EmpoyedTypes } from 'components/EmployedsList';

type ChooseEmployedTypes = Omit<
  EmpoyedTypes,
  'created_at' | 'last_info_update' | 'nationality' | 'patronymic'
>;

interface EmployedsSearchBlockTypes {
  worker: EmpoyedTypes[] | [];
  isReady?: boolean;
  workSupport?: boolean;
}

export const EmployedsSearchBlock: FC<EmployedsSearchBlockTypes> = ({
  worker = [],
  isReady = false,
  workSupport = false,
}) => {
  const { data, isLoading, error } = useGetWorkersQuery();
  // const [focusSearchWorkers, setFocusSearchWorkers] = useState(false);
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState('');
  const [chooseEmployeds, setChooseEmployeds] = useState<ChooseEmployedTypes[]>(
    worker?.length ? worker : [],
  );
  const dispatch = useDispatch();

  const listRef = useRef<HTMLDivElement>(null);

  // console.log('data', data);

  const filtredArr = // value && data
    // ?
    data?.filter(
      (elem: ChooseEmployedTypes) =>
        elem?.name
          ?.replaceAll(' ', '')
          ?.toLowerCase()
          ?.includes(value?.replaceAll(' ', '').toLowerCase()) ||
        elem?.surname
          ?.replaceAll(' ', '')
          ?.toLowerCase()
          ?.includes(value?.replaceAll(' ', '').toLowerCase()),
    );
  // : [];

  const chooseHandler = (elem: ChooseEmployedTypes) => {
    // console.log(elem);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setChooseEmployeds([elem]);
    dispatch(setEmployedsInfo(elem));
    dispatch(setInfoOrderDetail({ key: 'worker_id', value: elem.id }));
    // setFocusSearchWorkers(false);
    setValue('');
  };

  const deleteEmployedHandler = () => {
    setChooseEmployeds([]);
    dispatch(setInfoOrderDetail({ key: 'worker_id', value: null }));
  };

  useEffect(() => {
    const clickOutside = (event: MouseEvent) => {
      if (listRef.current && !event.composedPath().includes(listRef.current)) {
        setValue('');
        setFocus(false);
      }
    };
    document.body.addEventListener('click', clickOutside);
    return () => document.body.removeEventListener('click', clickOutside);
  }, []);

  if (isLoading) {
    return <div>loading...</div>;
  }

  // console.log('first', filtredArr);

  // console.log('chooseEmployeds', chooseEmployeds);

  return (
    <WhiteBlock>
      <div ref={listRef}>
        <div className={styles.blockTitle}>
          <Title title='Исполнитель' />
          {/* {workSupport && !chooseEmployeds.length && (
            <div style={{ cursor: 'pointer' }} onClick={() => setFocusSearchWorkers(true)}>
              <Title
                style={{ color: '#002CFB', fontSize: '16px', fontWeight: 500 }}
                title='Пригласить из избранных'
              />
            </div>
          )} */}
        </div>

        {!chooseEmployeds.length && (
          <InputSearch
            focus={focus}
            setFocus={setFocus}
            orders
            // setFocusSearchWorkers={setFocusSearchWorkers}
            border
            value={value}
            placeholder='Искать по ИНН, ФИО или номеру телефона'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setValue(e.target.value.trimStart())
            }
          />
        )}
        {focus && !!filtredArr.length && !chooseEmployeds.length && (
          <div className={styles.list}>
            {filtredArr?.map((elem: ChooseEmployedTypes, i: number) => (
              <InfoEmployedItem
                chooseHandler={() => chooseHandler(elem)}
                key={i}
                fns_status={elem?.fns_status}
                fio={`${elem?.surname} ${elem?.name}`}
                inn={elem?.inn}
                phone={elem?.phone}
                workerAppStatus={elem?.worker_app?.app_status}
              />
            ))}
          </div>
        )}

        {!!chooseEmployeds.length && (
          <div className={styles.choose}>
            {!!chooseEmployeds.length &&
              chooseEmployeds?.map((elem: ChooseEmployedTypes, i: number) => (
                <InfoEmployedItem
                  chooseHandler={() => chooseHandler(elem)}
                  key={i}
                  fns_status={elem?.fns_status}
                  fio={`${elem?.surname} ${elem?.name}`}
                  inn={elem?.inn}
                  phone={elem?.phone}
                  workerAppStatus={elem?.worker_app?.app_status}
                />
              ))}
            <div onClick={deleteEmployedHandler} className={styles.choose_icon}>
              <CrossIcon />
            </div>
          </div>
        )}
      </div>
    </WhiteBlock>
  );
};
