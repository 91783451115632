import React, { ErrorInfo, ReactNode } from 'react';
import s from './ErrorBoundary.module.scss';
import { Layout } from 'features/Layouts/Layouts';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render custom fallback UI
      return (
        <Layout>
          <div className={s.root_nothing}>
            <p>Что-то пошло не так, попробуйте позже</p>
          </div>
        </Layout>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
