import React, { FC, useState } from 'react';
import { currentDay, getFotmattedDateCreated } from 'utils/date';
import { ReactComponent as IconSvg } from './img/icon.svg';
import { ReactComponent as IconDone } from './img/iconDone.svg';
import { ReactComponent as IconError } from 'assets/img/error.svg';
import styles from './PaymentsItem.module.scss';
import { addSpaceNumber } from 'utils/addSpaceNumber';
import { BeneficiariesTabBtn } from 'ui/BeneficiariesTabBtn';
import { Tooltip } from 'components/Tooltip';

interface PaymentsItemTypes {
  payment: any;
}

export const PaymentsItem: FC<PaymentsItemTypes> = ({ payment }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const lengthType = 35;

  // console.log('lengthAmount', payment.type.length);
  const typeSlice =
    payment.type.length > lengthType
      ? `${payment.type.slice(0, lengthType)}...`
      : payment.type;

  const mouseMove = () => {
    if (payment.type.length > lengthType) setShowTooltip(true);
  };

  const mouseLeave = () => {
    if (payment.type.length > lengthType) setShowTooltip(false);
  };

  return (
    <div className={styles.root}>
      <div>
        <p className={styles.root_title}>{payment.id}</p>
        <p className={styles.root_subTitle}>{currentDay(payment.tochka_created_at)}</p>
      </div>
      <div style={{ position: 'relative' }}>
        <p
          onMouseMove={mouseMove}
          onMouseLeave={mouseLeave}
          className={styles.root_title}>
          {typeSlice}
        </p>
        {showTooltip && <Tooltip fullAddress={payment.type} />}
        {/* <p className={styles.root_subTitle}>Платеж по договору №12345 от 23.12.23</p> */}
      </div>
      <p className={styles.root_title} style={{ textAlign: 'end' }}>
        {addSpaceNumber(payment.amount)}
      </p>
      <div>
        <div style={{ padding: '0 16px' }}>
          <BeneficiariesTabBtn
            classNameStyles={styles.statusBlock}
            oldStyle
            bg={payment.status?.color_bg}
            color={payment.status?.color_text}
            title={payment.status.text}
          />
        </div>
        <p className={styles.root_subTitle}>
          <IconSvg /> {getFotmattedDateCreated(payment.tochka_updated_at)}. назад
        </p>
      </div>
      <p>{payment.identified ? <IconDone /> : <IconError />}</p>
    </div>
  );
};
