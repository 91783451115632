import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const getToken = () => localStorage.getItem('token');

const getAuthorizationHeader = () => {
  const token = getToken();
  return token ? `Bearer ${token}` : '';
};

export interface Performers {
  fioF: string;
  fioI: string;
  fioO: string;
  id: number;
  phone: string;
  sex: number;
  verified: number;
}

// https://{domain}/api/tochka/beneficiaries
export const performersWorkSupport = createApi({
  reducerPath: 'performersWorkSupport',
  tagTypes: ['Employeds', 'Employed', 'Tags', 'EmployedsTabs'],
  refetchOnReconnect: true,
  keepUnusedDataFor: 15,
  // refetchOnFocus: true,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_SERVER_TEST,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: getAuthorizationHeader(),
    },
  }),
  endpoints: (build) => ({
    getPerformersWorkSupport: build.query<Performers | any, void>({
      query: () => ({
        url: `workers-app/moderation-list`,
        method: 'GET',
      }),
      providesTags: () => ['Employeds'],
    }),
  }),
});

export const { useGetPerformersWorkSupportQuery } = performersWorkSupport;
