import React from 'react';
import styles from './ProgressBar.module.scss';

export const ProgressBar = ({ total_amount, amounts = [] }: any) => {
  const widthOnOrders = (summ: number) => {
    return (summ / total_amount) * 100;
  };
  const widthTaxes = (summ: number) => {
    return (summ / total_amount) * 100;
  };
  return (
    <div className={styles.progress}>
      {amounts?.map((amount: any, i: number) => (
        <div
          key={i}
          style={
            amount.payment_type === 'Оплата по заказам'
              ? { width: `${widthOnOrders(amount.amount)}%` }
              : { width: `${widthTaxes(amount.amount)}%` }
          }
          className={
            amount.payment_type === 'Оплата по заказам'
              ? styles.progress_item1
              : styles.progress_item2
          }></div>
      ))}
    </div>
  );
};
