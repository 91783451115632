import React, { FC, useEffect } from 'react';
import { useGetTabOperationsQuery } from 'redux/operationsApi';
import { TitleRowTabOperations } from 'components/TitleRowTabOperations';
import { TaxesOperationsItem } from 'ui/TaxesOperationsItem';
import { useDispatch } from 'react-redux';
import { setCountTab } from 'redux/operationInfo/slice';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { Link } from 'react-router-dom';
import { Nothing } from 'components/Nothing';
import styles from './Taxes.module.scss';
interface TaxesTypes {
  title: string;
}

export const Taxes: FC<TaxesTypes> = ({ title }) => {
  const { data, isLoading } = useGetTabOperationsQuery({ title });

  const dispatch = useDispatch();
  // console.log('data', data);

  useEffect(() => {
    if (data) dispatch(setCountTab({ countTab: data.count, nameTab: 'Налоги' }));
  }, [data]);

  const dataTaxes = [
    'Период',
    'Бенефициар',
    'Виртуальный счет',
    'Сумма в копилке',
    'Сумма списаний',
    'Статус',
  ];

  return (
    <div className={styles.root}>
      {isLoading ? (
        <LoadingSkeleton widthTab />
      ) : (
        <>
          <TitleRowTabOperations data={dataTaxes} title={title} />
          {data ? (
            data.items.slice(0, 50).map((elem: any, i: number) => (
              <Link key={i} to={`/operations/taxes/${elem.id}`}>
                <TaxesOperationsItem taxe={elem} />
              </Link>
            ))
          ) : (
            <Nothing style={{ height: '660px' }} title='Ничего не найдено' />
          )}
        </>
      )}
    </div>
  );
};
