import React, { FC, useState } from 'react';
import { BeneficiariesTabBtn } from 'ui/BeneficiariesTabBtn';
import classNames from 'classnames';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { currentDay, getFotmattedDateCreated } from 'utils/date';
import { ReactComponent as IconSvg } from './img/icon.svg';
import { ReactComponent as ArrowSvg } from './img/Arrow.svg';
import { ReactComponent as BigArrowSvg } from './img/BigArrow.svg';
import styles from './DealDetailsItemRight.module.scss';
import { paymentsTypes } from 'redux/operationsApi';

interface DealDetailsItemRightTypes {
  data: paymentsTypes;
}

export const DealDetailsItemRight: FC<DealDetailsItemRightTypes> = ({ data }) => {
  const [parent] = useAutoAnimate();

  const [showTable, setShowTable] = useState(false);
  const [showMoreInfoPayer, setShowMoreInfoPayer] = useState(false);
  const [showMoreInfoRecipient, setShowMoreInfoRecipient] = useState(false);

  const showHandlerPayer = () => {
    if (showMoreInfoPayer) {
      setShowMoreInfoPayer(false);
    } else {
      setShowMoreInfoPayer(true);
    }
  };

  const showHandlerRecipient = () => {
    if (showMoreInfoRecipient) {
      setShowMoreInfoRecipient(false);
    } else {
      setShowMoreInfoRecipient(true);
    }
  };

  const showHandlerTable = () => {
    if (showTable) {
      setShowTable(false);
    } else {
      setShowTable(true);
    }
  };

  const translateKey: Record<string, string> = {
    name: 'Владелец счёта',
    account: 'Номер счёта',
    tax_code: 'ИНН',
    bank_code: 'БИК',
    bank_name: 'Банк',
    tax_reason_code: 'КПП',
    bank_correspondent_account: 'Кор.счёт ',
  };

  return (
    <div className={styles.rootleft}>
      <div className={styles.left}>
        <div onClick={showHandlerTable} className={styles.left_title}>
          <div className={styles.titleBlock}>
            <p className={styles.title}>
              Платеж ID {data.id} <span className={styles.title_sub}>{data.type}</span>
            </p>
            <p>
              от{' '}
              {currentDay(data.created_at?.split(' ')[0]) +
                ' ' +
                data.created_at?.split(' ')[1]}
            </p>
          </div>
          <div className={styles.blockStatus}>
            <div className={styles.titleBlock}>
              <BeneficiariesTabBtn
                style={{ padding: '2px 8px' }}
                oldStyle
                title={data.status.text}
                color={data.status.color_text}
                bg={data.status.color_bg}
              />
              <p className={styles.root_subText}>
                <IconSvg /> {getFotmattedDateCreated(data.updated_at)}. назад
              </p>
            </div>
            <BigArrowSvg
              className={
                showTable ? styles.tempTableItemRotate_active : styles.tempTableItemRotate
              }
            />
          </div>
        </div>
      </div>
      <div ref={parent}>
        {showTable && (
          <div className={styles.table}>
            <div style={{ border: '1px solid #ECF2FC' }} id='tempTable'>
              <div className={styles.tempTable}>
                <p>Сумма платежа</p>
                <p>{data.amount}</p>
              </div>
              <div className={styles.tempTable}>
                <p>Номер документа</p>
                <p>{data.document_number}</p>
              </div>
              <div className={styles.tempTable}>
                <p>Назначение платежа</p>
                <p style={{ maxWidth: '400px' }}>{data.purpose}</p>
              </div>
              <div
                style={{ cursor: 'pointer', alignItems: 'center' }}
                onClick={showHandlerPayer}
                className={styles.tempTable}>
                <p>Плательщик</p>
                <ArrowSvg
                  className={
                    showMoreInfoPayer
                      ? styles.tempTableItemRotate_active
                      : styles.tempTableItemRotate
                  }
                />
              </div>
              <div ref={parent}>
                {showMoreInfoPayer && (
                  <div>
                    {data.payer.map((elem: any, index: number) => (
                      <div key={index} className={styles.tempTableItem}>
                        <p>{translateKey[elem.name]}</p>
                        <p>{elem.value}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div
                style={{ cursor: 'pointer', alignItems: 'center' }}
                onClick={showHandlerRecipient}
                className={styles.tempTable}>
                <p>Получатель</p>
                <ArrowSvg
                  className={
                    showMoreInfoRecipient
                      ? styles.tempTableItemRotate_active
                      : styles.tempTableItemRotate
                  }
                />
              </div>
              <div ref={parent}>
                {showMoreInfoRecipient && (
                  <div>
                    {data.recipient.map((elem: any, index: number) => (
                      <div key={index} className={styles.tempTableItem}>
                        <p>{translateKey[elem.name]}</p>
                        <p>{elem.value}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
