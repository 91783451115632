import React, { FC } from 'react';
import { currentDay, getFotmattedDateCreated } from 'utils/date';
import { ReactComponent as IconDone } from './img/iconDone.svg';
import { ReactComponent as IconSvg } from './img/icon.svg';
import { ReactComponent as IconError } from 'assets/img/error.svg';
import styles from './DealsOperationsItem.module.scss';
import { addSpaceNumber } from 'utils/addSpaceNumber';
import { BeneficiariesTabBtn } from 'ui/BeneficiariesTabBtn';

interface DealsOperationsItemTypes {
  deal: any;
}

export const DealsOperationsItem: FC<DealsOperationsItemTypes> = ({ deal }) => {
  // console.log('DealsOperationsItem', deal);
  return (
    <div className={styles.root}>
      <div>
        <p className={styles.root_text}>{deal.id}</p>
        <p className={styles.root_subText}>{deal.tochka_created_at}</p>
      </div>
      <p className={styles.root_text} style={{ textAlign: 'end' }}>
        {addSpaceNumber(deal.total_amount)}
      </p>
      <div>
        <p className={styles.root_text}>{deal.beneficiary.customer.name}</p>
        <p className={styles.root_subText}>{deal.beneficiary.tochka_uuid}</p>
      </div>
      <div>
        <p className={styles.root_text}>{deal.worker?.fio}</p>
        <p className={styles.root_subText}>
          {deal.worker?.inn ? `ИНН ${deal.worker.inn}` : deal.worker?.inn}
        </p>
      </div>
      <p>
        {deal.compliance_approved ? (
          <IconDone />
        ) : deal.compliance_approved === false ? (
          <IconError />
        ) : (
          ''
        )}
      </p>
      <div>
        <div style={{ padding: '0 16px' }}>
          <BeneficiariesTabBtn
            classNameStyles={styles.statusBlock}
            oldStyle
            bg={deal.status?.color_bg}
            color={deal.status?.color_text}
            // isActive={false}
            title={deal.status.text}
          />
        </div>
        {deal.tochka_updated_at && (
          <p className={styles.root_subText}>
            <IconSvg /> {getFotmattedDateCreated(deal.tochka_updated_at)}. назад
          </p>
        )}
      </div>
    </div>
  );
};
