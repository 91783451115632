import { FC, useEffect, useMemo, useState } from 'react';
import { Layout } from 'features/Layouts/Layouts';
import { useGetBeneficiariesQuery } from 'redux/beneficiariesApi';
import { MainTitle } from 'components/MainTitle';
// import { SubHeader } from 'components/SubHeader';
import { BeneficiariesList } from 'components/BeneficiariesList';
import {
  useGetEmployedsQuery,
  useGetTabEmployedsCustomerQuery,
} from 'redux/employedsApi';
import { EmployedsList } from 'components/EmployedsList';
import { InputSearch } from 'ui/InputSearch';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { TabMenuFilter } from 'components/TabMenuFilter';
import { PushInvite } from 'components/PushInvite';
import { Button } from 'ui/Button';
import plus from 'assets/img/plus.svg';
import styles from './EmployedsContent.module.scss';

export const EmployedsContent: FC = () => {
  const role = localStorage.getItem('role') || 'WorkSupport';
  const [searchValue, setSearchValue] = useState('');
  const [active, setActive] = useState<any>(
    role === 'director' ? { key: 'invited', filter: null } : 'ready',
  );
  const { data: dataTabs, isLoading: isLoadingTabs } = useGetTabEmployedsCustomerQuery(
    {},
  );

  const [page, setPage] = useState(1);
  const [fetching, setFetching] = useState(true);

  const params = {};
  //  useMemo(() => {
  //   if (role === 'director') {
  //     const { filters, ...rest } = active;

  //     if (Array.isArray(filters)) {
  //       if (filters.length > 1) {
  //         return filters.join('&');
  //       } else {
  //         return filters[0];
  //       }
  //     } else {
  //       return filters;
  //     }
  //   } else {
  //     return null;
  //   }
  // }, [active, role]);

  const { data, isLoading, isFetching } = useGetEmployedsQuery({
    params: params || active,
  });

  useEffect(() => {
    const scrollHandler = (e: any) => {
      if (
        // e?.target?.documentElement.scrollHeight -
        //   (e.target.documentElement.scrollTop + window.innerHeight) <
        // 100
        e.target.scrollHeight - (e.target.scrollTop + window.innerHeight) <
        100
      ) {
        // console.log('object');
        // setFetching(true);
      }
    };

    document.addEventListener('scroll', scrollHandler, { capture: true });
    return () => document.removeEventListener('scroll', scrollHandler);
  }, []);

  const [showPushInvite, setShowPushInvite] = useState(false);

  const openModal = () => {
    setShowPushInvite(true);
  };

  const filtredArr = data?.items?.filter(
    (elem: any) =>
      elem?.name
        ?.replaceAll(' ', '')
        ?.toLowerCase()
        ?.includes(searchValue?.replaceAll(' ', '').toLowerCase()) ||
      elem?.surname
        ?.replaceAll(' ', '')
        ?.toLowerCase()
        ?.includes(searchValue?.replaceAll(' ', '').toLowerCase()) ||
      elem?.phone
        ?.replaceAll(' ', '')
        ?.toLowerCase()
        ?.includes(searchValue?.replaceAll(' ', '').toLowerCase()) ||
      elem?.inn
        ?.replaceAll(' ', '')
        ?.toLowerCase()
        ?.includes(searchValue?.replaceAll(' ', '').toLowerCase()),
  );

  return (
    <Layout>
      <div className={styles.root}>
        <MainTitle title='Избранные исполнители' count={data?.count} />
        <div className={styles.root_search}>
          <div className={styles.root_search_item}>
            <InputSearch
              notFocus
              placeholder='Искать'
              value={searchValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchValue(e.target.value)
              }
            />
            {/* {data && !(isLoading || isLoadingTabs) && (
              <TabMenuFilter
                count
                lists={dataTabs?.items}
                active={active.key}
                setActive={setActive}
              />
            )} */}
          </div>
          {data && !(isLoading || isLoadingTabs) && (
            <Button icon src={plus} onClick={openModal}>
              Добавить исполнителя
            </Button>
          )}
        </div>
        {/* <SubHeader create /> */}
        {isLoading || isLoadingTabs ? (
          // isLoading || isLoadingTabs
          // || isFetching
          <LoadingSkeleton />
        ) : (
          <EmployedsList data={filtredArr} />
        )}
        {showPushInvite && (
          <PushInvite
            showPushInvite={showPushInvite}
            setShowPushInvite={setShowPushInvite}
          />
        )}
      </div>
    </Layout>
  );
};
