import React, { FC } from 'react';
import { TitleRowTabBeneficiaries } from 'components/TitleRowTabBeneficiaries';
import { useGetTabEmployedsQuery } from 'redux/employedsWorkSupportApi';
import { Nothing } from 'components/Nothing';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { DocumentsEmployedsItem } from 'ui/DocumentsEmployedsItem';
import styles from './Documents.module.scss';

interface OrdersTypes {
  id: number | null;
  title: string;
}

export const Documents: FC<OrdersTypes> = ({ id = 0, title }) => {
  const { data, isLoading, error } = useGetTabEmployedsQuery({ id, title });
  const documentsEmployeds = ['Дата', 'Тип', 'Подписано'];

  return (
    <div className={styles.root}>
      <TitleRowTabBeneficiaries data={documentsEmployeds} title='documentsEmployeds' />
      {error || !data?.length ? (
        <Nothing
          style={{ height: '640px' }}
          title={error ? 'Ошибка, попробуйте позже' : 'Ничего не найдено'}
        />
      ) : isLoading ? (
        <LoadingSkeleton tabDetails />
      ) : (
        data?.map((elem: any, i: number) => <DocumentsEmployedsItem doc={elem} key={i} />)
      )}
    </div>
  );
};
