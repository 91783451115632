import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './WhiteBlock.module.scss';

interface WhiteBlockTypes {
  children: ReactNode;
  className?: any;
  style?: any;
}

export const WhiteBlock: FC<WhiteBlockTypes> = ({ children, className, style }) => {
  return (
    <div style={style} className={classNames(styles.root, className)}>
      {children}
    </div>
  );
};
