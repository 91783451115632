import React, { FC } from 'react';
import { ReactComponent as CartSvg } from './img/cart-icon.svg';
import { ReactComponent as MoneyBoxSvg } from './img/moneyBox.svg';
import { ReactComponent as LockSvg } from './img/lock.svg';
import styles from './PriceItem.module.scss';
import { addSpaceNumber } from 'utils/addSpaceNumber';

interface PriceItemTypes {
  type: string;
  cash?: number;
  hold?: number;
}

export const PriceItem: FC<PriceItemTypes> = ({
  type = 'standard',
  cash = 0,
  hold = 0,
}) => {
  return (
    <div className={styles.root}>
      {type === 'standard' ? <CartSvg /> : <MoneyBoxSvg />}
      <div className={styles.root_cash}>
        <p className={styles.root_title}>
          {type === 'standard' ? 'Платежный счет' : 'Налоговая копилка'}
        </p>
        <div className={styles.root_cash_lock}>
          <p className={styles.root_cash_bold}>{addSpaceNumber(cash)} ₽</p>
          {type === 'standard' && (
            <div>
              <LockSvg />
              <p>{addSpaceNumber(hold)} ₽</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
