import { FC, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import {
  setDateEnd,
  setDateEndPicker,
  setDateStart,
  setDateStartPicker
} from 'redux/dateRange/slice';
import { getDatePicker } from 'utils/date';
import './DatePickerСhoose.scss';

type DatePickerСhooseProps = {
  setOpenDateFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setNameSelectDate: (s: string) => void;
};

export const DatePickerСhoose: FC<DatePickerСhooseProps> = ({
  setOpenDateFilter,
  setNameSelectDate
}) => {
  const dispatch = useDispatch();

  const { dateStartPicker, dateEndPicker } = useSelector((state: RootState) => state.dateRange);

  const onChange = (dates: any) => {
    const [start, end] = dates;
    dispatch(setDateEndPicker(end));
    dispatch(setDateStartPicker(start));

    if (end) {
      setNameSelectDate('');
      dispatch(setDateStart(getDatePicker(start)));
      dispatch(setDateEnd(getDatePicker(end)));
      setOpenDateFilter(false);
    }
  };

  registerLocale('ru', ru);

  return (
    <DatePicker
      selected={dateStartPicker}
      onChange={onChange}
      startDate={dateStartPicker}
      endDate={dateEndPicker}
      selectsRange
      inline
      locale={'ru'}
    />
  );
};
