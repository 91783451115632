import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { resetOrderInfo } from 'redux/ordersDetails/slice';
import {
  navListWorkSupport,
  navListСustomer,
  navListСustomerProfile,
} from 'constants/stringConstants';
// import { Button } from 'components/Header/Button';
import { NavList } from 'components/Nav/NavList';
import { Button } from 'ui/Button';
// import logoSvg from 'assets/img/navSvg/logo.svg';
import logoSvg from 'assets/img/navSvg/LogoRus.svg';
import exclamation from 'assets/img/navSvg/exclamation.svg';
import plus from 'assets/img/navSvg/plus.svg';
import styles from './Nav.module.scss';
import { RootState } from 'redux/store';

export const Nav: FC = () => {
  const { is_contract_signed } = useSelector((state: RootState) => state.authSlice);
  // console.log('is_contract_signed', is_contract_signed);
  // console.log('navListСustomerProfile', navListСustomerProfile)
  const dispatch = useDispatch();
  const role = localStorage.getItem('role') || 'worksupport';
  return (
    <div className={styles.nav}>
      <img className={styles.logo} src={logoSvg} alt='Skilla logo' />
      <div className={styles.nav__list}>
        <NavList
          navItems={role === 'worksupport' ? navListWorkSupport : navListСustomer}
        />
      </div>
      <div className={styles.buttons}>
        {role !== 'director' ? (
          <Link to='/beneficiar/create'>
            <Button
              width='24'
              height='24'
              className={classNames(styles.buttons_item, styles.buttons_item_ben)}
              icon
              src={plus}>
              Бенефициар
            </Button>
          </Link>
        ) : (
          <Link to='/orders/create'>
            <Button
              onClick={() => dispatch(resetOrderInfo())}
              width='24'
              height='24'
              className={styles.buttons_item}
              icon
              src={plus}>
              Добавить заказ
            </Button>
          </Link>
        )}
        {/* <Button title='Оплата' img={exclamation} /> */}
      </div>
    </div>
  );
};
