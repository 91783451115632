export const activeArrfilters = [
  'moderation_in_progress',
  'worker_invited_by_customer',
  'worker_invited_by_customer',
  'worker_approved_by_customer',
  'work_success',
  'worker_approved_by_customer',
  'worker_approved_order',
  'payment_in_progress',
  'payment_failed',
  'payment_returned',
];

export const actionsArrFilters = [
  'moderation_failed',
  'worker_took_order',
  'act_signed_by_worker',
];

export const actionsByWorkerArrFilters = [
  'worker_invited_by_customer',
  'worker_approved_by_customer',
  'worker_approved_order',
  'work_success',
  'payment_failed',
  'payment_returned',
];
