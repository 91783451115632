import React, { FC } from 'react';
import classNames from 'classnames';
import { dateForOrderDetails } from '../../utils/date';
import { addSpaceNumber } from '../../utils/addSpaceNumber';
import styles from './InformationOrderBlock.module.scss';
interface InformationOrderBlockTypes {
  objectInfo?: Record<string, string | any>;
  className?: any;
  payment?: boolean;
}

export const InformationOrderBlock: FC<InformationOrderBlockTypes> = ({
  objectInfo,
  className,
  payment,
}) => {
  const objectElements = [];

  const translateObj: Record<string, string> = {
    name: 'Наименование',
    inn: 'ИНН',
    kpp: 'КПП',
    phone: 'Телефон',
    email: 'E-mail',
    jur_address: 'Юридический адрес',
    legal_type: 'Тип организации',
    ogrn: 'ОГРН',
    address: 'Локация',
    description: 'Описание',
    order_type: 'Тип услуги ',
    date_start: 'Дата начала ',
    date_end: 'Дата окончания',
    amount: 'Стоимость',
    period: 'Период',
    customer_jur_name: 'Юр. лицо',
    taxes_on_virtual_account_amount: 'В копилке',
    taxes_accrued_amount: 'Начислено',
    taxes_paid_amount: 'Оплачено',
    date: 'Дата',
    recipient: 'Получатель ',
    total_amount: 'Сумма',
    worker_amount: 'Выплата',
    commission_amount: 'Комиссия',
    tax_amount: 'В копилку',
    legal_entity: 'Заказчик',
    purpose: 'Назначение',
    requisites: 'Реквизиты',
    failure_description: 'Комментарий',
    bic: 'БИК',
    recipient_name: 'Наименование получателя',
  };

  // if (state.infoBeneficiar[key] instanceof Object)

  for (const key in objectInfo) {
    if (Boolean(objectInfo[key])) {
      // eslint-disable-next-line no-prototype-builtins
      if (objectInfo.hasOwnProperty(key) && key in translateObj) {
        // console.log('obje', key);
        objectElements.push(
          <div className={styles.blockDetail} key={key}>
            <p className={styles.blockDetail_key}>{translateObj[key]} </p>
            <p className={styles.blockDetail_value}>
              {key.includes('date') && !payment
                ? dateForOrderDetails(objectInfo[key])
                : key.includes('amount')
                ? `${addSpaceNumber(objectInfo[key])} ₽`
                : objectInfo[key] === 'I'
                ? 'ИП'
                : objectInfo[key] === 'J'
                ? 'Юридическое лицо'
                : objectInfo[key] === 'F'
                ? 'Физическое лицо'
                : objectInfo[key]}
            </p>
          </div>,
        );
      } else {
        objectElements.push(
          <div className={styles.blockDetail} key={key}>
            <p className={styles.blockDetail_key}>{key} </p>
            <p className={styles.blockDetail_value}>{objectInfo[key]}</p>
          </div>,
        );
      }
    }
  }
  return (
    <div className={classNames(styles.blockDetailRoot, className)}>{objectElements}</div>
  );
};
