import React, { FC, useState } from 'react';
import { TitleRowTabBeneficiaries } from 'components/TitleRowTabBeneficiaries';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { TaxeReadyItem } from 'ui/TaxeReadyItem';
import { useGetTabQuery } from 'redux/taxesApi';
import styles from './TaxeReady.module.scss';
interface TaxeReadyTypes {
  id?: string;
  title?: string;
}

interface TaxesAmountTypes {
  accrued: string;
  in_virtual_account: string;
}

interface WorkerTypes {
  app_status: number;
  fns_status: number;
  id: number;
  inn: string;
  name: string;
}

export interface TaxeReadyTab {
  taxes_amount: TaxesAmountTypes;
  worker: WorkerTypes;
}

export const TaxeReady: FC<TaxeReadyTypes> = ({ id, title }) => {
  const { data, isLoading } = useGetTabQuery({ id, title });
  const dataTaxes = ['Исполнитель', 'В копилке', 'Начислено'];

  return (
    <div className={styles.root}>
      <TitleRowTabBeneficiaries data={dataTaxes} title='taxesDetail' />
      {isLoading ? (
        <LoadingSkeleton tabDetails />
      ) : (
        data?.map((elem: TaxeReadyTab, i: number) => (
          <TaxeReadyItem item={elem} key={i} />
        ))
      )}
    </div>
  );
};
