import { FC, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { useDispatch } from 'react-redux';
import { setErrorStatusField, setWasChanges } from 'redux/mainInfo/slice';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';
// import { getDateFormattedDateForParams } from 'utils/date';

type DatePickerProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDate: any;
  date: any;
  withoutHour?: boolean;
  nameError?: string;
};

export const DatePickerChoose: FC<DatePickerProps> = ({
  setOpen,
  date,
  setDate,
  withoutHour,
  nameError,
}) => {
  // console.log(withoutHour);
  const dispatch = useDispatch();

  const onChange = (date: any) => {
    dispatch(setDate(date));
    if (nameError) {
      dispatch(setErrorStatusField({ key: nameError, value: 0 }));
    }
    // getDateFormattedDateForParams(date)
    // setOpen(false);
    // dispatch(setWasChanges(true));
  };

  registerLocale('ru', ru);

  return (
    <DatePicker
      selected={date}
      onChange={onChange}
      inline
      locale={'ru'}
      timeInputLabel='Time:'
      dateFormat={withoutHour ? 'MM/dd/yyyy' : 'MM/dd/yyyy h:mm aa'}
      showTimeInput={!withoutHour}
      // customTimeInput
      // showDisabledMonthNavigation={true}
      minDate={new Date()}
    />
  );
};
