import { FC, useEffect, useMemo, useState } from 'react';
import { Layout } from 'features/Layouts/Layouts';
import { MainTitle } from 'components/MainTitle';
import {
  Performers,
  useGetPerformersWorkSupportQuery,
} from 'redux/performersWorkSupport';
import { PerformersList } from 'components/PerformersList';
import { InputSearch } from 'ui/InputSearch';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import styles from './PerformersContent.module.scss';

export const PerformersContent: FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const { data, isLoading, error } = useGetPerformersWorkSupportQuery();

  // console.log('dataPerformersContent', data);

  // const data = {
  //   count: 156,
  //   items: [
  //     {
  //       id: 430063,
  //       fioF: '',
  //       fioI: '',
  //       fioO: '',
  //       sex: 1,
  //       phone: '79785048514',
  //       verified: 2,
  //     },
  //     {
  //       id: 443086,
  //       fioF: 'Копытов',
  //       fioI: 'Иван',
  //       fioO: 'Сергеевич',
  //       sex: 1,
  //       phone: '79965753182',
  //       verified: 2,
  //     },
  //     {
  //       id: 444114,
  //       fioF: '',
  //       fioI: '',
  //       fioO: '',
  //       sex: 1,
  //       phone: '79043930646',
  //       verified: 2,
  //     },
  //     {
  //       id: 445436,
  //       fioF: '',
  //       fioI: '',
  //       fioO: '',
  //       sex: 1,
  //       phone: '79617011258',
  //       verified: 2,
  //     },
  //     {
  //       id: 453017,
  //       fioF: '',
  //       fioI: '',
  //       fioO: '',
  //       sex: 1,
  //       phone: '79233353755',
  //       verified: 2,
  //     },
  //     {
  //       id: 453705,
  //       fioF: '',
  //       fioI: '',
  //       fioO: '',
  //       sex: 1,
  //       phone: '79143320881',
  //       verified: 2,
  //     },
  //     {
  //       id: 453808,
  //       fioF: '',
  //       fioI: '',
  //       fioO: '',
  //       sex: 1,
  //       phone: '79130604791',
  //       verified: 2,
  //     },
  //     {
  //       id: 453897,
  //       fioF: '',
  //       fioI: '',
  //       fioO: '',
  //       sex: 1,
  //       phone: '79600542037',
  //       verified: 2,
  //     },
  //     {
  //       id: 453941,
  //       fioF: '',
  //       fioI: '',
  //       fioO: '',
  //       sex: 1,
  //       phone: '79631547707',
  //       verified: 2,
  //     },
  //     {
  //       id: 453943,
  //       fioF: '',
  //       fioI: '',
  //       fioO: '',
  //       sex: 1,
  //       phone: '79302575800',
  //       verified: 2,
  //     },
  //     {
  //       id: 453944,
  //       fioF: '',
  //       fioI: '',
  //       fioO: '',
  //       sex: 1,
  //       phone: '79785213281',
  //       verified: 2,
  //     },
  //     {
  //       id: 453945,
  //       fioF: '',
  //       fioI: '',
  //       fioO: '',
  //       sex: 1,
  //       phone: '79135173234',
  //       verified: 2,
  //     },
  //   ],
  // };

  const filtredArr = data?.items?.filter(
    (elem: Performers) =>
      elem?.fioI
        ?.replaceAll(' ', '')
        ?.toLowerCase()
        ?.includes(searchValue?.replaceAll(' ', '').toLowerCase()) ||
      elem?.fioF
        ?.replaceAll(' ', '')
        ?.toLowerCase()
        ?.includes(searchValue?.replaceAll(' ', '').toLowerCase()) ||
      elem?.phone
        ?.replaceAll(' ', '')
        ?.toLowerCase()
        ?.includes(searchValue?.replaceAll(' ', '').toLowerCase()),
  );

  return (
    <Layout>
      <div className={styles.root}>
        <MainTitle title='Модерация' count={data?.count} />
        <div className={styles.root_search}>
          <div className={styles.root_search_item}>
            <InputSearch
              notFocus
              placeholder='Искать'
              value={searchValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchValue(e.target.value)
              }
            />
          </div>
        </div>
        {isLoading ? <LoadingSkeleton /> : <PerformersList data={filtredArr} />}
      </div>
    </Layout>
  );
};
