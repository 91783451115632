import React, { FC } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import { addSpaceNumber } from 'utils/addSpaceNumber';
import { getFotmattedDateCreated, getShortDate } from 'utils/date';
import { ReactComponent as CartSvg } from './img/cart-icon.svg';
import { ReactComponent as MoneyBoxSvg } from './img/moneyBox.svg';
import { ReactComponent as ReloadSvg } from './img/reload-icon.svg';
import styles from './VirtualAccountsItem.module.scss';
import { BeneficiariesTabBtn } from 'ui/BeneficiariesTabBtn';

interface ItemTypes {
  cash: number;
  tochka_updated_at: string;
  tochka_uuid: string;
  type: string;
}
interface VirtualAccountsItemTypes {
  item: ItemTypes;
}

export const VirtualAccountsItem: FC<VirtualAccountsItemTypes> = ({ item }) => {
  // console.log('item', item);
  return (
    <div className={styles.root}>
      <div>{item.type === 'standard' ? <CartSvg /> : <MoneyBoxSvg />}</div>
      <div className={styles.root_item}>
        <div>
          <div className={styles.cash}>
            <p className={styles.root_item_title}>
              {item.type === 'standard' ? 'Платежный счет' : 'Налоговая копилка'}
            </p>
            <p className={styles.cash_bold}>{addSpaceNumber(item.cash)} ₽</p>
          </div>
          <div className={styles.cash_title}>
            <p>{item.tochka_uuid}</p>
          </div>
        </div>
        <div className={styles.active_block}>
          {/* <div className={styles.active_block__btn}>
            <BeneficiariesTabBtn isActive={true} title={'Деактивирован'} />
          </div> */}
          <div className={styles.active_block__item}>
            <ReloadSvg />
            <p>
              Данные обновлены {getFotmattedDateCreated(item.tochka_updated_at)} назад
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
