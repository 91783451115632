import React, { FC } from 'react';
import { currentDay, getFotmattedDateCreated, getMounth, getWeek } from 'utils/date';
import { addSpaceNumber } from 'utils/addSpaceNumber';
import { BeneficiariesTabBtn } from 'ui/BeneficiariesTabBtn';
import { ReactComponent as IconSvg } from './img/icon.svg';
import styles from './TaxesOperationsItem.module.scss';

interface TaxesOperationsItemTypes {
  taxe: any;
}

export const TaxesOperationsItem: FC<TaxesOperationsItemTypes> = ({ taxe }) => {
  // console.log('taxe', taxe);
  return (
    <div className={styles.root}>
      <p className={styles.root_text}>
        <span>{getMounth(taxe.tochka_created_at.split(' ')[0])}</span>
      </p>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p className={styles.root_text}>{taxe.beneficiary?.customer?.name}</p>
        <div style={{ display: 'flex', columnGap: '10px', padding: '0 16px' }}>
          <p style={{ padding: 0 }} className={styles.root_subText}>
            ИНН 7653462867
          </p>
          <p style={{ padding: 0 }} className={styles.root_subText}>
            КПП 483749734985
          </p>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p className={styles.root_text}>{taxe.virtual_account.type}</p>
        <p className={styles.root_subText}>{taxe.virtual_account.tochka_uuid}</p>
      </div>
      <p className={styles.root_text} style={{ textAlign: 'end' }}>
        {taxe.account_balance}
      </p>
      <p className={styles.root_text} style={{ textAlign: 'end' }}>
        {addSpaceNumber(taxe.amount)}
      </p>
      <div style={{ padding: '0 16px' }}>
        <BeneficiariesTabBtn
          bg={taxe.status?.color_bg}
          color={taxe.status?.color_text}
          // isActive={false}
          title={taxe.status.text}
        />
      </div>
    </div>
  );
};
