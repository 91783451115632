import { FC } from 'react';
import classNames from 'classnames/bind';
import styles from './Tooltip.module.scss';

let cx = classNames.bind(styles);

type TooltipProps = {
  text: string;
  position: 'top' | 'bottom';
  field?: boolean;
  isFns?: boolean;
  employeds?: boolean;
};

export const Tooltip: FC<TooltipProps> = ({
  text,
  position,
  field,
  isFns,
  employeds,
}) => {
  // console.log('isFns', isFns);
  const classNamePosition = cx({
    root: true,
    root_top: position === 'top',
    root_bottom: position === 'bottom',
    root_bottom_field: field,
    root_field: field,
    root_bottom_isFns: isFns,
    root_bottom_employeds: employeds,
  });

  return <div className={classNamePosition}>{text}</div>;
};
