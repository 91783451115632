import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCreateOrderMutation, useGetTypesOfServicesQuery } from 'redux/ordersApi';
import { resetOrderInfo } from 'redux/ordersDetails/slice';
import { RootState } from 'redux/store';
import {
  getDateFormattedDateForParams,
  getDateFormattedDateForParamsString,
} from 'utils/date';
import { Layout } from 'features/Layouts/Layouts';
import { ButtonSave } from 'components/ButtonSave';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { EmployedsSearchBlock } from 'components/EmployedsSearchBlock';
import { EditFieldsOrder } from 'components/EditFieldsOrder';
import { NewOrderDetailsHistory } from 'components/NewOrderDetailsHistory';
import styles from './CreateOrder.module.scss';

export const CreateOrder = () => {
  const phone = localStorage.getItem('phone');

  const navigate = useNavigate();
  const { data: dataTypes, isLoading } = useGetTypesOfServicesQuery();
  const { infoOrderDetail, dateStartHourMinutes, dateEndHourMinutes } = useSelector(
    (state: RootState) => state.ordersDetailsSlice,
  );
  const { balance, infoPercent } = useSelector((state: RootState) => state.authSlice);
  const { worker_id, description, amount } = infoOrderDetail || {};
  const [saveOrder, { data, error, isLoading: loadingSaveOrder }] =
    useCreateOrderMutation<any>();

  // console.log(
  //   'date',
  //   typeof infoOrderDetail.date_start === 'object' &&
  //     getDateFormattedDateForParams(infoOrderDetail.date_start),
  // );
  // console.log('dateformated', getDateFormattedDateForParams(infoOrderDetail.date_start));

  // console.log('infoOrderDetail', infoOrderDetail.worker_id);

  // console.log(
  //   'infoOrderDetail',
  //   typeof infoOrderDetail.date_end === 'object'
  //     ? `${getDateFormattedDateForParams(infoOrderDetail.date_end)} 00:00`
  //     : `${getDateFormattedDateForParamsString(infoOrderDetail.date_end)} 00:00`,
  // );

  const dispatch = useDispatch();
  const saveOrderHandler = async (draft: boolean) => {
    const { date_start, date_end, amount, is_draft, is_remote, ...rest } =
      infoOrderDetail;
    await saveOrder({
      body: {
        ...rest,
        // date_start:
        //   typeof date_start === 'object'
        //     ? `${getDateFormattedDateForParams(date_start)} ${
        //         dateStartHourMinutes.hour
        //       }:${dateStartHourMinutes.minutes}`
        //     : `${getDateFormattedDateForParamsString(date_start)} ${
        //         dateStartHourMinutes.hour
        //       }:${dateStartHourMinutes.minutes}`,
        // date_start: '',
        date_end:
          typeof date_end === 'object'
            ? `${getDateFormattedDateForParams(date_end)} 00:00`
            : `${getDateFormattedDateForParamsString(date_end)} 00:00`,
        amount: +amount,
        is_draft: draft,
        is_remote: is_remote === 'Удаленно' ? true : false,
      },
    });
    // navigate('/orders')
  };

  useEffect(() => {
    if (data) {
      navigate(`/orders/${data.id}`);
      dispatch(resetOrderInfo());
    }
  }, [data]);

  useEffect(() => {
    if (error?.data) {
      alert(error?.data?.message);
    }
  }, [error]);

  // useEffect(() => {
  //   const { worker_id, order_type_id, date_start, date_end, is_remote, ...obj } =
  //     infoOrderDetail;
  //   const arr = Object.values(obj).every((elem) => elem !== '');
  //   console.log(arr);
  // }, [infoOrderDetail]);

  const minMaxPrice =
    phone === '9052264047' || phone === '79052264047'
      ? { min: 50, max: 50000 }
      : { min: 1000, max: 50000 };

  const disabled =
    infoOrderDetail.is_remote === 'Удаленно'
      ? Boolean(description && amount)
      : Boolean(description && amount) &&
        Boolean(infoOrderDetail.city || infoOrderDetail.street);

  const disabledAmount =
    minMaxPrice.min > +infoOrderDetail.amount ||
    minMaxPrice.max < +infoOrderDetail.amount;

  const disabledBtn = !disabledAmount && disabled && +balance >= +amount;

  const sumWithPercent = +infoOrderDetail.amount * (infoPercent.commission_percent / 100);

  const disabledBtnPercent =
    +balance <
    (sumWithPercent < infoPercent.commission_min_amount
      ? +infoOrderDetail.amount + infoPercent.commission_min_amount
      : +infoOrderDetail.amount + +sumWithPercent.toFixed(2));

  return (
    <Layout>
      {!infoOrderDetail || isLoading ? (
        <LoadingSkeleton mt />
      ) : (
        <div className={styles.root}>
          <div className={styles.back}>
            <p className={styles.back_title}>Новый заказ</p>
            <div className={styles.root_blockBtn}>
              <ButtonSave
                disabled={!disabledAmount && +balance >= +amount && !disabledBtnPercent}
                onClick={() => saveOrderHandler(true)}
                // theme='button_d'
                theme={'button_draft'}
                text={'Сохранить как черновик'}
              />
              <ButtonSave
                disabled={disabledBtn && !disabledBtnPercent}
                onClick={() => saveOrderHandler(false)}
                // theme='button_d'
                // theme={!worker_id && 'button_draft'}
                text={'Сохранить и опубликовать заказ'}
              />
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.rootleft}>
              <EditFieldsOrder
                types={dataTypes}
                minMaxPrice={minMaxPrice}
                balance={+balance >= +amount}
                sumBalance={+balance}
                infoPercent={infoPercent}
              />
              {/* <EmployedsSearchBlock /> */}
            </div>

            <div className={styles.right}>
              {/* <OrderDetailsHistory create /> */}
              <NewOrderDetailsHistory create />
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};
