import { Title } from 'components/Title';
import { FC, useEffect, useState } from 'react';
import { ReactComponent as ArrowSvg } from 'assets/img/arrowOpenBlock.svg';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames/bind';
import styles from './BlockResize.module.scss';
import { useAutoAnimate } from '@formkit/auto-animate/react';

// const { ref, inView } = useInView({
//   threshold: 1
// });
const cx = classNames.bind(styles);

type BlockResizeProps = {
  title: string;
  children: JSX.Element;
  photoDel?: boolean;
  open?: string;
  style?: any;
  error?: string;
  notUse?: boolean;
};

export const BlockResize: FC<BlockResizeProps> = ({
  title,
  children,
  photoDel = false,
  open,
  error,
  style,
  notUse = false,
}) => {
  const [parent] = useAutoAnimate();

  const content: any = {
    'Основной документ': 'isOpenDocument',
    'Налоговый статус': 'isOpenTaxStatus',
    'Основной способ оплаты': 'isOpenMethodPay',
    'Адрес проживания': 'isOpenAddress',
    Выплата: 'isOpenPay',
  };

  // const isOpen = JSON.parse(localStorage.getItem(content[title]) || `false`);
  const [openContent, setOpenContent] = useState(true);

  const timeAnchor = (anchor: string) =>
    setTimeout(() => {
      const link = document.createElement('a');
      link.href = anchor;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, 300);

  useEffect(() => {
    if (open === 'passport') {
      setOpenContent(true);
      timeAnchor('#mainDocAnchor');
    }
    if (open === 'payment') {
      setOpenContent(true);
      timeAnchor('#payment');
    }
    if (open === 'Выплата') {
      setOpenContent(true);
    }
  }, [open]);

  const handlerOpenContent = () => {
    setOpenContent(!openContent);
  };

  const classNameTitle = cx({
    title_photoDel: photoDel,
    title: true,
    rotateSvg: openContent,
    paddingTitle: openContent && photoDel,
  });

  const classNameContent = cx({
    content_photoDel: photoDel,
    content: openContent,
  });

  return (
    <div ref={parent} className={styles.root}>
      <div
        style={style}
        className={classNameTitle}
        onClick={!notUse ? handlerOpenContent : undefined}>
        <Title
          title={title}
          // error={error}
        />
        {!notUse && <ArrowSvg />}
      </div>

      {openContent && <div className={classNameContent}>{children}</div>}
    </div>
  );
};
