import React, { FC, useState } from 'react';
import { getFotmattedDateCreated } from 'utils/date';
import { ReactComponent as Fns1 } from 'assets/img/taxStatus/ФНС.svg';
import { ReactComponent as Fns2 } from 'assets/img/taxStatus/fns2.svg';
import { ReactComponent as Fns3 } from 'assets/img/taxStatus/error.svg';
import styles from './EmployedItem.module.scss';
import { BeneficiariesTabBtn } from 'ui/BeneficiariesTabBtn';
import { InfoEmployeds } from 'components/InfoEmployeds';
import { Tooltip } from 'ui/Tooltip';

interface EmployedItemTypes {
  employed: any;
  role: string;
}

export const EmployedItem: FC<EmployedItemTypes> = ({ employed, role }) => {
  // console.log('employed', employed);
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);

  const mouseOver = () => {
    setIsOpenTooltip(true);
  };

  const mouseOut = () => {
    setIsOpenTooltip(false);
  };

  return (
    <div className={role === 'director' ? styles.rootCustomer : styles.root}>
      {role !== 'director' && <p>{employed.id}</p>}
      <div style={{ padding: '0 16px' }}>
        <InfoEmployeds
          status={employed.worker_app?.app_status}
          avatar={employed.worker_app?.avatar}
          id={employed.id}
          name={employed.name}
          surname={employed.surname}
          // phone={employed.phone}
        />
      </div>
      <p>{employed.phone || ''}</p>

      <p>{employed.inn || ''}</p>
      {/* <p style={{ position: 'relative' }} onMouseOver={mouseOver} onMouseOut={mouseOut}>
        {!employed.fns_status ? (
          ''
        ) : employed.fns_status === 2 ? (
          <Fns1 />
        ) : employed.fns_status === 1 ? (
          <Fns2 />
        ) : (
          <Fns3 />
        )}

        {isOpenTooltip && (
          <Tooltip
            text={
              employed.fns_status === 1
                ? 'Привязан к платформе без права оплаты налога'
                : 'Не привязан к платформе'
            }
            position={'bottom'}
            isFns
            employeds
          />
        )}
      </p> */}
      {/* <div>
        <p>Не зарегистрирован</p>
      </div> */}
      <div className={styles.blockCircle}>
        <div
          className={styles.circle}
          style={
            employed.fns_status === 1
              ? { background: '#28A879' }
              : { background: '#E10D0D' }
          }
        />
        {employed.fns_status === 1 ? (
          <p>Подключен к площадке</p>
        ) : (
          <p>Не подключен к площадке</p>
        )}
      </div>
      <p>{employed.created_at ? getFotmattedDateCreated(employed.created_at) : ''}</p>
    </div>
  );
};
