import React, { FC } from 'react';
import { ReactComponent as InfoSvg } from './img/iconInfo.svg';
import { ReactComponent as WarningSvg } from './img/iconWarning.svg';
import styles from './InfoBlock.module.scss';
import classNames from 'classnames';
import { title } from 'process';

interface buttonType {
  info: string;
  warning: string;
  secondary: string;
}

const themes: buttonType = {
  info: styles.info,
  warning: styles.warning,
  secondary: styles.grey,
};

interface InfoBlockTypes {
  theme?: keyof typeof themes;
  title: string;
}

export const InfoBlock: FC<InfoBlockTypes> = ({ theme = 'info', title }) => {
  return (
    <div className={classNames(styles.root, theme ? themes[theme] : null)}>
      <div>{theme === 'info' ? <InfoSvg /> : <WarningSvg />}</div>
      <p>{title}</p>
    </div>
  );
};
