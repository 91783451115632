import { FC } from 'react';
import ContentLoader from 'react-content-loader';
import styles from './LoadingSkeleton.module.scss';
// import classNames from 'classnames';
import classNames from 'classnames/bind';

type LoadingSkeletonType = {
  widthTab?: boolean;
  mt?: boolean;
  tabDetails?: boolean;
};

const cx = classNames.bind(styles);

export const LoadingSkeleton: FC<LoadingSkeletonType> = ({
  widthTab = false,
  mt = false,
  tabDetails,
}) => {
  const classNameLoading = cx({
    root: true,
    root_widthTab: widthTab,
    root_mt: mt,
    root_tabDetails: tabDetails,
  });

  return (
    <ContentLoader
      className={classNameLoading}
      speed={2}
      backgroundColor={'#FFFFFF'}
      foregroundColor={'#D8E4FB8C'}>
      <rect x='0' y='0' rx='0' ry='0' width='100%' height='100%' />
    </ContentLoader>
  );
};
