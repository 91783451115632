import { FC, useEffect, useRef, useState } from 'react';
import { DateToday } from './DateToday';
import { Client } from './Client';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ordersApi } from 'redux/ordersApi';
import { ReactComponent as IconLogout } from './img/iconLogout.svg';
import { ReactComponent as IconCash } from './img/cash.svg';
import { ReactComponent as LockSvg } from './img/lock.svg';

import styles from './Header.module.scss';
import { useGetInfoUserQuery, useLazyGetInfoUserQuery } from 'redux/ProfileApi';
import { addSpaceNumber } from 'utils/addSpaceNumber';
import { useDispatch } from 'react-redux';
import { logIn, setBalance, setIsContractSigned } from 'redux/auth/slice';

export const Header: FC = () => {
  const role = localStorage.getItem('role');
  const token = localStorage.getItem('token');

  const location = useLocation();
  const dispatch = useDispatch();
  const [getUser, { data, isLoading, error }] = useLazyGetInfoUserQuery<any>();
  const headerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [showPopap, setShowPopap] = useState(false);

  // console.log('data', data);
  // console.log('Header', localStorage.getItem('token'));

  const logout = () => {
    setShowPopap(false);

    navigate('/login', { replace: true });
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    dispatch(logIn(null));
    // ordersApi.invalidateQueries('getOrders');
  };

  useEffect(() => {
    if (role === 'director' && token) getUser();
  }, [location?.pathname]);

  useEffect(() => {
    if (error?.status === 401) {
      return logout();
    }
    if (data?.is_contract_signed) {
      dispatch(setIsContractSigned(data?.is_contract_signed));
    }
    dispatch(
      setBalance({
        balance: data?.balance,
        infoPercent: {
          commission_min_amount: data?.commission_min_amount,
          commission_percent: data?.commission_percent,
        },
      }),
    );
  }, [data, error]);

  useEffect(() => {
    const clickOutside = (event: MouseEvent) => {
      if (headerRef.current && !event.composedPath().includes(headerRef.current)) {
        setShowPopap(false);
      }
    };
    document.body.addEventListener('click', clickOutside);
    return () => document.body.removeEventListener('click', clickOutside);
  }, []);

  const showMenu = () => {
    setShowPopap(!showPopap);
  };

  return (
    <div ref={headerRef} className={styles.header}>
      <div className={styles.content}>
        <div className={styles.content_left}>
          <DateToday />
          {role === 'director' && (
            <>
              <Link to={'/profile'} className={styles.content_left_item}>
                <IconCash />
                <p>Баланс</p>
                <p>{data?.balance ? addSpaceNumber(data?.balance) : 0} ₽</p>
              </Link>
              <div className={styles.content_left_item}>
                <LockSvg />
                <p>Зарезервировано</p>
                <p>{data?.hold ? addSpaceNumber(data?.hold) : 0} ₽</p>
              </div>
            </>
          )}
        </div>
        <Client fio={data?.fio || 'WorkSupport'} showMenu={showMenu} />
      </div>
      {showPopap && (
        <div className={styles.popap}>
          <div onClick={logout}>
            <p>Выход</p>
            <IconLogout />
          </div>
        </div>
      )}
    </div>
  );
};
