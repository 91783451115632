import React, { useEffect, useState } from 'react';
import { useGetBeneficiarQuery } from 'redux/beneficiariesApi';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'features/Layouts/Layouts';
import { ButtonSave } from 'components/ButtonSave';
import { InputText } from 'ui/InputText';
import { RootState } from 'redux/store';
import { setInfoBeneficiar } from 'redux/beneficiarDetail/slice';
import { TabBeneficiaries } from 'components/TabBeneficiaries';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { BeneficiariesTabBtn } from 'ui/BeneficiariesTabBtn';
import { Nothing } from 'components/Nothing';
import { ReactComponent as DocSvg } from './img/icon_document.svg';
import styles from './BeneficiariesDetails.module.scss';
import { WhiteBlock } from 'components/WhiteBlock';
import { Title } from 'components/Title';
import { Field } from 'ui/Field';

export const BeneficiariesDetails = () => {
  const { id } = useParams();
  const { data, isLoading, error } = useGetBeneficiarQuery({ beneficiary_id: id });
  const { infoBeneficiar } = useSelector((state: RootState) => state.beneficiarSlice);
  const dispatch = useDispatch();
  // console.log('data', data);

  useEffect(() => {
    if (data) {
      for (const key in data) {
        dispatch(
          setInfoBeneficiar({ key, value: data[key], step: false, secondKey: '' }),
        );
      }
    }
  }, [data, id]);

  // console.log('infoBeneficiar', infoBeneficiar);

  // console.log('er', error);

  if (error) {
    return (
      <Layout>
        <Nothing
          style={{ height: '700px', marginTop: '30px' }}
          title='Ничего не найдено'
        />
      </Layout>
    );
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    dispatch(
      setInfoBeneficiar({
        key: 'commission',
        value: e.target.value,
        step: true,
        secondKey: name,
      }),
    );
  };

  const onBlurHandler = (e: any, name: string) => {
    // console.log('onBlurHandler', e.target.value, name);
  };

  return (
    <Layout>
      {isLoading ? (
        <LoadingSkeleton mt />
      ) : (
        <div className={styles.root}>
          <div className={styles.back}>
            <p className={styles.back_title}>{data?.customer?.jur_name}</p>
            {/* <ButtonSave /> */}
          </div>
          <div className={styles.content}>
            <div className={styles.rootleft}>
              <div className={styles.left}>
                <div className={styles.left_title}>
                  <p className={styles.title}>Бенефициар №{data?.id}</p>
                  <BeneficiariesTabBtn
                    isActive={data?.beneficiary?.is_active}
                    title={data?.beneficiary?.is_active ? 'Активен' : 'Не активен'}
                  />
                </div>
                <InputText
                  value={infoBeneficiar?.jur_name ? infoBeneficiar.jur_name! : ''}
                  type='text'
                  placeholder='Название юр. лица'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    dispatch(
                      setInfoBeneficiar({
                        key: 'jur_name',
                        value: e.target.value,
                        step: false,
                      }),
                    )
                  }
                />
                <InputText
                  value={infoBeneficiar?.director?.fio}
                  type='text'
                  placeholder='ФИО'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    dispatch(
                      setInfoBeneficiar({
                        key: 'director',
                        value: e.target.value,
                        step: true,
                        secondKey: 'fio',
                      }),
                    )
                  }
                />
                <div className={styles.inputBlock}>
                  <InputText
                    value={infoBeneficiar?.inn || ''}
                    type='text'
                    placeholder='ИНН'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      dispatch(
                        setInfoBeneficiar({
                          key: 'inn',
                          value: e.target.value,
                          step: false,
                        }),
                      )
                    }
                  />
                  <InputText
                    value={infoBeneficiar?.kpp || ''}
                    type='text'
                    placeholder='КПП'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      dispatch(
                        setInfoBeneficiar({
                          key: 'kpp',
                          value: e.target.value,
                          step: false,
                        }),
                      )
                    }
                  />
                </div>
              </div>
              {infoBeneficiar?.beneficiary?.document && (
                <div className={styles.left}>
                  <p style={{ marginBottom: '10px' }} className={styles.title}>
                    Документы
                  </p>
                  <div className={styles.doc}>
                    <DocSvg />
                    <a
                      style={{ cursor: 'pointer' }}
                      href={infoBeneficiar.beneficiary.document?.file}
                      download
                      target='_blank'
                      rel='noreferrer'>
                      {infoBeneficiar.beneficiary.document?.document_number} от{' '}
                      {infoBeneficiar.beneficiary.document?.document_date}
                    </a>
                  </div>
                </div>
              )}
              <WhiteBlock>
                <Title title='Комиссия' />
                <div className={styles.inputBlock}>
                  <InputText
                    beneficiaries
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeHandler(e, 'percent')
                    }
                    onBlurHandler={onBlurHandler}
                    value={infoBeneficiar?.commission?.percent || 0}
                    placeholder='%'
                  />
                  <InputText
                    beneficiaries
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeHandler(e, 'min_amount')
                    }
                    onBlurHandler={onBlurHandler}
                    value={infoBeneficiar?.commission?.min_amount || 0}
                    placeholder='но не менее'
                    price='₽'
                  />
                  {/* <Field
                    title={data?.commission?.percent || 0}
                    placeholder={'%'}
                    style={{
                      width: '100%',
                    }}
                  /> */}
                  {/* <Field
                    title={infoBeneficiar?.commission?.min_amount || 0}
                    placeholder='но не менее'
                    style={{ width: '100%' }}
                    price='₽'
                  /> */}
                </div>
              </WhiteBlock>
            </div>

            <div className={styles.right}>
              {infoBeneficiar?.id && infoBeneficiar && <TabBeneficiaries />}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};
