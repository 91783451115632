import { FC } from 'react';
import { ReactComponent as NoAvatarSvg } from 'assets/img/noAvatar.svg';
import { ReactComponent as SheduleIconWorker } from 'assets/img/worker/IconWorkers.svg';
import classNames from 'classnames/bind';
import styles from './Avatar.module.scss';

const cx = classNames.bind(styles);

type AvatarProps = {
  avatar: string | null;
  statusActive:
    | 'new'
    | 'active'
    | 'average_active'
    | 'low_active'
    | 'not_work'
    | 'black_list'
    | '';
  marks?: boolean;
  id: number;

  name?: string;
  surname?: string;
};

export const Avatar: FC<AvatarProps> = ({
  avatar,
  statusActive,
  marks,
  surname,
  name,
  id,
}) => {
  // console.log('in_shedule', item);
  const classBorder = cx({
    root: true,
    [`status_${statusActive}`]: statusActive,
    avatar_marks: marks,
    root_marks: marks,
    container_marks: marks,
    shedule_marks: marks,
  });

  const handlerLink = (e: React.MouseEvent<HTMLElement>) => {
    // e.stopPropagation();
    // window.open(`https://lk.skilla.ru/brigadier/worker/${id}`, '_blank');
  };

  const initials = `${surname?.charAt(0)}${name?.charAt(0)}`.toUpperCase();

  // console.log('initials', initials);

  return (
    <div className={styles.container}>
      <div className={classBorder} onClick={handlerLink}>
        {avatar ? (
          <img className={styles.avatar} src={avatar} alt='' />
        ) : (
          // <NoAvatarSvg />
          <div className={styles.blockInitials}>
            <p>{initials}</p>
          </div>
        )}
      </div>
    </div>
  );
};
