import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { DateRangeState } from './types';
import { getCurrentDay, getWeek } from 'utils/date';

const initialState: DateRangeState = {
  dateStartPicker: localStorage.getItem('dateStartPayments')
    ? new Date(`${localStorage.getItem('dateStartPayments')}`)
    : new Date(getWeek()),

  dateEndPicker: localStorage.getItem('dateEndPayments')
    ? new Date(`${localStorage.getItem('dateEndPayments')}`)
    : new Date(getCurrentDay()),

  dateStart: localStorage.getItem('dateStartPayments') || getWeek(),
  dateEnd: localStorage.getItem('dateEndPayments') || getCurrentDay(),
  activeHandlerDate: 1
};

export const dateRangeSlice = createSlice({
  name: 'dateRange',
  initialState,
  reducers: {
    prevActiveHandlerDate: (state) => {
      state.activeHandlerDate = state.activeHandlerDate === 0 ? 3 : state.activeHandlerDate - 1;
    },
    nextActiveHandlerDate: (state) => {
      state.activeHandlerDate = state.activeHandlerDate === 3 ? 0 : state.activeHandlerDate + 1;
    },
    setDateStart: (state, action: PayloadAction<any>) => {
      state.dateStart = action.payload;
      state.dateStartPicker = new Date(`${action.payload}`);
      // localStorage.setItem('dateStartPayments', action.payload);
    },
    setDateEnd: (state, action: PayloadAction<any>) => {
      state.dateEnd = action.payload;
      state.dateEndPicker = new Date(`${action.payload}`);
      // localStorage.setItem('dateEndPayments', action.payload);
    },
    setDateStartPicker: (state, action: PayloadAction<any>) => {
      state.dateStartPicker = action.payload;
    },
    setDateEndPicker: (state, action: PayloadAction<any>) => {
      state.dateEndPicker = action.payload;
    },
    setResetDateRange: (state) => {
      // localStorage.removeItem('dateStartPayments');
      // localStorage.removeItem('dateEndPayments');
      state.dateStart = getWeek();
      state.dateEnd = getCurrentDay();
      state.dateStartPicker = new Date(getWeek());
      state.dateEndPicker = new Date(getCurrentDay());
    }
  }
});

export const {
  prevActiveHandlerDate,
  nextActiveHandlerDate,
  setDateStart,
  setDateEnd,
  setDateStartPicker,
  setDateEndPicker,
  setResetDateRange
} = dateRangeSlice.actions;
export default dateRangeSlice.reducer;
