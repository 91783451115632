import React, { FC } from 'react';
import { useGetTabQuery } from 'redux/beneficiariesApi';
import { DealsItem } from 'ui/DealsItem';
import { TitleRowTabBeneficiaries } from 'components/TitleRowTabBeneficiaries';
import { LoadingSkeleton } from 'components/LoadingSkeleton';
import { Nothing } from 'components/Nothing';
import styles from './Deals.module.scss';
interface DealsTypes {
  id: number | null;
  title: string;
}

export const Deals: FC<DealsTypes> = ({ id, title }) => {
  const { data, isLoading, error } = useGetTabQuery({ id, title });

  const dataDeals = ['id', 'Сумма', 'Комплаенс', 'Статус'];

  // console.log('data', data?.items);

  return (
    <div className={styles.root}>
      <TitleRowTabBeneficiaries data={dataDeals} title={title} />
      {error ? (
        <Nothing style={{ height: '640px' }} title='Ничего не найдено' />
      ) : isLoading ? (
        <LoadingSkeleton tabDetails />
      ) : (
        data && data.items.map((elem: any) => <DealsItem deal={elem} key={elem.id} />)
      )}
    </div>
  );
};
