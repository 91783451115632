import React, { FC } from 'react';
import { currentDay, getFotmattedDateCreated } from 'utils/date';
import { addSpaceNumber } from 'utils/addSpaceNumber';
import { BeneficiariesTabBtn } from 'ui/BeneficiariesTabBtn';
import { ReactComponent as IconSvg } from './img/icon.svg';
import { ReactComponent as IconDone } from './img/iconDone.svg';
import { ReactComponent as IconError } from 'assets/img/error.svg';
import styles from './PaymentsOperationsItem.module.scss';

interface PaymentsOperationsItemTypes {
  payment: any;
}

export const PaymentsOperationsItem: FC<PaymentsOperationsItemTypes> = ({ payment }) => {
  // console.log('payment', payment);
  return (
    <div className={styles.root}>
      <div>
        <p className={styles.root_text}>{currentDay(payment.tochka_created_at)}</p>
        <p className={styles.root_subText}>
          {payment.tochka_created_at.split(' ')[1].slice(0, 5)}
        </p>
      </div>
      <p className={styles.root_text}>{payment.id}</p>
      <div>
        <p className={styles.root_text}>{payment.beneficiary.customer.name}</p>
        <p className={styles.root_subText}>{payment.beneficiary.tochka_uuid}</p>
      </div>
      <div>
        <p className={styles.root_text}>{payment?.worker?.fio}</p>
        <p className={styles.root_subText}>
          {payment?.worker?.inn ? `ИНН ${payment?.worker?.inn}` : null}
        </p>
      </div>
      <div>
        <p className={styles.root_text}>{payment.purpose}</p>
        <p className={styles.root_subText}>{payment.type}</p>
      </div>
      <p style={{ textAlign: 'end' }} className={styles.root_text}>
        {addSpaceNumber(payment.amount)}
      </p>
      <div>
        <p className={styles.root_text}>{currentDay(payment.tochka_updated_at)}</p>
      </div>
      <div className={styles.statusBlock}>
        <div>
          {/* <p className={styles.root_text}>{}</p> */}
          <div style={{ padding: '0 16px' }}>
            <BeneficiariesTabBtn
              classNameStyles={styles.statusBlock}
              style={{ padding: '2px 8px' }}
              oldStyle
              bg={payment.status?.color_bg}
              color={payment.status?.color_text}
              // isActive={false}
              title={payment.status.text}
            />
          </div>
          {payment.tochka_updated_at && (
            <p style={{ padding: '0px 16px' }} className={styles.root_subText}>
              <IconSvg /> {getFotmattedDateCreated(payment.tochka_updated_at)}. назад
            </p>
          )}
        </div>
        {payment.identified ? <IconDone /> : <IconError />}
      </div>
    </div>
  );
};
