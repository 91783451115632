import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InfoEmployedsTypes } from './types';

interface StateType {
  infoEmployeds: InfoEmployedsTypes;
  openBlock: string;
}

const initialState: StateType = {
  infoEmployeds: {
    id: null,
    fns_status: null,
    inn: '',
    last_info_update: '',
    lists: {
      available_tabs: [],
    },
    name: '',
    nationality: '',
    patronymic: '',
    phone: '',
    surname: '',
    address_passport: '',
    address_reg_a: '',
    address_reg_city: '',
    address_reg_home: '',
    address_reg_k: '',
    address_reg_street: '',
    app_status: 0,
    avatar: '',
    comment: '',
    dob: '',
    doc_fns_code: null,
    passport_date: '',
    passport_kem: '',
    passport_kod: '',
    passport_n: '',
    passport_s: '',
    sex: 1,
    worker_fns: {
      fns_status: 0,
      inn: '',
      last_info_update: '',
      nationality: '',
    },
  },
  openBlock: '',
};

export const employedsDetailSlice = createSlice({
  name: 'employedsDetailSlice',
  initialState,
  reducers: {
    setOpenBlock: (state, action: PayloadAction<string>) => {
      state.openBlock = action.payload;
    },
    setInfoEmployeds: (state, action) => {
      if (!action.payload.key) return;
      if (action.payload.step) {
        const { key, secondKey, value } = action.payload;

        if (action.payload.secondKey && typeof state.infoEmployeds[key] === 'object') {
          state.infoEmployeds[key][secondKey] = value;
        }
      } else {
        const { key, value } = action.payload;
        state.infoEmployeds[key] = value;
      }
    },
  },
});

export const { setInfoEmployeds, setOpenBlock } = employedsDetailSlice.actions;
export default employedsDetailSlice.reducer;
