import axios from 'axios';
const token = localStorage.getItem('token');

const api = 'api2';

// customer-test.skilla.ru
// api.go.skilla.ru

export const auth = async (params: any) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER_TEST_EMPLOYEDS}/auth/login`,
      params,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const checkWorker = async (params: any) => {
  const response = await axios(`https://${api}.skilla.ru/api/workers/check-worker`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    params,
  });
  return response.data;
};

export const getCallbackMango = async (params: any) => {
  const response = await axios('https://api.skilla.ru/mango/getCallback', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    params,
  });
  return response.data;
};

export const sendWorkerFile = async (idWorker: any, bodyFormData: any) => {
  const { data } = await axios.post(
    `https://${api}.skilla.ru/api/workers/${idWorker}/update`,
    bodyFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return data;
};

export const putCalendarDate = async (idWorker: any, dates: any) => {
  const response = await axios(
    `https://${api}.skilla.ru/api/workers/schedule/store?worker_id=${idWorker}${dates}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

export const putPlanningDay = async (params?: any) => {
  const response = await axios.put(
    `https://${api}.skilla.ru/api/workers/schedule/store`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      params,
    },
  );
  return response.data;
};

export const deletePlanningDay = async (params?: any) => {
  const response = await axios(`https://${api}.skilla.ru/api/workers/schedule/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    params,
  });
  return response.data;
};

export const downloadFile = async (url: string, params: any) => {
  const response = await axios.get(
    `${url ? url : `https://${api}.skilla.ru/api/payments/export`}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/vnd.ms-excel',
        Authorization: `Bearer ${token}`,
      },
      params,
      responseType: 'blob',
    },
  );

  return response;
};

export const downloadFileForOrders = async (role: string, id: number, params: any) => {
  const data = await axios.get(
    role
      ? `${process.env.REACT_APP_API_SERVER_TEST_EMPLOYEDS}/${role}/orders/${id}/docs/download`
      : `${process.env.REACT_APP_API_SERVER_TEST_EMPLOYEDS}/orders/${id}/docs/download`,
    {
      headers: {
        Accept: 'application/vnd.ms-excel',
        Authorization: `Bearer ${token}`,
      },
      params,
      responseType: 'blob',
    },
  );

  return data;
};

export const searchSuggestingApi = async (value: string) => {
  try {
    const response = await fetch(
      'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fms_unit',
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + `${process.env.REACT_APP_API_KEY}`,
        },
        body: JSON.stringify({ query: value }),
      },
    );
    const result = await response.text();
    return JSON.parse(result)?.suggestions;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};
//
export const searchSuggestingCompany = async (value: string) => {
  try {
    const response = await fetch(
      'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party',
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + `${process.env.REACT_APP_API_KEY}`,
        },
        body: JSON.stringify({ query: value }),
      },
    );
    const result = await response.text();
    return JSON.parse(result)?.suggestions;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};
