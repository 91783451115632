import { FC, useEffect, useState } from 'react';
import { ReactComponent as IconAlert } from 'assets/img/statuses/iconAlert.svg';
import styles from './TabMenu.module.scss';

type TabMenuProps = {
  setActive: (key: string) => void;
  active: string;
  lists?: any[];
  cls?: any;
};

export const TabMenu: FC<TabMenuProps> = ({ setActive, active, lists = [], cls }) => {
  const handlerActive = (key: string) => {
    setActive(key);
  };

  return (
    <div className={styles.container}>
      <div className={styles.root} style={cls}>
        {lists.map((item: any, i: number) => (
          <div
            className={`${styles.item} ${item.key === active ? styles.active : ''}`}
            key={i}
            onClick={() => handlerActive(item.key)}>
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};
