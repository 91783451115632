import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import operation from 'assets/img/navSvg/operation.svg';
import employed from 'assets/img/navSvg/IconBuilder.svg';
import tax from 'assets/img/navSvg/iconTax.svg';
import profile from 'assets/img/navSvg/iconProfile.svg';

export interface InfoPercentType {
  commission_min_amount: number;
  commission_percent: number;
}
export interface AuthState {
  token: string | null;
  is_contract_signed: boolean;
  balance: string | number;
  infoPercent: InfoPercentType;
}

const initialState: AuthState = {
  token: localStorage.token ?? null,
  is_contract_signed: false,
  balance: 0,
  infoPercent: {
    commission_min_amount: 0,
    commission_percent: 0,
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logIn(state: { token: string | null }, action: PayloadAction<string | null>): void {
      state.token = action.payload;
    },
    logOut(state) {
      state.token = null;
    },
    setIsContractSigned(state, action) {
      state.is_contract_signed = action.payload;
    },
    setBalance(state, action) {
      state.balance = action.payload.balance;
      state.infoPercent = action.payload.infoPercent;
    },
  },
});

export const { logIn, logOut, setIsContractSigned, setBalance } = authSlice.actions;
export default authSlice.reducer;
