import React, { FC, useState } from 'react';
import styles from './NewOrderDetailsHistory.module.scss';
import { WhiteBlock } from 'components/WhiteBlock';
import { Title } from 'components/Title';
import { BlockResize } from 'components/BlockResize';
import { InformationOrderBlock } from 'components/InformationOrderBlock';
import { EmployedsSearchBlock } from 'components/EmployedsSearchBlock';
import { InfoEmployedItem } from 'components/InfoEmployedItem';

interface NewOrderDetailsHistoryTypes {
  status?: any;
  payment?: any;
  objPayment?: any;
  data?: any;
  role?: string;
  actionWithOrder?: ((str: string) => void) | any;
  id?: string | number;
  isCanceled?: boolean;
  create?: boolean;
}

export const NewOrderDetailsHistory: FC<NewOrderDetailsHistoryTypes> = ({
  status = '',
  payment,
  objPayment = {},
  data,
  role,
  id,
  isCanceled,
  create = false,
  actionWithOrder,
}) => {
  const handleDelete = () => {
    if (!create) {
      actionWithOrder(`${id}/reject-worker`);
    }
  };
  return (
    <>
      <div>
        <WhiteBlock className={styles.statusBlock}>
          <Title title='Статус' />
          {/* <div className={styles.blockChoose}></div> */}
          {status && <Title className={styles.text} title={status.text} />}
        </WhiteBlock>
      </div>

      {(data?.is_ready || create) &&
        (!data?.worker || data?.is_draft ? (
          <EmployedsSearchBlock
            workSupport={role !== 'worksupport'}
            isReady={data?.is_ready}
            worker={data?.worker ? [data.worker] : []}
          />
        ) : (
          <WhiteBlock>
            <p className={styles.title}>Исполнитель</p>
            <div className={styles.blockChoose}>
              <InfoEmployedItem
                phone={data?.worker?.phone}
                workSupport={role !== 'worksupport'}
                fns_status={data?.worker?.fns_status}
                fio={`${data?.worker?.surname} ${data?.worker?.name}`}
                inn={data?.worker?.inn}
                workerAppStatus={data?.worker?.app_status}
                deleteEmployedHandler={create ? undefined : handleDelete}
                isDelete={isCanceled || data?.status?.value === 'worker_took_order'}
              />
            </div>
          </WhiteBlock>
        ))}

      {payment && (
        <BlockResize
          error={payment?.failure_description || !payment?.success}
          style={{ width: '100%' }}
          title='Выплата'
          // open='Выплата'
          notUse>
          <InformationOrderBlock payment objectInfo={objPayment} />
        </BlockResize>
      )}
    </>
  );
};
