import React, { FC } from 'react';
import classNames from 'classnames/bind';
import styles from './TitleRowTabBeneficiaries.module.scss';

const cx = classNames.bind(styles);

interface TitleRowTabBeneficiariesTypes {
  data: string[];
  title: string;
}

export const TitleRowTabBeneficiaries: FC<TitleRowTabBeneficiariesTypes> = ({
  data,
  title,
}) => {
  const classNameInput = cx({
    deals: title === 'deals',
    payments: title === 'payments',
    paymentsEmployeds: title === 'paymentsEmployeds',
    ordersEmployeds: title === 'ordersEmployeds',
    taxesEmployeds: title === 'taxesEmployeds',
    taxesDetail: title === 'taxesDetail',
    taxesDetailError: title === 'taxesDetailError',
    profileEmployees: title === 'profileEmployees',
    documentsEmployeds: title === 'documentsEmployeds'
  });

  return (
    <div className={classNameInput}>
      {data.map((elem: string, i: number) => (
        <p
          style={
            elem === 'Сумма' || elem === 'В копилке' || elem === 'Начислено'
              ? { textAlign: 'end' }
              : {}
          }
          key={i}>
          {elem}
        </p>
      ))}
    </div>
  );
};
