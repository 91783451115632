import { FC } from 'react';
import classNames from 'classnames';
import styles from './Title.module.scss';

type TitleProps = {
  title: string;
  className?: any;
  error?: string;
  style?: any;
};

export const Title: FC<TitleProps> = ({ title, className, error, style }) => {
  return (
    <span
      style={style}
      className={classNames(styles.root, className, error && styles.root_error)}>
      {title}
      {error?.length ? `. ${error}` : ''}
    </span>
  );
};
