import ReactDOM from 'react-dom';
import { FC } from 'react';
import { ReactComponent as CloseSvg } from 'assets/img/close.svg';
import styles from './Modal.module.scss';
import classNames from 'classnames';

type ModalProps = {
  children: JSX.Element;
  open?: boolean;
  setOpen: (e: boolean) => void;
  rest?: any;
  className?: any;
};

export const Modal: FC<ModalProps> = ({
  children,
  open,
  setOpen,
  className,
  ...rest
}) => {
  // console.log('open', open);
  const handlerClose = (e: any) => {
    e.stopPropagation();
    // console.log('object');

    setOpen(false);
  };

  return ReactDOM.createPortal(
    <div className={styles.root} onMouseDown={handlerClose}>
      <div className={styles.modal}>
        <div onMouseDown={(e) => e.stopPropagation()}> {children}</div>

        <div className={classNames(styles.close, className)}>
          <CloseSvg />
        </div>
      </div>
    </div>,
    document.body,
  );
};
