import React, { FC } from 'react';
import styles from './TitleRowTabOperations.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface TitleRowTabOperationsTypes {
  data: string[];
  title: string;
}

export const TitleRowTabOperations: FC<TitleRowTabOperationsTypes> = ({
  data,
  title,
}) => {
  const classNameInput = cx({
    deals: title === 'deals',
    payments: title === 'payments',
    taxes: title === 'taxes',
    taxeReady: title === 'ready',
    taxeError: title === 'error',
  });

  return (
    <div className={classNameInput}>
      {data.map((elem: string, i: number) => (
        <p
          style={
            elem.includes('Сумма') ||
            elem.includes('В копилке') ||
            elem.includes('Начислено')
              ? { textAlign: 'end' }
              : {}
          }
          key={i}>
          {elem}
        </p>
      ))}
    </div>
  );
};
