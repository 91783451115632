import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import {
  setDateEnd,
  setDateHourMinutes,
  setDateStart,
  setInfoOrderDetail,
} from 'redux/ordersDetails/slice';
import { Title } from 'components/Title';
import { ListSelect } from 'components/ListSelect';
import { InputDate } from 'components/InputDate';
import { AddressDadata } from 'components/AddressDadata';
import { InputText } from 'ui/InputText';
import { TextArea } from 'ui/TextArea';
import { Field } from 'ui/Field';
import { width } from '@mui/system';
import { InfoPercentType } from 'redux/auth/slice';
import { Tooltip } from 'ui/Tooltip';
import { ReactComponent as InfoSvg } from 'assets/img/iconInfo2big.svg';
import { ReactComponent as IconTime } from './img/iconTime.svg';
import { ReactComponent as IconClock } from './img/iconClock.svg';
import styles from './EditFieldsOrder.module.scss';
import { ChooseHourMinutes } from 'components/ChooseHourMinutes';
import classNames from 'classnames';
interface Type {
  id: number;
  name: string;
}

interface EditFieldsOrderTypes {
  types: Type[];
  draft?: boolean;
  minMaxPrice?: any;
  balance: boolean;
  infoPercent: InfoPercentType;
  sumBalance: number;
}

export const EditFieldsOrder: FC<EditFieldsOrderTypes> = ({
  types,
  draft,
  minMaxPrice,
  balance,
  infoPercent,
  sumBalance = 0,
}) => {
  const [isOpenTooltipApp, setIsOpenTooltipApp] = useState(false);
  const [isOpenTooltipAppField, setIsOpenTooltipAppField] = useState(false);

  const [isOpenChooseTimeRight, setIsOpenChooseTimeRight] = useState(false);
  const [isOpenChooseTimeLeft, setIsOpenChooseTimeLeft] = useState(false);

  const [sortedTypes, setSortedTypes] = useState<any>([]);

  const dispatch = useDispatch();
  const { infoOrderDetail, dateStartHourMinutes, dateEndHourMinutes } = useSelector(
    (state: RootState) => state.ordersDetailsSlice,
  );

  const [activeItem, setActiveItem] = useState<any>(types ? types[0] : null);

  useEffect(() => {
    if (infoOrderDetail?.order_type) {
      setActiveItem(types?.find((e) => e.name === infoOrderDetail?.order_type));
    }

    if (types) {
      const sorted = [...types].sort((a, b) => (a.name > b.name ? 1 : -1));
      setSortedTypes(sorted);
    }
  }, [types, infoOrderDetail]);

  // console.log('activeItem', infoOrderDetail);

  useEffect(() => {
    dispatch(setInfoOrderDetail({ key: 'order_type_id', value: activeItem?.id }));
  }, [activeItem]);

  const sumWithPercent =
    +infoOrderDetail?.amount * (infoPercent?.commission_percent / 100) || 0;

  const iconRef = useRef<HTMLDivElement>(null);
  const fieldRef = useRef<HTMLDivElement>(null);

  const timeLeftRef = useRef<HTMLDivElement>(null);
  const timeRightRef = useRef<HTMLDivElement>(null);

  const mouseOver = (isField: string) => {
    if (iconRef.current && !isField) {
      setIsOpenTooltipApp(true);
    } else if (fieldRef.current && isField) {
      setIsOpenTooltipAppField(true);
    }
  };

  const mouseOut = (isField: string) => {
    if (iconRef.current && !isField) {
      setIsOpenTooltipApp(false);
    } else if (fieldRef.current && isField) {
      setIsOpenTooltipAppField(false);
    }
  };

  useEffect(() => {
    const clickOutside = (event: MouseEvent) => {
      if (timeLeftRef.current && !event.composedPath().includes(timeLeftRef.current)) {
        setIsOpenChooseTimeLeft(false);
      }
      if (timeRightRef.current && !event.composedPath().includes(timeRightRef.current)) {
        setIsOpenChooseTimeRight(false);
      }
    };
    document.body.addEventListener('mousedown', clickOutside);
    return () => document.body.removeEventListener('mousedown', clickOutside);
  }, []);

  const editTime = (value: string, key: string, position: string) => {
    dispatch(setDateHourMinutes({ value, key, position }));
  };

  const errorForAmountWithPercent =
    sumBalance <
    (sumWithPercent < infoPercent?.commission_min_amount
      ? +infoOrderDetail.amount + infoPercent.commission_min_amount
      : +infoOrderDetail.amount + +sumWithPercent.toFixed(2));

  return (
    <div className={styles.left}>
      <Title title='Данные заказа' />
      {sortedTypes && (
        <ListSelect
          style={{ height: 'auto' }}
          placeholder='Тип услуги *'
          list={sortedTypes}
          search
          activeItem={activeItem?.name}
          setActiveItem={setActiveItem}
        />
      )}
      <TextArea
        value={infoOrderDetail.description || ''}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          dispatch(setInfoOrderDetail({ key: 'description', value: e.target.value }))
        }
        placeholder='Описание'
        orderFocus={true}
      />
      <div className={styles.inputBlock}>
        {/* <div ref={timeLeftRef} className={styles.inputBlock_item}>
          <InputDate
            // withoutHour
            style={{ width: '217px' }}
            draft={draft}
            placeholder='Дата начала'
            date={infoOrderDetail.date_start}
            setDate={setDateStart}
          />

          <div
            onClick={() => setIsOpenChooseTimeLeft(true)}
            className={styles.inputBlock_time}>
            {dateStartHourMinutes.hour !== '00' ? (
              <span>
                {dateStartHourMinutes.hour}:{dateStartHourMinutes.minutes}
              </span>
            ) : (
              <div>
                <IconTime />
              </div>
            )}
            <div style={{ height: '24px' }}>
              <IconClock />
            </div>

            {isOpenChooseTimeLeft && (
              <ChooseHourMinutes
                chooseHandler={(value, name) => editTime(value, name, 'left')}
                date={dateStartHourMinutes}
                style={{ left: '220px' }}
              />
            )}
          </div>
        </div> */}
        <div ref={timeRightRef} className={styles.inputBlock_item}>
          <InputDate
            // withoutHour
            // style={{ width: '217px' }}
            draft={draft}
            placeholder='Дата окончания * '
            date={infoOrderDetail.date_end}
            setDate={setDateEnd}
          />
          {/* <div
            onClick={() => setIsOpenChooseTimeRight(true)}
            className={styles.inputBlock_time}>
            {dateEndHourMinutes.hour !== '00' ? (
              <span>
                {dateEndHourMinutes.hour}:{dateEndHourMinutes.minutes}
              </span>
            ) : (
              <div>
                <IconTime />
              </div>
            )}
            <div style={{ height: '24px' }}>
              <IconClock />
            </div>

            {isOpenChooseTimeRight && (
              <ChooseHourMinutes
                chooseHandler={(value, name) => editTime(value, name, 'right')}
                date={dateEndHourMinutes}
                style={{ right: 0 }}
              />
            )}
          </div> */}
        </div>
      </div>
      <div style={{ justifyContent: 'flex-start' }} className={styles.inputBlock}>
        <div>
          <InputText
            error={
              ((minMaxPrice.min > +infoOrderDetail.amount ||
                minMaxPrice.max < +infoOrderDetail.amount) &&
                !!infoOrderDetail.amount) ||
              !balance
            }
            price='₽'
            value={String(infoOrderDetail.amount)}
            type='text'
            placeholder='Стоимость *'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const str = e.target.value.replace(/[a-zA-Zа-яА-Я]/g, '').replace(',', '.');
              dispatch(
                setInfoOrderDetail({
                  key: 'amount',
                  value: str,
                }),
              );
            }}
          />
          <p
            style={
              ((minMaxPrice.min > +infoOrderDetail.amount ||
                minMaxPrice.max < +infoOrderDetail.amount) &&
                !!infoOrderDetail.amount) ||
              !balance
                ? {
                    marginTop: '9px',
                    color: '#e10d0d',
                    fontWeight: 500,
                    fontSize: '16px',
                    width: '345px',
                  }
                : { marginTop: '9px' }
            }>
            {!balance
              ? 'Средств на балансе недостаточно для создания заказа'
              : 'Допустимая сумма заказа от 1 000 до 50 000 ₽'}
          </p>
        </div>
        <div>
          <div
            style={{
              position: 'relative',
            }}
            ref={fieldRef}
            onMouseOver={() => mouseOver('field')}
            onMouseOut={() => mouseOut('field')}>
            {/* <Field
              error={
                sumBalance <
                (sumWithPercent < infoPercent?.commission_min_amount
                  ? +infoOrderDetail.amount + infoPercent.commission_min_amount
                  : +infoOrderDetail.amount + +sumWithPercent.toFixed(2))
              }
              style={{
                // width: '100%',
                width: '345px',
                background: 'rgba(212, 223, 243, 0.17)',
              }}
              styleTitle={{ color: '#8E9AA8' }}
              title={
                infoOrderDetail.amount
                  ? sumWithPercent < infoPercent?.commission_min_amount
                    ? +infoOrderDetail.amount + infoPercent.commission_min_amount
                    : +infoOrderDetail.amount + +sumWithPercent.toFixed(2)
                  : 0
              }
              price={'₽'}
            /> */}

            <div className={styles.blockAmount}>
              <p
                className={classNames(
                  styles.amount,
                  errorForAmountWithPercent && styles.amount_error,
                )}>
                {infoOrderDetail.amount
                  ? sumWithPercent < infoPercent?.commission_min_amount
                    ? +infoOrderDetail.amount + infoPercent.commission_min_amount
                    : +infoOrderDetail.amount + +sumWithPercent.toFixed(2)
                  : 0}{' '}
                ₽
              </p>

              <div
                ref={iconRef}
                onMouseOver={() => mouseOver('')}
                onMouseOut={() => mouseOut('')}
                style={{
                  height: '24px',
                  position: 'relative',
                  marginTop: '11.5px',
                }}>
                <InfoSvg />

                {isOpenTooltipApp && (
                  <Tooltip
                    text={`Комиссия площадки ${infoPercent.commission_percent} %, но не менее ${infoPercent.commission_min_amount} ₽`}
                    position={'bottom'}
                  />
                )}
              </div>
            </div>

            {/* {isOpenTooltipAppField && (
              <Tooltip
                field
                text='Сумма с учетом комиссии площадки будет захолдирована 
до момента выплаты или отмены заказа'
                position={'bottom'}
              />
            )} */}
          </div>
          {/* <div className={styles.percentInfo}>
            <p style={{ marginTop: '9px' }}>Стоимость с учетом комиссии </p>
            <div
              ref={iconRef}
              onMouseOver={() => mouseOver('')}
              onMouseOut={() => mouseOut('')}
              style={{ height: '16px', marginBottom: '2px', position: 'relative' }}>
              <InfoSvg />

              {isOpenTooltipApp && (
                <Tooltip
                  text={`Комиссия площадки ${infoPercent.commission_percent} %, но не менее ${infoPercent.commission_min_amount} ₽`}
                  position={'bottom'}
                />
              )}
            </div>
          </div> */}
        </div>
      </div>
      <div className={styles.location}>
        <Title title='Локация' />
        <div className={styles.location_type}>
          {['Удаленно', 'По адресу'].map((item, i) => (
            <button
              className={`${styles.button} ${
                infoOrderDetail.is_remote === item ? styles.active : ''
              }`}
              onClick={() => {
                dispatch(setInfoOrderDetail({ key: 'is_remote', value: item }));
                dispatch(setInfoOrderDetail({ key: 'apartment', value: '' }));
                dispatch(setInfoOrderDetail({ key: 'city', value: '' }));
                dispatch(setInfoOrderDetail({ key: 'street', value: '' }));
                dispatch(setInfoOrderDetail({ key: 'home', value: '' }));
              }}
              key={i}>
              {item}
            </button>
          ))}
        </div>
        {infoOrderDetail.is_remote !== 'Удаленно' && <AddressDadata />}
      </div>
    </div>
  );
};
